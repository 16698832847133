export const STORAGE_KEY = {
  ACCESS_TOKEN: '__VENDIT__ACCESS_TOKEN',
  REFRESH_TOKEN: '__VENDIT__REFRESH_TOKEN',
};

export const ACCESS_LEVEL = {
  CUSTOMER: 'CUSTOMER',
  PARTNER: 'PARTNER',
  PARTNERADMIN: 'PARTNERADMIN',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
} as const;

export const POLL_INTERVAL = {
  DASHBOARD: 10000,
};

export const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_STAGING_BUILD === 'true';

export const API_HOST =
  (isDevelopment && 'https://dev.vendit.co.kr') || 'https://api.vendit.co.kr';

export const WS_API_HOST =
  (isDevelopment && 'wss://subdev.vendit.co.kr') ||
  'wss://subscription.vendit.co.kr';
