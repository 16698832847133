import React from 'react';
import { InputAdornment, InputBase } from '@material-ui/core';
import { COLORS, FONTS } from '@common/styles';
import styled from 'styled-components';
import { LayoutFlex, SUITTypography } from '@common/components';
import { restrictInputNumberOnly } from 'helpers/hooks';

interface WrapperProps {
  backgroundColor?: string
  width?: string
  height?: number
  border?: string
  padding?: string
  containerStyleProps?: Record<string, number | string>
  unitStyleProps?: Record<string, number | string>
}
interface Props extends WrapperProps {
  text: string
  type?: string
  onChange: (value: string) => void
  placeholder?: string
  multiline?: boolean
  multilineRows?: number
  fontColor?: string
  icon?: React.ReactElement
  iconAlignItem?: 'flex-end' | undefined
  unit?: string
  inputProps?: Record<string, string | number>
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: () => void
  autoFocus?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.GRAY_05};
  border: ${({ border }) => border};
  width: ${({ width }) => width};
  height: ${({ height }) => height || 70}px;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => padding || '0px 24px'};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const IconWrapper = styled.div`
  margin-right: 21px;
  width: 20px;
  cursor: pointer;
  padding-top: 4px;
`;

const Icon = ({ element, styles }: { element: React.ReactElement, styles?: Record<string, string | number> }) => (
  <IconWrapper style={styles}>
    {element}
  </IconWrapper>
);

const Input: React.FunctionComponent<Props> = ({
  text, type, height, onChange, placeholder, backgroundColor, multilineRows, fontColor, icon, border, multiline, iconAlignItem, width, padding, containerStyleProps, unit, unitStyleProps, inputProps, onKeyDown, onBlur, autoFocus,
}) => (
  <Wrapper style={containerStyleProps} border={border} backgroundColor={backgroundColor} width={width} height={height} padding={padding}>
    <div>
      {((icon && iconAlignItem !== 'flex-end')) && <Icon element={icon} />}
      <InputBase
        autoFocus={autoFocus}
        type={type}
        inputProps={inputProps}
        multiline={multiline}
        rows={multilineRows || (multiline ? 11 : undefined)}
        value={text}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={inputProps?.inputMode === 'numeric' ? restrictInputNumberOnly({ onChange: (newValue: string) => onChange(newValue) }) : (event) => onChange(event.target.value)}
        fullWidth
        onBlur={onBlur}
        endAdornment={unit
          && (
            <InputAdornment position="end">
              <LayoutFlex justifyContent="flex-end" style={unitStyleProps}>
                <SUITTypography fontColor={COLORS.GRAY_87} fontWeight={FONTS.SUIT.SEMI_BOLD}>{unit}</SUITTypography>
              </LayoutFlex>
            </InputAdornment>
          )}
      />
      {(icon && iconAlignItem === 'flex-end') && <Icon element={icon} styles={{ paddingRight: 0 }} />}
    </div>
  </Wrapper>
);

export default Input;
