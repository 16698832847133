import { useMutation, useQuery } from '@apollo/client';
import { NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import { Button } from 'components/ui/Button';
import { getMyAccommodationsQuery } from 'graphql/accommodations';
import { setConciergeServiceSettingsMutation } from 'graphql/settings';
import { useGetAccommodationId } from 'helpers/hooks';
import { ReactComponent as Clock } from 'images/clock.svg';
import { ReactComponent as Request } from 'images/ico_request.svg';
import { ReactComponent as PaymentIcon } from 'images/payment.svg';
import moment from 'moment';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DropdownItem } from 'types';
import ServiceDeactivateModal from '../DeactivateModal';
import PaymentOptionModal from '../payment/PaymentOptionModal';

interface Tab {
  id: 'roomService' | 'amenity' | 'serviceNotice' | 'paymentOption' | 'businessHours' | 'orderRequest' | null
  name: string
  icon: React.ReactElement | null
  button: React.ReactElement | null
  subText?: string
}

interface ModalProps {
  type: 'deactivate' | 'paymentOption' | ''
  isOpened: boolean
  data: 'roomService' | 'amenity' | 'serviceNotice' | 'paymentOption' | 'businessHours' | 'orderRequest' | null
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  height: 166px;
  padding: 18px 23px;

  > div {
    display: flex;

     > div {
      margin-right: 15px;
     }
  }
`;

const TabButton: React.FunctionComponent<{ data: Tab, isSelected: boolean, onSelect: (data: Tab) => void, onDeactivate: (data: Tab) => void }> = ({
  data, isSelected, onSelect, onDeactivate,
}) => <Button descriptionFontSize={13} descriptionFontColor={COLORS.GRAY_01} description={data.subText && moment(data.subText).format('MM.DD.dd HH:mm에 활성화 예정')} fontSize={18} styleProps={{ flexDirection: 'column' }} icon={(data.icon && data.icon) || undefined} iconStyleProps={{ marginBottom: 20, marginRight: 0 }} text={data.name} width="20%" height={130} borderWidth={2} backgroundColor={isSelected ? COLORS.GRAY_05 : COLORS.WHITE} borderColor={isSelected ? COLORS.BLACK : COLORS.GRAY_02} onClick={() => onSelect(data)} childButton={(data.button && data.button) || undefined} onChildButtonClicked={() => onDeactivate(data)} />;

const ServiceTab = () => {
  const [tabs, setTabs] = React.useState<Tab[]>([
    {
      id: 'roomService',
      name: '룸서비스',
      icon: null,
      button: null,
    },
    {
      id: 'amenity',
      name: '어메니티',
      icon: null,
      button: null,
    },
    // {
    //   id: 'serviceNotice',
    //   name: '메인 배너 설정',
    //   icon: null,
    //   button: null,
    // },
    {
      id: 'paymentOption',
      name: '결제방식 설정',
      icon: <PaymentIcon />,
      button: null,
    },
    {
      id: 'businessHours',
      name: '매장 운영 시간',
      icon: <Clock />,
      button: null,
    },
    {
      id: 'orderRequest',
      name: '요청사항',
      icon: <Request />,
      button: null,
    },
  ]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { accommodationId } = useGetAccommodationId();
  const [selectedTab, setSelectedTab] = React.useState<string | null>(tabs[0].id);
  const [modal, setModal] = useState<ModalProps>({
    type: '',
    isOpened: false,
    data: null,
  });
  const { data: myAccommodations, refetch: myAccommodationsReFetch } = useQuery(getMyAccommodationsQuery);
  const [emitSetConciergeServiceSettingsMutation] = useMutation(setConciergeServiceSettingsMutation);

  React.useEffect(() => {
    const getType = pathname.split('/').pop();

    setSelectedTab(getType || null);
  }, []);

  const enableConcierge = React.useMemo(() => {
    const conciergeServiceSettings = myAccommodations?.getMyAccommodations.length && myAccommodations?.getMyAccommodations[0].conciergeServiceSettings;

    if (conciergeServiceSettings) {
      return {
        roomService: conciergeServiceSettings?.roomServiceActivated ? moment().isBefore(moment(conciergeServiceSettings.roomServiceActivated)) : false,
        roomServiceActivated: moment().isBefore(moment(conciergeServiceSettings.roomServiceActivated)) && conciergeServiceSettings?.roomServiceActivated,
        amenity: conciergeServiceSettings?.amenityActivated ? moment().isBefore(moment(conciergeServiceSettings.amenityActivated)) : false,
        amenityActivated: moment().isBefore(moment(conciergeServiceSettings.amenityActivated)) && conciergeServiceSettings?.amenityActivated,
      };
    }

    return false;
  }, [myAccommodations]);

  const onSelect = React.useCallback((data: Tab) => {
    setSelectedTab(data.id);

    if (data.id === 'roomService' || data.id === 'amenity' || data.id === 'serviceNotice' || data.id === 'businessHours' || data.id === 'orderRequest') {
      navigate(`/service/${accommodationId}/${data.id}`);
    }

    if (data.id === 'paymentOption') {
      setModal({
        type: 'paymentOption',
        isOpened: true,
        data: null,
      });
    }
  }, [accommodationId]);

  const onDeactivate = (data: { type: 'roomService' | 'amenity', data?: DropdownItem }) => {
    confirmAlert({
      title: `${data.type === 'roomService' ? '룸서비스' : '어메니티'}를 ${data.data ? '비활성화' : '활성화'} 시키겠습니까?`,
      message: `확인 버튼을 누를 시 ${data.type === 'roomService' ? '룸서비스' : '어메니티'}가 ${data.data ? '비활성화' : '활성화'} 됩니다.`,
      buttons: [
        {
          label: '확인',
          onClick: async () => {
            await toast.promise(
              emitSetConciergeServiceSettingsMutation({
                variables: {
                  accommodationId,
                  inputs: [
                    {
                      key: data.type === 'roomService' ? 'roomServiceActivated' : 'amenityActivated',
                      value: data?.data?.id || moment().format('YYYY-MM-DDTHH:mm'),
                    },
                  ],
                },
              }),
              {
                loading: <NotoSansTypography NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
                success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`${data.type === 'roomService' ? '룸서비스' : '어메니티'} ${data.data ? '비활성화' : '활성화'} 처리가 성공적으로 완료되었습니다.`}</NotoSansTypography>,
                error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`${data.type === 'roomService' ? '룸서비스' : '어메니티'} ${data.data ? '비활성화' : '활성화'} 처리 중 문제가 발생했습니다.`}</NotoSansTypography>,
              },
            );

            myAccommodationsReFetch();
          },
        },
        {
          label: '취소',
          onClick: () => { },
        },
      ],
    });
  };

  const onCloseModal = React.useCallback(() => {
    setModal({
      type: '',
      data: null,
      isOpened: false,
    });
  }, []);

  React.useEffect(() => {
    if (enableConcierge) {
      const copyTabs = [...tabs];

      const ROOM_SERVICE = 0;
      const AMENITY = 1;

      const buttonFilter = (isEnabled: boolean) => {
        if (!isEnabled) {
          return <Button text="활성화" width="64px" height={32} backgroundColor={COLORS.GREEN} fontSize={14} fontColor={COLORS.WHITE} fontWeight={500} borderColor="none" />;
        }

        return <Button text="비활성화" width="79px" height={32} backgroundColor={COLORS.GRAY_02} fontSize={14} fontColor={COLORS.WHITE} fontWeight={500} borderColor="none" />;
      };

      copyTabs[ROOM_SERVICE] = {
        ...copyTabs[ROOM_SERVICE],
        button: buttonFilter(enableConcierge.roomService),
        subText: enableConcierge.roomServiceActivated,
      };

      copyTabs[AMENITY] = {
        ...copyTabs[AMENITY],
        button: buttonFilter(enableConcierge.amenity),
        subText: enableConcierge.amenityActivated,
      };

      setTabs(copyTabs);
    }
  }, [enableConcierge]);

  const onDeactivateHandler = (tab: Tab) => {
    if (tab.button?.props?.text === '비활성화') {
      if (tab.id === 'roomService' || tab.id === 'amenity') {
        onDeactivate({ type: tab.id });
      }
    } else {
      setModal({
        type: 'deactivate',
        isOpened: true,
        data: tab.id,
      });
    }
  };

  return (
    <div style={{ position: modal.type === 'deactivate' ? 'relative' : 'static' }}>
      <Wrapper>
        <div>
          {
            tabs.map((tab) => (<TabButton
              isSelected={selectedTab === tab.id}
              data={tab}
              key={tab.id}
              onSelect={onSelect}
              onDeactivate={onDeactivateHandler}
            />))
          }
        </div>
      </Wrapper>
      {
        (modal.type === 'deactivate' && modal.isOpened && (modal.data === 'roomService' || modal.data === 'amenity')) && <ServiceDeactivateModal isOpened={modal.isOpened} data={modal.data} onConfirm={onDeactivate} onClose={onCloseModal} />
      }
      {
        (modal.type === 'paymentOption' && modal.isOpened) && <PaymentOptionModal isOpened={modal.isOpened} onClose={onCloseModal} />
      }
    </div>
  );
};

export default ServiceTab;
