import React from 'react';
import { DateSelectItem, DateType, DropdownItem } from 'types';
import { COLORS, FONTS } from '@common/styles';
import Dropdown from 'components/ui/Dropdown';
import styled from 'styled-components';
import { stringToHHMM } from 'helpers';
import { LayoutFlexCenterAligned, MontserratTypography, NotoSansTypography } from '@common/components';

interface Props {
  keyName: string
  title: string
  onSelectTime: DateSelectItem
  onSelect: (keyName: string, type: DateType, time: number) => void
  containerStyles?: Record<string, string | number>
}

const DateSelectorGrid = styled.div`
  display: flex;
  align-items: center;
  margin-left: 54px;

  > div:nth-child(1) {
    margin-right: 18px;
  }
`;

interface DateDropdown {
  id: string
  data: DropdownItem[]
  type: DateType
  isSelectedItem: DropdownItem | undefined
  onSelect: (keyName: string, type: DateType, time: number) => void
  isDeActive?: boolean
}

const DateDropdown: React.FunctionComponent<DateDropdown> = ({ id, data, type, onSelect, isDeActive, isSelectedItem }) => (
  <Dropdown
    id={id}
    width="130px"
    height={48}
    isSelectedItem={isSelectedItem}
    onSelected={(item) => onSelect(id, type, (Number(item.id) * 60))}
    borderColor={COLORS.GRAY_02}
    borderWidth={1}
    isDeActive={isDeActive}
    itemContainerStyles={{
      position: 'absolute',
      zIndex: 2,
      maxHeight: 190,
    }}
    data={data}
  />
);

const OPEN_24 = { id: '24hour', name: '24시간' };

const returnDateFormat = (index: number) => ({
  id: index.toString(),
  name: `${index >= 24 ? '다음 날 ' : ''}${stringToHHMM((index) * 60, '0').time}`,
});

const DateSelector: React.FunctionComponent<Props> = ({ title, keyName, onSelectTime, onSelect, containerStyles }) => {
  const times = () => {
    const startTimeAt = 480 / 60;
    const endTimeAt = (onSelectTime.start || 480) / 60;

    return {
      start: new Array(24).fill('').map((_, index) => {
        const updatedIndex = startTimeAt + index;

        return returnDateFormat(updatedIndex);
      }),
      end: new Array(32 - (endTimeAt)).fill('').map((_, index) => {
        const updatedIndex = endTimeAt + (index + 1);

        return returnDateFormat(updatedIndex);
      }),
    };
  };

  const initialSelectedItem = (time?: number | undefined) => ({
    id: time ? returnDateFormat(time / 60).id : '-',
    name: time ? returnDateFormat(time / 60).name : '-',
  });

  const startTimeIsSelectedItemHandler = React.useMemo(() => {
    if (!onSelectTime.start && keyName === 'work') {
      return OPEN_24;
    }

    return initialSelectedItem(onSelectTime.start);
  }, [onSelectTime]);

  const startTimeDataHandler = React.useMemo(() => {
    const { start } = times();

    if (keyName === 'work') {
      return [OPEN_24].concat(start);
    }

    return start;
  }, []);

  return (
    <DateSelectorGrid style={containerStyles}>
      <div>
        <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>{title}</NotoSansTypography>
      </div>
      <LayoutFlexCenterAligned>
        <DateDropdown id={keyName} isSelectedItem={startTimeIsSelectedItemHandler} type="start" data={startTimeDataHandler} onSelect={onSelect} />
        <div style={{ margin: '0px 8px' }}>
          <MontserratTypography fontSize={20} lineHeight={20} letterSpacing={2} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD}>~</MontserratTypography>
        </div>
        <DateDropdown id={keyName} isSelectedItem={initialSelectedItem(onSelectTime.end)} type="end" data={times().end} onSelect={onSelect} isDeActive={!onSelectTime.start} />
      </LayoutFlexCenterAligned>
    </DateSelectorGrid>
  );
};

export default DateSelector;
