import React from 'react';
import { NotoSansTypography } from '@common/components';
import Modal from 'components/ui/Modal';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import { Button } from 'components/ui/Button';
import { DateSelectItem, DateType, BusinessHourOption } from 'types';
import DateSelector from './DateSelector';

interface State {
  work: DateSelectItem
  break: DateSelectItem[]
}

interface Props {
  isOpened: boolean
  onUpdate: (data: BusinessHourOption) => void
  onClose: () => void
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: 390px;
  border-radius: 16px;

  > div:nth-child(2) {
    display: flex;
    height: 200px;
    padding-left: 24px;
  }
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.GRAY_02};
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0px 34px;
`;

const FooterWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.GRAY_02};
  align-items: center;
  padding: 0px 40px;
  justify-content: flex-end;
  height: 100px;
`;

const Header = () => (
  <HeaderWrapper>
    <NotoSansTypography fontSize={24} lineHeight={24} letterSpacing={-1.2} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>일괄 변경</NotoSansTypography>
  </HeaderWrapper>
);

const Footer = ({ onClose, onSave }: { onClose: () => void, onSave: () => void }) => (
  <FooterWrapper>
    <Button fontWeight="bold" text="취소" backgroundColor={COLORS.WHITE} fontColor={COLORS.GRAY_01} width="158px" height={56} fontSize={20} styleProps={{ marginRight: 12 }} onClick={onClose} />
    <Button fontWeight="bold" borderWidth="0" text="적용" backgroundColor={COLORS.BROWN} fontColor={COLORS.WHITE} width="220px" height={56} fontSize={20} onClick={onSave} />
  </FooterWrapper>
);

const wrapperStyles = {
  marginLeft: 0,
  marginRight: 24,
};

const ServiceBusinessHoursDateModal: React.FunctionComponent<Props> = ({ isOpened, onClose, onUpdate }) => {
  const [state, setState] = React.useState<State>({
    work: {
      start: undefined,
      end: undefined,
    },
    break: [
      {
        start: undefined,
        end: undefined,
      },
      {
        start: undefined,
        end: undefined,
      },
    ],
  });

  const onSelect = (keyName: string, type: DateType, time: number) => {
    if (keyName === 'work') {
      setState({
        ...state,
        work: {
          ...state.work,
          [type]: time,
          ...(type === 'start' ? { end: time + (60 * 9) } : {}),
        },
      });
    } else {
      const breakTimeIndex = Number(keyName.split('_')[1]) - 1;
      const copyBreakList = [...state.break];

      if (type === 'start') {
        copyBreakList[breakTimeIndex].end = time + 60;
      }

      copyBreakList[breakTimeIndex][type] = time;

      setState({
        ...state,
        break: copyBreakList,
      });
    }
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <Header />
        <div>
          <DateSelector keyName="work" title="영업 시간" onSelectTime={state.work} onSelect={onSelect} containerStyles={wrapperStyles} />
          <DateSelector keyName="break_1" title="휴게 시간" onSelectTime={state.break[0]} onSelect={onSelect} containerStyles={wrapperStyles} />
          <DateSelector keyName="break_2" title="휴게 시간" onSelectTime={state.break[0]} onSelect={onSelect} containerStyles={wrapperStyles} />
        </div>
        <Footer onClose={onClose} onSave={() => onUpdate(state)} />
      </Wrapper>
    </Modal>
  );
};

export default ServiceBusinessHoursDateModal;
