import React from 'react';
import { LayoutFlexCenterAligned } from '@common/components';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled(LayoutFlexCenterAligned)`
  height: 100%;
`;

interface Props {
  containerStyles?: Record<string, string | number>
}

const Loading: React.FunctionComponent<Props> = ({ containerStyles }) => (
  <Wrapper style={containerStyles}>
    <CircularProgress />
  </Wrapper>
);

export default Loading;
