import React from 'react';
import { useLocation } from 'react-router-dom';
import { List } from 'types/api';
import { LayoutFlex, NotoSansTypography } from '@common/components';
import { useGetAccommodationId } from 'helpers/hooks';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import { Button } from 'components/ui/Button';
import { UseInputState, UseInputUpdate } from 'types/hooks';
import toast from 'react-hot-toast';
import SearchBar from 'components/ui/SearchBar';
import Pagination from 'components/ui/Pagination';
import ServiceListRoomServiceItem from './RoomServiceItem';
import ServiceListAmenityItem from './AmenityItem';
import RoomServiceCreateUpdate from '../roomService/CreateUpdate';
import AmenityCreateUpdate from '../amenity/CreateUpdate';

interface WrapperProps {
  height?: number
  marginTop?: number | undefined
}

interface Props {
  data?: List<any>
  refetch: (params?: Record<string, any>) => void
  pagination?: UseInputState
  setPagination?: (pagination: UseInputUpdate) => void
  categoryLength: number
}

type ModalTypes = 'serviceNoticeCreate' | 'serviceNoticeUpdate' | 'roomServiceCreate' | 'amenityCreate' | 'roomServiceUpdate' | 'amenityUpdate' | '';
type ContentsTypes = 'roomService' | 'amenity' | 'serviceNotice';

interface Modal {
  type: ModalTypes
  isOpened: boolean
  data?: any
}

interface HeaderContentProps {
  type: string
  onCreate: (type: ContentsTypes) => void
  onSearch: (text: string) => void
  categoryLength: number
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  margin-top: ${({ marginTop }) => marginTop || 67}px;
  width: 100%;
  padding: 16px 24px 0px 24px;
  border-radius: 8px;

  > div {
    width: 100%;
  }
`;

const ListWrapper = styled(LayoutFlex)`
  height: 100%;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderRoomDefaultItem = ({ onCreate, onSearch, categoryLength }: { onCreate: () => void, onSearch: (text: string) => void, categoryLength: number }) => (
  <>
    <SearchBar width="320px" height={48} placeholder="상품명 입력" onSearch={onSearch} />
    <Button text="상품 추가 +" width="155px" height={48} backgroundColor={categoryLength ? COLORS.WHITE : COLORS.GRAY_04} borderColor={categoryLength ? COLORS.BROWN : COLORS.GRAY_04} fontColor={categoryLength ? COLORS.BROWN : COLORS.GRAY_B7} fontWeight="bold" onClick={onCreate} />
  </>
);

const HeaderRoomServiceNoticeItem = ({ onClick }: { onClick: () => void }) => (
  <>
    <div style={{ marginRight: 12 }}>
      <NotoSansTypography fontColor={COLORS.PINK_RED}>안내문은 최대 6개까지 가능합니다.</NotoSansTypography>
    </div>
    <Button text="배너 추가 +" width="139px" height={48} backgroundColor={COLORS.WHITE} borderColor={COLORS.GRAY_02} fontColor={COLORS.GRAY_02} fontWeight="bold" onClick={onClick} />
  </>
);

const HeaderContent: React.FunctionComponent<HeaderContentProps> = ({ type, onCreate, onSearch, categoryLength }) => (
  <LayoutFlex alignItems="center">
    {
      (type === 'roomService' || type === 'amenity') && <HeaderRoomDefaultItem categoryLength={categoryLength} onSearch={onSearch} onCreate={() => onCreate(type)} />
    }
    {
      (type === 'serviceNotice') && <HeaderRoomServiceNoticeItem onClick={() => onCreate(type)} />
    }
  </LayoutFlex>
);

const ServiceList: React.FunctionComponent<Props> = ({ data, pagination, setPagination, refetch, categoryLength }) => {
  const { accommodationId } = useGetAccommodationId();
  const { pathname } = useLocation();
  const type = pathname.split('/').pop();

  const [modal, setModal] = React.useState<Modal>({
    type: '',
    isOpened: false,
    data: null,
  });

  const useService = React.useMemo(() => {
    const returnText = {
      text: '',
      notice: '',
    };

    if (type === 'roomService') {
      returnText.text = '룸서비스';
      returnText.notice = '* 카테고리 미지정 시 상품이 노출되지 않습니다.';
    } else if (type === 'amenity') {
      returnText.text = '어메니티';
      returnText.notice = '* 카테고리 미지정 시 상품이 노출되지 않습니다.';
    } else if (type === 'serviceNotice') {
      returnText.text = '배너 슬라이드';
    }

    return returnText;
  }, []);

  const onCloseModal = React.useCallback(() => {
    setModal({
      isOpened: false,
      type: '',
    });

    refetch();
  }, []);

  const onCreate = React.useCallback((type: ContentsTypes) => {
    if (!categoryLength) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>카테고리를 생성해주세요.</NotoSansTypography>);
    }

    setModal({
      type: `${type}Create`,
      isOpened: true,
    });
  }, [categoryLength]);

  const onUpdate = React.useCallback((type: ContentsTypes, data: any) => {
    setModal({
      type: `${type}Update`,
      isOpened: true,
      data,
    });
  }, []);

  const onSearch = (value: string) => {
    if (pagination) {
      refetch({
        accommodationId,
        connectionArgs: {
          page: pagination.page,
          limit: pagination.limit,
        },
        where: {
          key: type === 'roomService' ? 'title' : 'name',
          value,
        },
      });
    }
  };

  return (
    <>
      <Wrapper marginTop={(type === 'serviceNotice' && 230) || undefined}>
        <div style={{ height: '100%' }}>
          <Grid style={{ justifyContent: 'space-between' }}>
            <LayoutFlex>
              <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD} lineHeight={20} letterSpacing={-1}>{useService.text}</NotoSansTypography>
              {
                useService.notice && (
                  <div style={{ paddingLeft: 24 }}>
                    <NotoSansTypography fontColor={COLORS.PINK_RED} fontSize={14} fontWeight={FONTS.NOTO_SANS.BLACK} lineHeight={20} letterSpacing={-1}>{useService.notice}</NotoSansTypography>
                  </div>
                )
              }
            </LayoutFlex>
            <Grid>
              <HeaderContent type={type || ''} onSearch={onSearch} onCreate={onCreate} categoryLength={categoryLength} />
            </Grid>
          </Grid>
          <ListWrapper>
            {
              type === 'roomService' && <ServiceListRoomServiceItem onUpdate={onUpdate} data={data} refetch={refetch} />
            }
            {
              type === 'amenity' && <ServiceListAmenityItem onUpdate={onUpdate} data={data} refetch={refetch} />
            }
            {/* {
              type === 'serviceNotice' && <ServiceListServiceNoticeItem onUpdate={onUpdate} data={SERVICE_NOTICE_DUMMY} />
            } */}
          </ListWrapper>
        </div>
        {
          !data?.pageInfo?.totalCount || <Pagination paginationInfo={pagination} setCurrentPage={setPagination} />
        }
      </Wrapper>
      {/* {
        ((modal.type === 'serviceNoticeCreate' || modal.type === 'serviceNoticeUpdate') && modal.isOpened) && <ServiceNoticeModal type={modal.type === 'serviceNoticeCreate' ? 'create' : 'update'} isOpened={modal.isOpened} onClose={onCloseModal} />
      } */}
      {
        ((modal.type === 'roomServiceCreate' || modal.type === 'roomServiceUpdate') && modal.isOpened) && <RoomServiceCreateUpdate type={modal.type === 'roomServiceCreate' ? 'create' : 'update'} isOpened={modal.isOpened} data={modal.data} onClose={onCloseModal} />
      }
      {
        ((modal.type === 'amenityCreate' || modal.type === 'amenityUpdate') && modal.isOpened) && <AmenityCreateUpdate type={modal.type === 'amenityCreate' ? 'create' : 'update'} isOpened={modal.isOpened} data={modal.data} onClose={onCloseModal} />
      }
    </>
  );
};

export default ServiceList;
