import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import { LayoutFlex, LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';
import { Button } from 'components/ui/Button';
import Input from 'components/ui/Input';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CreateOrderRequestTemplateMutation, DeleteOrderRequestTemplateMutation, GetOrderRequestTemplatesQuery, UpdateOrderRequestTemplateMutation } from 'graphql/order';
import { OrderRequestType } from 'types';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { useGetAccommodationId } from 'helpers/hooks';
import SimpleBar from 'simplebar-react';

type ListItemData = {
  id: string | null
  request: string
  type: OrderRequestType
}

type ListItem = {
  title: string
  type: OrderRequestType
  data: ListItemData[]
}

interface DataItemProps {
  data: ListItemData
  onChangeText: (key: OrderRequestType, index: number, value: string) => void
  onSave: () => void
  onDelete: () => void
  index: number
  autoFocus: boolean
}

interface ItemProps {
  data: ListItem
  onChangeText: (type: OrderRequestType, index: number, text: string) => void
  onCreateItem: (type: OrderRequestType) => void
  onSave: (data: ListItemData) => void
  onDelete: (data: ListItemData, index: number) => void
}

interface List {
  ROOM_SERVICE: ListItem
  AMENITY: ListItem
}

const Wrapper = styled(LayoutFlex)`
  margin-top: 40px;

  > div:nth-child(1) {
    margin-right: 50px;
  }
`;

const ItemWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  border-radius: 8px;
  padding: 34px 19px;
`;

const DataWrapper = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DataGrid = styled(LayoutFlex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 64px;
  border-radius: 10px;
  border: 1px solid ${COLORS.GRAY_B7};
  padding: 0px 20px;
  margin-bottom: 24px;
`;

const OrderRequestNotFoundWrapper = styled(LayoutFlexCenterAligned)`
  width: 100%;
  height: 554px;
  border-radius: 10px;
  flex-direction: column;
  border: 1px solid ${COLORS.BROWN};
  border-style: dashed;
`;

const OrderRequestNotFound = ({ onClick }: { onClick: () => void }) => (
  <OrderRequestNotFoundWrapper>
    <NotoSansTypography fontSize={48} fontColor={COLORS.BROWN}>+</NotoSansTypography>
    <div style={{ margin: '24px 0px 16px 0px' }}>
      <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontSize={18} lineHeight={18} letterSpacing={-0.9} fontColor={COLORS.BROWN}>요청사항 추가</NotoSansTypography>
    </div>
    <Button text="이곳을 선택하세요" width="160px" height={33} borderWidth="0" borderRadius={16} onClick={onClick} />
  </OrderRequestNotFoundWrapper>
);

const DataItem: React.FunctionComponent<DataItemProps> = ({
  data, onChangeText, index, onDelete, onSave, autoFocus,
}) => {
  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.currentTarget.blur();
    }
  };

  return (
    <DataGrid>
      <div style={{ width: '90%' }}>
        <Input autoFocus={autoFocus} placeholder="요청사항 입력" text={data.request} height={60} backgroundColor={COLORS.WHITE} containerStyleProps={{ marginBottom: 0, padding: 0 }} onChange={(value) => onChangeText(data.type, index, value)} onBlur={onSave} onKeyDown={onKeyDown} />
      </div>
      <div style={{ cursor: 'pointer' }}>
        <DeleteButton width={24} height={24} onClick={onDelete} />
      </div>
    </DataGrid>
  );
};

const Item: React.FunctionComponent<ItemProps> = ({ data, onChangeText, onCreateItem, onDelete, onSave }) => (
  <ItemWrapper>
    <LayoutFlex justifyContent="space-between" alignItems="center" style={{ marginBottom: 40 }}>
      <div>
        <NotoSansTypography fontSize={20} lineHeight={20} letterSpacing={-0.6} fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_86}>{data.title}</NotoSansTypography>
      </div>
      <LayoutFlex alignItems="center">
        <Button text="요청사항 추가 +" width="171px" height={48} backgroundColor={COLORS.WHITE} fontColor={COLORS.BROWN} borderColor={COLORS.BROWN} styleProps={{ marginLeft: 32 }} onClick={() => onCreateItem(data.type)} />
      </LayoutFlex>
    </LayoutFlex>
    <DataWrapper>
      {/* {
        data.data.map((item, index) => <DataItem index={index} data={item} key={item.id} onChangeText={onChangeText} onDelete={() => onDelete(item, index)} onSave={() => onSave(item)} />)
      } */}
      {
        data.data.length > 0 ? data.data.map((item, index) => <DataItem autoFocus={!item.id} index={index} data={item} key={item.id} onChangeText={onChangeText} onDelete={() => onDelete(item, index)} onSave={() => onSave(item)} />) : <OrderRequestNotFound onClick={() => onCreateItem(data.type)} />
      }
      {/* <DataGrid style={{ backgroundColor: COLORS.GRAY_05, borderColor: COLORS.GRAY_01 }}>
        <LayoutFlex alignItems="center">
          <div style={{ marginRight: 10 }}>
            <DirectInput width={15} height={16} />
          </div>
          <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} letterSpacing={-0.8}>직접입력</NotoSansTypography>
        </LayoutFlex>
        <div>
          <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_02}>고객의 요청사항을 직접 받을 수 있습니다.</NotoSansTypography>
        </div>
      </DataGrid> */}
    </DataWrapper>
  </ItemWrapper>
);

const OrderRequest: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();

  const [emitCreateOrderRequestTemplateMutation] = useMutation(CreateOrderRequestTemplateMutation);
  const [emitUpdateOrderRequestTemplateMutation] = useMutation(UpdateOrderRequestTemplateMutation);
  const [emitDeleteOrderRequestTemplateMutation] = useMutation(DeleteOrderRequestTemplateMutation);

  const [getOrderRequestTemplatesQuery] = useLazyQuery(GetOrderRequestTemplatesQuery);

  const [list, setList] = React.useState<List>({
    ROOM_SERVICE: {
      title: '요청사항',
      type: 'ROOM_SERVICE',
      data: [],
    },
    AMENITY: {
      title: '어메니티',
      type: 'AMENITY',
      data: [],
    },
  });

  const getRoomServiceOrderRequestTemplate = async (config?: { isSave?: boolean }) => {
    const { data: roomServiceData } = await getOrderRequestTemplatesQuery({
      variables: {
        accommodationId,
        // type: 'ROOM_SERVICE',
      },
      fetchPolicy: 'network-only',
    });

    if (config?.isSave) {
      setList({
        ...list,
        ROOM_SERVICE: {
          ...list.ROOM_SERVICE,
          data: roomServiceData.getOrderRequestTemplates,
        },
      });
    }

    return roomServiceData;
  };

  const getAmenityOrderRequestTemplate = async (config?: { isSave?: boolean }) => {
    const { data: amenityData } = await getOrderRequestTemplatesQuery({
      variables: {
        accommodationId,
        type: 'AMENITY',
      },
      fetchPolicy: 'network-only',
    });

    if (config?.isSave) {
      setList({
        ...list,
        AMENITY: {
          ...list.AMENITY,
          data: amenityData.getOrderRequestTemplates,
        },
      });
    }

    return amenityData;
  };

  const getOrderRequestTemplate = async () => {
    const roomServiceTemplate = await getRoomServiceOrderRequestTemplate();
    // const amenityTemplate = await getAmenityOrderRequestTemplate();

    setList({
      ...list,
      ROOM_SERVICE: {
        ...list.ROOM_SERVICE,
        data: roomServiceTemplate.getOrderRequestTemplates,
      },
      // AMENITY: {
      //   ...list.AMENITY,
      //   data: amenityTemplate.getOrderRequestTemplates,
      // },
    });
  };

  React.useEffect(() => {
    getOrderRequestTemplate();
  }, []);

  const onChangeText = React.useCallback((type: OrderRequestType, index: number, request: string) => {
    const newRequest = [...list.ROOM_SERVICE.data];
    // const newRequest = [...list[type].data];

    newRequest[index] = {
      ...newRequest[index],
      request,
    };

    console.log('newRequest', newRequest);

    setList({
      ...list,
      ROOM_SERVICE: {
        ...list.ROOM_SERVICE,
        data: newRequest,
      },
      // [type]: {
      //   ...list[type],
      //   data: newRequest,
      // },
    });
  }, [list]);

  const onSave = (data: ListItemData) => {
    if (!data.request) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항을 입력해주세요.</NotoSansTypography>);
    }

    confirmAlert({
      title: `해당 요청사항을 ${data.id ? '변경' : '추가'}하시겠습니까?`,
      buttons: [
        {
          label: data.id ? '변경' : '추가',
          onClick: async () => {
            if (!data.id) {
              await toast.promise(
                emitCreateOrderRequestTemplateMutation({
                  variables: {
                    input: [
                      {
                        accommodationId,
                        // type: data.type,
                        request: data.request,
                      },
                    ],
                  },
                }),
                {
                  loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>생성중...</NotoSansTypography>,
                  success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 생성이 성공적으로 완료되었습니다</NotoSansTypography>,
                  error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 생성 중 문제가 발생했습니다.</NotoSansTypography>,
                },
              );

              getOrderRequestTemplate();

              // if (data.type === 'ROOM_SERVICE') {
              //   getRoomServiceOrderRequestTemplate({ isSave: true });
              // } else {
              //   getAmenityOrderRequestTemplate({ isSave: true });
              // }
            } else {
              await toast.promise(
                emitUpdateOrderRequestTemplateMutation({
                  variables: {
                    input: [
                      {
                        id: data.id,
                        accommodationId,
                        request: data.request,
                      },
                    ],
                  },
                }),
                {
                  loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
                  success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 변경이 성공적으로 완료되었습니다</NotoSansTypography>,
                  error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 변경 중 문제가 발생했습니다.</NotoSansTypography>,
                },
              );

              getOrderRequestTemplate();

              // if (data.type === 'ROOM_SERVICE') {
              //   getRoomServiceOrderRequestTemplate({ isSave: true });
              // } else {
              //   getAmenityOrderRequestTemplate({ isSave: true });
              // }
            }
          },
        },
        {
          label: '취소',
          onClick: () => { },
        },
      ],
    });
  };

  const onCreateItem = (type: OrderRequestType) => {
    const foundLastElement = list[type].data[list[type].data.length - 1];

    if (foundLastElement?.id || list[type].data.length === 0) {
      const copyItem = [...list[type].data];

      setList({
        ...list,
        [type]: {
          ...list[type],
          data: copyItem.concat({ id: null, request: '', type }),
        },
      });
    }
  };

  const onDeleteItem = (data: ListItemData, index: number) => {
    if (data.id) {
      confirmAlert({
        title: '해당 요청사항을 정말 삭제하시겠습니까?',
        message: '삭제 버튼을 누를 시 해당 요청사항이 삭제됩니다.',
        buttons: [
          {
            label: '삭제',
            onClick: async () => {
              await toast.promise(
                emitDeleteOrderRequestTemplateMutation({
                  variables: {
                    id: [data.id],
                  },
                }),
                {
                  loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>삭제중...</NotoSansTypography>,
                  success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 삭제가 성공적으로 완료되었습니다.</NotoSansTypography>,
                  error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항 삭제 중 문제가 발생하였습니다.</NotoSansTypography>,
                },
              );

              getOrderRequestTemplate();
              // if (data.type === 'ROOM_SERVICE') {
              //   getRoomServiceOrderRequestTemplate({ isSave: true });
              // } else {
              //   getAmenityOrderRequestTemplate({ isSave: true });
              // }
            },
          },
          {
            label: '취소',
            onClick: () => { },
          },
        ],
      });
    } else {
      const copyItem = [...list[data.type].data];

      copyItem.splice(index, 1);

      setList({
        ...list,
        [data.type]: {
          ...list[data.type],
          data: copyItem,
        },
      });
    }
  };

  return (
    <Wrapper>
      <Item data={list.ROOM_SERVICE} onChangeText={onChangeText} onCreateItem={onCreateItem} onSave={onSave} onDelete={onDeleteItem} />
      {/* <Item data={list.AMENITY} onChangeText={onChangeText} onCreateItem={onCreateItem} onSave={onSave} onDelete={onDeleteItem} /> */}
    </Wrapper>
  );
};

export default OrderRequest;
