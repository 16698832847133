import React from 'react';
import Modal from 'components/ui/Modal';
import { COLORS, FONTS } from '@common/styles';
import styled, { css } from 'styled-components';
import { LayoutFlex, LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';
import { Button } from 'components/ui/Button';
import NotFound from 'components/ui/NotFound';
import Checkbox from 'components/ui/Checkbox';
import { ReactComponent as Calendar } from 'images/ico_calendar.svg';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { ReactComponent as ExclamationMark } from 'images/exclamationmark.svg';
import Dropdown from 'components/ui/Dropdown';
import ServiceDeactivateModal from 'containers/service/DeactivateModal';
import { getRoomServiceCategoryQuery, getRoomServicesQuery, updateRoomServiceMutation } from 'graphql/roomService';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getConciergeAmenitiesQuery, getConciergeAmenityCategoriesQuery, updateConciergeAmenityMutation } from 'graphql/amenity';
import { RoomService, RoomServiceCategory, RoomServiceOption } from 'types/roomService';
import { ConciergeAmenity, ConciergeAmenityCategory } from 'types/amenity';
import { DropdownItem } from 'types';
import { useGetAccommodationId, usePagination } from 'helpers/hooks';
import { List } from 'types/api';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getConciergeWorkSettingsQuery } from 'graphql/settings';
import toast from 'react-hot-toast';
import DatePickerModal from 'components/ui/DatePickerModal';
import SimpleBar from 'simplebar-react';

interface Props {
  isOpened: boolean
  onClose: () => void
}

interface TagItemProps {
  data: DropdownItem[]
  isSelectedItem: DropdownItem | null
  onSelectItem: (selectedItem: DropdownItem) => void
}

type Categories = 'roomService' | 'amenity';

interface Modal {
  type: 'calendar' | 'soldOut' | '',
  isOpened: boolean
  data: null
}

interface TabProps {
  title: string
  children: React.ReactNode
  borderRight?: boolean
  containerStyles?: Record<string, string | number>
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 1255px;
  height: 860px;
  border-radius: 16px;
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.GRAY_02};
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0px 34px;
`;

const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  border-top: 1px solid ${COLORS.GRAY_02};
  align-items: center;
  padding: 0px 40px;
  justify-content: flex-end;
  height: 94px;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 670px;
  padding: 30px 24px;
  border-right: ${({ borderRight }: { borderRight?: boolean }) => borderRight && `1px solid ${COLORS.GRAY_02}`};
`;

const PanelWrapper = styled(LayoutFlex)`
  width: 360px;
  cursor: pointer;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 24px;
  border-radius: 8px;

  ${({ isSelected }: { isSelected: boolean }) => css`
    background-color: ${isSelected ? COLORS.BROWN_0DOT03 : COLORS.WHITE};
    border: 1px solid ${isSelected ? COLORS.BROWN : COLORS.GRAY_02};
  `}
`;

const MenuWrapper = styled(LayoutFlex)`
  width: 100%;
  cursor: pointer;
  min-height: 78px;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  border: 1.5px solid ${COLORS.GRAY_02};
  margin-bottom: 26px;
  padding: 16px 14px;
`;

const SoldOutNoticeWrapper = styled(LayoutFlexCenterAligned)`
  position: absolute;
  right: 40px;
  top: -50px;
  box-shadow: 0 0 50px 0 ${COLORS.WHITE};
  border: 1px solid ${COLORS.PINK_RED};
  border-radius: 6px;
  background-color: ${COLORS.PINK_RED_02};
  padding: 18px;
  width: 432px;
  height: 32px;
`;

const TagGrid = styled(LayoutFlex)`
  margin-bottom: 24px;

  > div:nth-child(1) {
    margin-right: 24px;
  }
`;

const SoldOutNotice = () => (
  <SoldOutNoticeWrapper>
    <ExclamationMark style={{ marginRight: 8 }} />
    <NotoSansTypography fontColor={COLORS.PINK_RED}>품절 기간 설정 미설정시 다음날 오픈 전까지 품절처리됩니다.</NotoSansTypography>
  </SoldOutNoticeWrapper>
);

const Header = () => (
  <HeaderWrapper>
    <NotoSansTypography fontSize={24} lineHeight={24} letterSpacing={-1.2} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>메뉴 품절 처리</NotoSansTypography>
  </HeaderWrapper>
);

const Footer = ({ soldOutPeriod, onClick }: { soldOutPeriod: string, onClick: (type: 'soldOut' | 'calendar') => void }) => (
  <FooterWrapper>
    <SoldOutNotice />
    <Button fontWeight="bold" text="품절 기간 설정" backgroundColor={COLORS.WHITE} fontColor={COLORS.GRAY_01} width="200px" height={56} fontSize={20} styleProps={{ marginRight: 12 }} onClick={() => onClick('calendar')} icon={<Calendar width={18} height={20} />} />
    <Button fontWeight="bold" borderWidth="0" text={`${soldOutPeriod} 품절 처리`} backgroundColor={COLORS.BROWN} fontColor={COLORS.WHITE} width="220px" height={56} fontSize={20} onClick={() => onClick('soldOut')} justifyContent="flex-start" />
  </FooterWrapper>
);

const Tab = ({
  title, children, borderRight, containerStyles,
}: TabProps) => (
  <TabWrapper borderRight={borderRight} style={containerStyles}>
    <NotoSansTypography fontSize={18} fontWeight={FONTS.NOTO_SANS.BOLD} lineHeight={18} letterSpacing={-0.9}>{title}</NotoSansTypography>
    <div style={{ paddingTop: title ? 30 : 0, height: '100%' }}>
      {children}
    </div>
  </TabWrapper>
);

const PanelButton = ({ title, isSelected, onClick }: { title: string, isSelected: boolean, onClick: () => void }) => (
  <PanelWrapper onClick={onClick} isSelected={isSelected}>
    <NotoSansTypography fontSize={18} fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={isSelected ? COLORS.BROWN : COLORS.BLACK}>{title}</NotoSansTypography>
    <ArrowForwardIosOutlinedIcon />
  </PanelWrapper>
);

interface menuProps {
  data: any
  isSelected: boolean
  onClick: (type: 'soldOut', data?: any) => void
  onSelect: (newItem: RoomService | ConciergeAmenity) => void
}

const Menu: React.FunctionComponent<menuProps> = ({ data, onClick, onSelect, isSelected }) => {
  const soldOutPeriod = React.useMemo(() => {
    if (data.soldOutPeriod) {
      const soldOutPeriodDate = moment(data.soldOutPeriod);

      if (moment().isBefore(soldOutPeriodDate)) {
        return true;
      }
    }

    return false;
  }, [data.soldOutPeriod]);

  return (
    <MenuWrapper style={{ borderColor: isSelected ? COLORS.BROWN : COLORS.GRAY_02 }}>
      <div style={{ width: '100%', marginLeft: 8 }}>
        <LayoutFlex justifyContent="space-between">
          <LayoutFlex style={{ height: 24 }} alignItems="center">
            {
              !soldOutPeriod && <Checkbox isChecked={isSelected} onClick={() => onSelect(data)} />
            }
            <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>{data.title || data.name}</NotoSansTypography>
          </LayoutFlex>
          {
            soldOutPeriod && (
              <div>
                <Button width="89px" height={34} backgroundColor={COLORS.PINK_RED} fontWeight={FONTS.NOTO_SANS.MEDIUM} fontColor={COLORS.WHITE} text="품절 해제" borderWidth="0" onClick={() => onClick('soldOut', data)} />
              </div>
            )
          }
        </LayoutFlex>
        <div style={soldOutPeriod ? { marginBottom: 14 } : { margin: '8px 32px' }}>
          <NotoSansTypography fontColor={COLORS.GRAY_01}>{`${data.description.slice(0, 25)}${data.description.length > 25 ? '...' : ''}`}</NotoSansTypography>
        </div>
        {soldOutPeriod && <NotoSansTypography fontColor={COLORS.PINK_RED} fontWeight={FONTS.NOTO_SANS.BOLD}>{moment(data.soldOutPeriod).format('YY.MM.DD / HH:mm시 까지 품절')}</NotoSansTypography>}
      </div>
    </MenuWrapper>
  );
};

const TagItem = ({ data, onSelectItem, isSelectedItem }: TagItemProps) => (
  <TagGrid>
    <Dropdown width="275px" isSelectedItem={isSelectedItem || { name: '전체', id: 'null' }} borderColor={COLORS.GRAY_02} borderWidth={1} data={[{ name: '전체', id: 'null' }].concat(data)} onSelected={onSelectItem} itemContainerStyles={{ height: 200 }} />
  </TagGrid>
);

interface TabState {
  roomService: {
    name: '룸서비스'
    categories: RoomServiceCategory[],
    data: List<RoomService>
  },
  amenity: {
    name: '어메니티',
    categories: ConciergeAmenityCategory[],
    data: List<ConciergeAmenity>,
  },
}

interface selectedOptions {
  tab: Categories
  category: DropdownItem | null
  items: RoomService[] | ConciergeAmenity[]
}

const SoldOut: React.FunctionComponent<Props> = ({ isOpened, onClose }) => {
  const { accommodationId } = useGetAccommodationId();
  const { pagination, setPagination } = usePagination();
  const [getConciergeWorkSettings] = useLazyQuery(getConciergeWorkSettingsQuery);
  const [getRoomServices] = useLazyQuery(getRoomServicesQuery, {
    variables: {
      accommodationId,
      connectionArgs: {
        page: pagination.page,
        limit: pagination.limit,
      },
    },
  });

  const [getConciergeAmenities] = useLazyQuery(getConciergeAmenitiesQuery, {
    variables: {
      accommodationId,
      connectionArgs: {
        page: pagination.page,
        limit: pagination.limit,
      },
    },
  });

  const [getRoomServiceCategory] = useLazyQuery(getRoomServiceCategoryQuery, {
    variables: {
      accommodationId,
    },
  });

  const [getConciergeAmenityCategories] = useLazyQuery(getConciergeAmenityCategoriesQuery, {
    variables: {
      accommodationId,
    },
  });

  const [emitUpdateRoomService] = useMutation(updateRoomServiceMutation);
  const [emitUpdateConciergeAmenity] = useMutation(updateConciergeAmenityMutation);

  const [tabs, setTabs] = React.useState<TabState>({
    roomService: {
      name: '룸서비스',
      categories: [],
      data: {
        edges: [],
        pageInfo: null,
      },
    },
    amenity: {
      name: '어메니티',
      categories: [],
      data: {
        edges: [],
        pageInfo: null,
      },
    },
  });

  const [selectedOptions, setSelectedOptions] = React.useState<selectedOptions>({
    tab: 'roomService',
    category: null,
    items: [],
  });

  const [modal, setModal] = React.useState<Modal>({
    isOpened: false,
    type: '',
    data: null,
  });

  const [soldOutPeriod, setSoldOutPeriod] = React.useState<string | null>(null);

  const initialSetting = async () => {
    const { data } = await getRoomServiceCategory();
    const { data: roomServiceList } = await getRoomServices();

    setTabs({
      ...tabs,
      roomService: {
        ...tabs.roomService,
        categories: data?.getRoomServiceCategory || [],
        data: roomServiceList.getRoomServices,
      },
    });
  };

  React.useEffect(() => {
    initialSetting();
  }, []);

  const onSelectItem = React.useCallback(async (category: Categories) => {
    let categories = [];
    let list: any[] = [];

    if (category === 'roomService') {
      const { data } = await getRoomServiceCategory();
      const { data: roomServiceList } = await getRoomServices();

      categories = data?.getRoomServiceCategory;
      list = roomServiceList.getRoomServices;
    } else {
      const { data } = await getConciergeAmenityCategories();
      const { data: amenityList } = await getConciergeAmenities();

      categories = data?.getConciergeAmenityCategories;
      list = amenityList.getConciergeAmenities;
    }

    setTabs({
      ...tabs,
      [category]: {
        ...tabs[category],
        categories,
        data: list,
      },
    });

    setSelectedOptions({
      ...selectedOptions,
      tab: category,
      category: null,
      items: [],
    });
  }, []);

  const tagDataNotfound = (tabs[selectedOptions.tab].categories.length === 0);

  const onSave = React.useCallback(async (data?: { resetSoldOutPeriod?: boolean, item?: any }) => {
    if (!data?.resetSoldOutPeriod && !selectedOptions.items.length) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>메뉴를 선택해주세요.</NotoSansTypography>);
    }

    let copySoldOutPeriod = data?.resetSoldOutPeriod ? null : soldOutPeriod;

    if (!data?.resetSoldOutPeriod) {
      const response = await getConciergeWorkSettings({
        variables: {
          accommodationId,
          weekDay: [],
        },
      });

      if (response.data?.getConciergeWorkSettings.length) {
        const DAY_WEEKS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const date = soldOutPeriod ? moment(soldOutPeriod) : moment().add('1', 'days');

        const reduceSettings = response.data?.getConciergeWorkSettings.reduce((accumulator: any, currentValue: any) => {
          const { weekDay, workType } = currentValue;

          if (!accumulator[weekDay]) {
            accumulator[weekDay] = undefined;
          }

          if (workType === 'WORK') {
            accumulator[weekDay] = currentValue.value.substring(0, 4);
          }

          return accumulator;
        }, {});

        const tomorrowDay = reduceSettings[DAY_WEEKS[date.day()]];

        const { start, end } = {
          start: tomorrowDay.substring(0, 2),
          end: tomorrowDay.substring(2, 4),
        };

        copySoldOutPeriod = `${date.format('YYYY-MM-DD')}T${start}:${end}`;
        console.log('copySoldOutPeriod', copySoldOutPeriod);
      }
    }

    const responseMessage = {
      loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
      success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`품절 처리${!copySoldOutPeriod ? '해제' : ''}가 성공적으로 완료되었습니다.`}</NotoSansTypography>,
      error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>품절 처리 중 문제가 발생하였습니다.</NotoSansTypography>,
    };

    const targetData = data?.item ? [data?.item] : selectedOptions.items;

    if (selectedOptions.tab === 'roomService') {
      // ...item하면 되는 문제인데 아래와 같이 데이터들을 정의한 이유는 GQL에서 __typename 에러가 발생하여서 API CALL이 불가능한 상황입니다.
      // 해당 이슈를 해결하려면 작업이 딜레이 될 것 같아서 아래와 같이 작업을 진행했습니다.
      // 또한 특정 (orderRequest)값은 update Inputs에 정의되지 않는 item이라서 데이터를 빼줘야합니다.

      await toast.promise(
        emitUpdateRoomService({
          variables: {
            accommodationId,
            input: targetData.map((item: any) => ({
              accommodationId,
              id: item.id,
              title: item.title,
              description: item.description,
              price: item.price,
              categories: item.categories?.map(({ id }: { id: string }) => id),
              summary: item.summary,
              leadTime: item.leadTime,
              thumbnail: item.thumbnail,
              images: item.images,
              soldOutPeriod: copySoldOutPeriod,
              options: item.options.map((option: RoomServiceOption) => ({
                roomServiceId: item.id,
                accommodationId,
                id: option.id,
                name: option.name,
                price: option.price,
                stock: 0,
              })),
            })),
          },
        }),
        responseMessage,
      );

      onSelectItem('roomService');
    } else {
      await toast.promise(
        emitUpdateConciergeAmenity({
          variables: {
            input: targetData.map((item: any) => ({
              accommodationId,
              id: item.id,
              name: item.name,
              price: item.price,
              deliveryTime: item.deliveryTime,
              description: item.description,
              thumbnail: item.thumbnail,
              categories: item.categories?.map(({ id }: { id: string }) => id),
              images: item.images,
              soldOutPeriod: copySoldOutPeriod,
            })),
          },
        }),
        responseMessage,
      );

      onSelectItem('amenity');
    }
  }, [selectedOptions.items, soldOutPeriod]);

  const soldOutHandler = (type: 'soldOut' | 'calendar') => {
    if (type === 'calendar') {
      setModal({
        type,
        isOpened: true,
        data: null,
      });
    } else {
      onSave();
    }
  };

  const menuEventHandler = (type: 'soldOut' | 'calendar', data?: any) => {
    if (type === 'soldOut') {
      onSave({ resetSoldOutPeriod: true, item: data });
    } else {
      setModal({
        type,
        isOpened: true,
        data,
      });
    }
  };

  const onCloseModal = React.useCallback(() => {
    setModal({
      type: '',
      data: null,
      isOpened: false,
    });
  }, []);

  const onSelectCategory = async (selectedItem: DropdownItem) => {
    setSelectedOptions({
      ...selectedOptions,
      category: selectedItem,
    });

    let list = [];

    const variables = {
      accommodationId,
      connectionArgs: {
        page: pagination.page,
        limit: pagination.limit,
      },
      categories: selectedItem.id === 'null' ? null : [selectedItem.id],
    };

    if (selectedOptions.tab === 'roomService') {
      const { data: roomServiceList } = await getRoomServices({
        fetchPolicy: 'network-only',
        variables,
      });

      list = roomServiceList?.getRoomServices;
    } else {
      const { data: amenityList } = await getConciergeAmenities({
        fetchPolicy: 'network-only',
        variables,
      });

      list = amenityList?.getConciergeAmenities;
    }

    setTabs({
      ...tabs,
      [selectedOptions.tab]: {
        ...tabs[selectedOptions.tab],
        data: list,
      },
    });

    setPagination({
      page: 1,
      totalCount: 0,
      totalPages: 0,
    });
  };

  const onSelectListItem = (newItem: RoomService | ConciergeAmenity) => {
    const copySelectList = [...selectedOptions.items];
    const findDuplicateId = copySelectList.findIndex((item) => item.id === newItem.id);

    let items = [];

    if (findDuplicateId !== -1) {
      copySelectList.splice(findDuplicateId, 1);
      items = copySelectList;
    } else {
      // @ts-ignore
      items = selectedOptions.items.concat(newItem);
    }

    setSelectedOptions({
      ...selectedOptions,
      items,
    });
  };

  const onSelectDate = (date: Date) => {
    setSoldOutPeriod(moment(date).format('YYYYMMDD'));
    onCloseModal();
  };

  const fetchMore = async () => {
    const page = pagination.page + 1;

    const variables = {
      accommodationId,
      connectionArgs: {
        page,
        limit: pagination.limit,
      },
      categories: selectedOptions.category?.id ? [selectedOptions.category?.id] : null,
    };

    let list = [];

    if (selectedOptions.tab === 'roomService') {
      const { data: roomServiceList } = await getRoomServices({
        fetchPolicy: 'network-only',
        variables,
      });

      list = roomServiceList?.getRoomServices;
    } else {
      const { data: amenityList } = await getConciergeAmenities({
        fetchPolicy: 'network-only',
        variables,
      });

      list = amenityList?.getConciergeAmenities;
    }

    const newEdges = [...tabs[selectedOptions.tab].data.edges].concat(list.edges);

    setPagination({
      page,
    });

    setTabs({
      ...tabs,
      [selectedOptions.tab]: {
        ...tabs[selectedOptions.tab],
        data: {
          edges: newEdges,
          pagination: list.pageInfo,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper style={{ opacity: modal.isOpened ? 0.5 : 1 }}>
        <Header />
        <LayoutFlex>
          <Tab title="상품종류" borderRight>
            <PanelButton title={tabs.roomService.name} isSelected={selectedOptions.tab === 'roomService'} onClick={() => onSelectItem('roomService')} />
            <PanelButton title={tabs.amenity.name} isSelected={selectedOptions.tab === 'amenity'} onClick={() => onSelectItem('amenity')} />
          </Tab>
          <Tab title={(!tagDataNotfound && '카테고리') || ''} borderRight>
            {
              tagDataNotfound && <NotFound text="카테고리가 없습니다." width="356px" height={609} />
            }
            {
              tabs[selectedOptions.tab].categories.length > 0 && <TagItem data={tabs[selectedOptions.tab].categories} onSelectItem={onSelectCategory} isSelectedItem={selectedOptions.category} />
            }
            {/* {
              TABS[selectedOptions.tab].tags.length > 0 && <TagItem title="해시태그" />
            } */}
          </Tab>
          <Tab title="메뉴 선택">
            {
              !tabs[selectedOptions.tab].data?.edges.length || (
                <SimpleBar id="soldoOutScrollableDiv" style={{ width: '440px', height: '100%', overflow: 'auto' }}>
                  <InfiniteScroll
                    dataLength={tabs[selectedOptions.tab].data?.edges.length}
                    next={fetchMore}
                    hasMore
                    loader={<></>}
                    scrollableTarget="soldoOutScrollableDiv"
                  >
                    {
                      // @ts-ignore
                      tabs[selectedOptions.tab].data.edges.map((data: any) => <Menu key={data.node.id} data={data.node} isSelected={!!selectedOptions.items.find((element) => element.id === data.node.id)} onClick={menuEventHandler} onSelect={onSelectListItem} />)
                    }
                  </InfiniteScroll>
                </SimpleBar>
              )
            }
          </Tab>
        </LayoutFlex>
        <Footer soldOutPeriod={soldOutPeriod ? moment(soldOutPeriod).format('YY.MM.DD (dd)') : ''} onClick={soldOutHandler} />
      </Wrapper>
      {
        (modal.isOpened && modal.type === 'calendar') && <DatePickerModal containerStyles={{ top: '45%', left: '41.5%', border: `1px solid ${COLORS.GRAY_03}` }} onSelectDate={onSelectDate} onClose={onCloseModal} />
      }
      {/* {
        (modal.isOpened && modal.type === 'soldOut') && <ServiceDeactivateModal data={selectedOptions.tab} title="품절 해제 시간" description="품절 해제 시간을 선택해주세요." onClose={onCloseModal} containerStyles={{ position: 'absolute', top: '40%', right: '23%' }} onConfirm={onSoldOutItem} />
      } */}
    </Modal>
  );
};

export default SoldOut;
