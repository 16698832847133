import React from 'react';
import { LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import styled from 'styled-components';

const Wrapper = styled(LayoutFlexCenterAligned)`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  width: 100%;
  height: 400px;
  border: 1px solid ${COLORS.GRAY_02};
`;

interface Props {
  text: string
  containerStyles?: Record<string, string | number>
  fontSize?: number
}

const DataNotFound: React.FunctionComponent<Props> = ({ text, containerStyles, fontSize }) => (
  <Wrapper style={containerStyles}>
    <NotoSansTypography fontSize={fontSize || 20} fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_01}>{text}</NotoSansTypography>
  </Wrapper>
);

export default DataNotFound;
