import { useMutation } from '@apollo/client';
import { MontserratTypography, NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import Arrow from 'components/ui/Arrow';
import { Button } from 'components/ui/Button';
import XClose from 'components/ui/Close';
import Input from 'components/ui/Input';
import Modal from 'components/ui/Modal';
import { UpdateConciergeOrderMutation } from 'graphql/order';
import { useGetAccommodationId, useInput } from 'helpers/hooks';
import React from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { ConciergeOrder, ConciergeOrderStatus } from 'types/order';

interface Props {
  isOpened: boolean
  onClose: () => void
  refetch: () => void
  data: ConciergeOrder
}

const Wrapper = styled.div`
  width: 720px;
  height: 612px;
  border-radius: 16px;
  padding: 64px 0px;
`;

const Header = styled.div`
  > div:nth-child(2) {
    margin-top: 21px;
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 63px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const LIST = ['고객요청', '오픈 준비', '영업 마감', '가게 사정', '재료소진'];

const RejectButton: React.FunctionComponent<{ text: string, onClick: () => void }> = ({ text, onClick }) => (
  <div onClick={() => { }} onKeyPress={onClick} style={{ margin: '0px 20px 20px 20px' }}>
    <Button text={text} width="280px" height={88} fontSize={24} fontWeight="bold" onClick={onClick} />
  </div>
);

const InputBox = ({ text, onChange }: { text: string, onChange: (key: string, value: string) => void }) => (
  <Content style={{ justifyContent: 'flex-start', padding: '0px 70px', marginTop: 56 }}>
    <div style={{ width: '100%' }}>
      <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontSize={24} lineHeight={24} letterSpacing={-0.72}>기타</NotoSansTypography>
      <div style={{ marginTop: 16 }}>
        <Input text={text} onChange={(value) => onChange('reason', value)} backgroundColor={COLORS.WHITE} border={`1px solid ${COLORS.GRAY_02}`} height={240} multiline />
      </div>
    </div>
  </Content>
);

const DashboardOrderRejectModal: React.FunctionComponent<Props> = ({ isOpened, data, onClose, refetch }) => {
  const { accommodationId } = useGetAccommodationId();
  const [emitUpdateConciergeOrderMutation] = useMutation(UpdateConciergeOrderMutation);

  const [input, { onChangeInput }] = useInput({
    reason: '',
  });

  const [inputBoxIsVisible, setInputBoxIsVisible] = React.useState<boolean>(false);

  const onConfirm = React.useCallback(async (reason: string) => {
    await toast.promise(
      emitUpdateConciergeOrderMutation({
        variables: {
          accommodationId,
          input: {
            id: data.id,
            status: ConciergeOrderStatus.AdminCancel,
            reason,
            expectedTime: data.expectedTime,
          },
        },
      }),
      {
        loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
        success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>주문거부 처리가 성공적으로 완료되었습니다.</NotoSansTypography>,
        error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>주문거부 처리 중 문제가 발생했습니다.</NotoSansTypography>,
      },
    );

    refetch();
    onClose();
  }, [input]);

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <Header>
          <Grid>
            {inputBoxIsVisible && <Arrow onClick={() => setInputBoxIsVisible(false)} />}
            <NotoSansTypography fontSize={32} lineHeight={32} letterSpacing={-0.96} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>주문거절 사유를 선택해주세요.</NotoSansTypography>
            <XClose containerStyles={{ right: inputBoxIsVisible ? -103 : -120 }} onClick={onClose} />
          </Grid>
          <Grid>
            <MontserratTypography fontSize={20} fontColor={COLORS.GRAY_01} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD}>{data.reservation?.phone}</MontserratTypography>
            <div style={{ paddingTop: 2 }}>
              <NotoSansTypography fontSize={20} lineHeight={32} letterSpacing={-1} fontColor={COLORS.GRAY_01}>으로 문자 발송합니다.</NotoSansTypography>
            </div>
          </Grid>
        </Header>
        {
          inputBoxIsVisible && (
            <>
              <InputBox text={input.reason} onChange={onChangeInput} />
              <Content style={{ marginTop: 8 }}>
                <Button text="전송" width="580px" height={56} backgroundColor={COLORS.BROWN} fontColor={COLORS.WHITE} onClick={() => onConfirm(input.reason)} borderWidth="0" />
              </Content>
            </>
          )
        }
        {
          !inputBoxIsVisible
          && (
            <Content>
              {LIST.map((list) => <RejectButton onClick={() => onConfirm(list)} text={list} key={list} />)}
              <RejectButton text="기타" onClick={() => setInputBoxIsVisible(true)} />
            </Content>
          )
        }
      </Wrapper>
    </Modal>
  );
};

export default DashboardOrderRejectModal;
