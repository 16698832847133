import React from 'react';
import styled from 'styled-components';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';
import { COLORS } from '@common/styles';

interface Props {
  isChecked: boolean
  onClick?: () => void
}

const Wrapper = styled.div`
  margin-right: 8px;
`;

const checkBoxStyles = (theme: any) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: COLORS.BROWN,
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(MaterialCheckbox);

const Checkbox: React.FunctionComponent<Props> = ({ isChecked, onClick }) => (
  <Wrapper onClick={onClick}>
    <CustomCheckbox checked={isChecked} style={{ width: 24, height: 24 }} />
  </Wrapper>
);

export default Checkbox;
