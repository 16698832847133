import React from 'react';
import ServiceList from 'containers/service/list';
import { useQuery } from '@apollo/client';
import { GetConciergeServiceNoticeQuery } from 'graphql/serviceNotice';
import { useGetAccommodationId } from 'helpers/hooks';

const ServiceNotice: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();

  const { data: noticeList, refetch: noticeListRefetch } = useQuery(GetConciergeServiceNoticeQuery, {
    variables: {
      accommodationId,
    },
  });

  return (
    <div>
      <ServiceList categoryLength={0} data={{ edges: noticeList }} refetch={noticeListRefetch} />
    </div>
  );
};

export default ServiceNotice;
