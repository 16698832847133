import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import {
  LayoutFlex, LayoutFlexCenterAligned, MontserratTypography, NotoSansTypography,
} from '@common/components';
import { ConciergeAmenityOrderDetail, RoomServiceOrderDetail } from 'types/order';

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 150px;
  flex-direction: column;
  margin-top: 14px;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
`;

const LayoutSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TreeIcon = styled.div`
  width: 10px;
  height: 10px;
  border-left: 2px solid ${COLORS.PINK_RED};
  border-bottom: 2px solid ${COLORS.PINK_RED};
  margin-right: 6.5px;
`;

const MenuGrid = styled.div`
  width: 50%;
`;

interface Props {
  roomServices: RoomServiceOrderDetail[]
  amenities: ConciergeAmenityOrderDetail[]
}

const Option = ({ data }: { data: any }) => (
  <LayoutFlex style={{ marginBottom: 10 }}>
    <NotoSansTypography fontColor={COLORS.PINK_RED}>{data.origin.name}</NotoSansTypography>
    <LayoutFlex style={{ paddingLeft: 4 }}>
      <MontserratTypography fontColor={COLORS.PINK_RED}>{data.quantity}</MontserratTypography>
      <NotoSansTypography fontColor={COLORS.PINK_RED}>개</NotoSansTypography>
    </LayoutFlex>
  </LayoutFlex>
);

const Menu = ({ data }: { data: any }) => (
  <MenuGrid style={{ marginBottom: !data?.options?.length ? 14 : 0 }}>
    <LayoutSpaceBetween>
      <NotoSansTypography fontSize={18} lineHeight={18} fontWeight={FONTS.NOTO_SANS.BOLD} letterSpacing={-0.54}>{data?.name || data?.title}</NotoSansTypography>
      <LayoutFlexCenterAligned>
        <MontserratTypography fontWeight={FONTS.MONTSERRAT.BOLD} letterSpacing={0.8}>{(data?.quantity || 0).toLocaleString()}</MontserratTypography>
        <div style={{ paddingTop: 2 }}>
          <NotoSansTypography letterSpacing={0.8}>개</NotoSansTypography>
        </div>
      </LayoutFlexCenterAligned>
    </LayoutSpaceBetween>
    {
      !data?.options?.length || (
        <Grid style={{ padding: 6 }}>
          <TreeIcon />
          <div>
            {
              data.options.map((option: any) => <Option data={option} key={option.id} />)
            }
          </div>
        </Grid>
      )
    }
  </MenuGrid>
);

const DashboardMenu: React.FunctionComponent<Props> = ({ roomServices, amenities }) => (
  <MenuWrapper>
    {
      roomServices.map((menu: any) => <Menu data={menu} key={menu.id} />)
    }
    {
      amenities.map((menu: any) => <Menu data={menu} key={menu.id} />)
    }
  </MenuWrapper>
);

export default DashboardMenu;
