import React, { KeyboardEventHandler } from 'react';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { COLORS, FONTS } from '@common/styles';
import styled from 'styled-components';

interface WrapperProps {
  backgroundColor?: string
  width?: string
  height?: number
  border?: string
  padding?: string
  containerStyleProps?: Record<string, number | string>
}

interface Props {
  backgroundColor?: string
  height?: number
  border?: string
  padding?: string
  containerStyleProps?: Record<string, number | string>
  text: string
  onChange: OutlinedInputProps['onChange']
  placeholder?: string
  icon?: React.ReactElement
  iconAlignItem?: 'flex-end' | undefined
  onKeyDown?: KeyboardEventHandler | undefined;
  autoFocus?: boolean
  onBlur?: () => void
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.GRAY_05};
  border: ${({ border }) => border};
  width: ${({ width }) => width};
  height: ${({ height }) => height || 70}px;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => padding || '0px 24px'};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const IconWrapper = styled.div`
  padding-right: 21px;
  width: 20px;
  cursor: pointer;
  padding-top: 4px;
`;

const Icon = ({ element, styles }: { element: React.ReactElement, styles?: Record<string, string | number> }) => (
  <IconWrapper style={styles}>
    {element}
  </IconWrapper>
);

const Span = styled.span`
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
`;

const Input = styled.input`
  min-width: 30px;
  padding: 0;
  margin-right: 8px;
  border: 0px;
  font-family: ${FONTS.NOTO_SANS.NAME};
  font-weight: ${FONTS.NOTO_SANS.BOLD};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.8px;
  color: ${COLORS.GRAY_01};
`;

const AutoMaticallyInput: React.FunctionComponent<Props> = ({
  text, height, onChange, backgroundColor, icon, border, iconAlignItem, padding, placeholder, containerStyleProps, onKeyDown, autoFocus, onBlur,
}) => {
  const [width, setWidth] = React.useState(0);
  const span = React.useRef<any>();

  React.useEffect(() => {
    setWidth(span?.current?.offsetWidth);
  }, [text]);

  return (
    <Wrapper style={containerStyleProps} border={border} backgroundColor={backgroundColor} height={height} padding={padding}>
      <div>
        {((icon && iconAlignItem !== 'flex-end')) && <Icon element={icon} />}
        <Span ref={span}>{text}</Span>
        <Input type="text" style={{ width }} onBlur={onBlur} onChange={onChange} value={text} placeholder={placeholder} onKeyDown={onKeyDown} autoFocus={autoFocus} />
        {(icon && iconAlignItem === 'flex-end') && <Icon element={icon} styles={{ paddingRight: 0 }} />}
      </div>
    </Wrapper>
  );
};

export default AutoMaticallyInput;
