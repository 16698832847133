import React from 'react';
import { COLORS, FONTS } from '@common/styles';
import { authHandler, useUser } from 'helpers/authHandler';
import styled from 'styled-components';
import { Circle } from 'components/ui/Circle';
import { Button } from 'components/ui/Button';
import {
  MontserratTypography, NotoSansTypography, LayoutFlex, LayoutFlexCenterAligned,
} from '@common/components';
import { useGetAccommodationId } from 'helpers/hooks';
import { getMyAccommodationsQuery } from 'graphql/accommodations';
import ClosedTemporarily from 'containers/service/ClosedTemporarily';
import SoldOut from 'containers/SoldOut';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { setConciergeServiceSettingsMutation, getConciergeWorkSettingsQuery } from 'graphql/settings';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { stringToHHMM, timeStringToMinute } from 'helpers';

interface Modal {
  type: 'soldOut' | 'closed' | '',
  isOpened: boolean
}

const Wrapper = styled(LayoutFlexCenterAligned)`
  justify-content: space-between;
  padding: 0px 60px;
  width: 100%;
  height: 80px;
  background-color: ${COLORS.WHITE};
`;

const STATUS_TYPOGRAPHY_STYLES = {
  fontColor: COLORS.GREEN,
  fontWeight: FONTS.NOTO_SANS.MEDIUM,
  lineHeight: 16,
};

const BUTTONS_TYPOGRAPHY_STYLES = {
  lineHeight: 16,
  fontWeight: FONTS.NOTO_SANS.BOLD,
  subFontWeight: FONTS.NOTO_SANS.BOLD,
};

const Header: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();

  const [time, setTime] = React.useState<moment.Moment>(moment());
  const [elapsedTime, setElapsedTime] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);

  const [emitSetConciergeServiceSettingsMutation] = useMutation(setConciergeServiceSettingsMutation);
  const { data: myAccommodations, refetch: myAccommodationsRefetch } = useQuery(getMyAccommodationsQuery);
  const [getConciergeWorkSettings] = useLazyQuery(getConciergeWorkSettingsQuery);

  const getConciergeWorkSetting = async () => {
    const istempWorkingStop = myAccommodations?.getMyAccommodations.length && myAccommodations?.getMyAccommodations[0].conciergeServiceSettings.tempWorkingStop;

    if (moment().isAfter(istempWorkingStop) || moment().isSame(istempWorkingStop)) {
      const response = await getConciergeWorkSettings({
        variables: {
          accommodationId,
          weekDay: [],
        },
      });

      if (response.data?.getConciergeWorkSettings.length) {
        const DAY_WEEKS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

        const reduceSettings = response.data?.getConciergeWorkSettings.reduce((accumulator: any, currentValue: any) => {
          const { weekDay, workType } = currentValue;

          if (!accumulator[weekDay]) {
            accumulator[weekDay] = undefined;
          }

          if (workType === 'WORK') {
            accumulator[weekDay] = currentValue.value.substring(0, 4);
          }

          return accumulator;
        }, {});

        const getDay = moment().day();
        const time = reduceSettings[DAY_WEEKS[getDay]];

        const { start, end } = {
          start: time.substring(0, 2),
          end: time.substring(2, 4),
        };

        const getTodayStartTimeToMinute = timeStringToMinute(`${start}:${end}`);
        const getCurrentTimeToMinute = timeStringToMinute(moment().format('HH:mm'));

        setElapsedTime(`${stringToHHMM(getCurrentTimeToMinute - getTodayStartTimeToMinute).string} `);
      }
    }
  };

  React.useEffect(() => {
    if (myAccommodations) {
      getConciergeWorkSetting();
    }
  }, [myAccommodations]);

  React.useEffect(() => {
    const clockInterval = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => clearInterval(clockInterval);
  }, []);

  const user = useUser();

  const tempWorkingStop = React.useMemo(() => {
    const istempWorkingStop = myAccommodations?.getMyAccommodations.length && myAccommodations?.getMyAccommodations[0].conciergeServiceSettings.tempWorkingStop;

    if (istempWorkingStop) {
      return moment().isAfter(moment(istempWorkingStop));
    }

    return false;
  }, [myAccommodations]);

  const [modal, setModal] = React.useState<Modal>({
    isOpened: false,
    type: '',
  });

  const onCloseModal = React.useCallback(() => {
    setModal({
      type: '',
      isOpened: false,
    });
  }, []);

  const conciergeServiceHandler = (value: string) => {
    confirmAlert({
      title: tempWorkingStop ? '영업을 임시중지하겠습니까?' : '영업임시중지를 해제하시겠습니까?',
      message: `확인 버튼을 누를 시 영업이 ${tempWorkingStop ? '임시 중지 처리됩니다.' : '임시 중지가 해제됩니다.'}.`,
      buttons: [
        {
          label: '확인',
          onClick: async () => {
            setIsLoading(true);
            await toast.promise(
              emitSetConciergeServiceSettingsMutation({
                variables: {
                  accommodationId,
                  inputs: [
                    {
                      key: 'tempWorkingStop',
                      value,
                    },
                  ],
                },
              }),
              {
                loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
                success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>영업임시중지 처리가 성공적으로 완료되었습니다.</NotoSansTypography>,
                error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>영업임시중지 처리 중 문제가 발생했습니다.</NotoSansTypography>,
              },
            );

            setIsLoading(false);
            myAccommodationsRefetch();
            onCloseModal();
          },
        },
        {
          label: '취소',
          onClick: () => { },
        },
      ],
    });
  };

  const ClosedTemporarilyHandler = () => {
    if (tempWorkingStop) {
      setModal({ isOpened: true, type: 'closed' });
    } else {
      conciergeServiceHandler(moment().format('YYYY-MM-DDTHH:mm'));
    }
  };

  return (
    <>
      <Wrapper>
        <LayoutFlex alignItems="center">
          <div style={{ paddingRight: 4 }}>
            <MontserratTypography fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} fontSize={32} lineHeight={32} letterSpacing={1.6}>
              {time.format('HH:mm')}
            </MontserratTypography>
          </div>
          <LayoutFlex alignItems="center" style={{ paddingTop: 8 }}>
            <NotoSansTypography fontSize={18} lineHeight={30} fontWeight={FONTS.NOTO_SANS.MEDIUM}>{time.format('MM월 DD일 (dddd)')}</NotoSansTypography>
            <>
              <Circle color={tempWorkingStop ? COLORS.GREEN : COLORS.ORANGE} width={8} height={8} style={{ marginRight: 8, marginLeft: 24 }} />
              <NotoSansTypography {...STATUS_TYPOGRAPHY_STYLES} fontColor={tempWorkingStop ? COLORS.GREEN : COLORS.ORANGE}>{`${tempWorkingStop ? '정상 영업중  (' : '영업임시중지중'}`}</NotoSansTypography>
              {
                tempWorkingStop && (
                  <>
                    <div style={{ paddingBottom: 3 }}>
                      <MontserratTypography {...STATUS_TYPOGRAPHY_STYLES} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} letterSpacing={0} fontColor={tempWorkingStop ? COLORS.GREEN : COLORS.ORANGE}>{elapsedTime}</MontserratTypography>
                    </div>
                    <NotoSansTypography {...STATUS_TYPOGRAPHY_STYLES} fontColor={tempWorkingStop ? COLORS.GREEN : COLORS.ORANGE}>경과)</NotoSansTypography>
                  </>
                )
              }
            </>
          </LayoutFlex>
        </LayoutFlex>
        <LayoutFlex>
          <Button {...BUTTONS_TYPOGRAPHY_STYLES} text="메뉴 품절처리" onClick={() => setModal({ isOpened: true, type: 'soldOut' })} />
          <Button {...BUTTONS_TYPOGRAPHY_STYLES} text={tempWorkingStop ? '영업임시중지' : '영업임시중지 해제'} styleProps={{ margin: '0px 34px 0px 16px' }} backgroundColor={!tempWorkingStop ? COLORS.ORANGE_0DOT03 : undefined} borderColor={!tempWorkingStop ? COLORS.ORANGE : undefined} fontColor={!tempWorkingStop ? COLORS.ORANGE : undefined} onClick={ClosedTemporarilyHandler} />
          <Button {...BUTTONS_TYPOGRAPHY_STYLES} text="로그아웃" subText={user?.name} onClick={() => authHandler.signOut()} />
        </LayoutFlex>
      </Wrapper>
      {
        modal.type === 'soldOut' && <SoldOut isOpened={modal.isOpened} onClose={onCloseModal} />
      }
      {
        modal.type === 'closed' && <ClosedTemporarily isLoading={isLoading} isOpened={modal.isOpened} onClose={onCloseModal} onSelect={conciergeServiceHandler} />
      }
    </>
  );
};

export default Header;
