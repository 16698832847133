import React from 'react';
import { LayoutFlexJustifyContent } from '@common/components';
// @ts-ignore
import { Calendar } from 'react-date-range';
import ko from 'date-fns/locale/ko';
import styled from 'styled-components';
import { COLORS } from '@common/styles';
import moment from 'moment';
import XClose from './Close';

interface Props {
  containerStyles?: Record<string, string | number>
  onSelectDate?: (ranges: Date) => void;
  onClose?: () => void
}

const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
  }
`;

const DatePickerModal: React.FunctionComponent<Props> = ({ containerStyles, onSelectDate, onClose }) => {
  const [date, setDate] = React.useState<Date>(moment().toDate());

  const onDateSelected = React.useCallback((date: Date) => {
    setDate(date);

    if (typeof onSelectDate === 'function') {
      onSelectDate(date);
    }
  }, []);

  return (
    <Wrapper style={containerStyles}>
      <LayoutFlexJustifyContent justifyContent="flex-end">
        <XClose containerStyles={{ position: 'static', margin: '16px 16px 0px 16px' }} width="14px" height={14} onClick={onClose} />
      </LayoutFlexJustifyContent>
      <Calendar
        minDate={new Date()}
        color={COLORS.BROWN}
        locale={ko}
        showMonthAndYearPickers={false}
        onChange={onDateSelected}
        date={date}
      />
    </Wrapper>
  );
};

export default DatePickerModal;
