import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as ArrowDown } from 'images/arrow_down.svg';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  left: -110px;
  cursor: pointer;
`;

interface Props {
  containerStyles?: Record<string, string | number>
  onClick?: () => void
  type?: 'left' | 'right' | 'bottom'
  iconSize?: 'large' | 'small'
}

const Arrow: React.FunctionComponent<Props> = ({
  containerStyles, onClick, type, iconSize,
}) => (
  <Wrapper style={containerStyles} onClick={onClick}>
    {
      (!type || type === 'left') && <ArrowBackIosIcon fontSize={iconSize || 'large'} />
    }
    {
      (type === 'right') && <ArrowForwardIosIcon fontSize={iconSize || 'large'} />
    }
    {
      (type === 'bottom') && <ArrowDown fontSize={iconSize || 'large'} />
    }
  </Wrapper>
);

export default Arrow;
