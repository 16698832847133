import React from 'react';
import ServiceTag from 'containers/service/Tag';
import ServiceList from 'containers/service/list';
import { getRoomServicesQuery, getRoomServiceCategoryQuery, createRoomServiceCategoryMutation, updateRoomServiceCategoryMutation, deleteRoomServiceCategoryMutation } from 'graphql/roomService';
import { useMutation, useQuery } from '@apollo/client';
import { useGetAccommodationId, usePagination } from 'helpers/hooks';

const RoomService: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();
  const { pagination, setPagination } = usePagination();

  const { data: serviceCategoryData, refetch: serviceCategoryRefetch } = useQuery(getRoomServiceCategoryQuery, {
    variables: {
      accommodationId,
    },
  });

  const {
    data: roomServiceList, error, refetch: roomServiceListRefetch,
  } = useQuery(getRoomServicesQuery, {
    variables: {
      accommodationId,
      connectionArgs: {
        page: pagination.page,
        limit: pagination.limit,
      },
    },
  });

  React.useEffect(() => {
    if (roomServiceList) {
      setPagination({
        ...roomServiceList?.getRoomServices.pageInfo,
      });
    }
  }, [roomServiceList]);

  const [emitCreateRoomServiceCategoryMutation] = useMutation(createRoomServiceCategoryMutation);
  const [emitUpdateRoomServiceCategoryMutation] = useMutation(updateRoomServiceCategoryMutation);
  const [emitDeleteRoomServiceCategoryMutation] = useMutation(deleteRoomServiceCategoryMutation);

  if (error) {
    console.log('error', error);
    // return <>{JSON.stringify(error)}</>;
  }

  return (
    <div>
      <ServiceTag category={serviceCategoryData?.getRoomServiceCategory || []} onCreateCategory={emitCreateRoomServiceCategoryMutation} onUpdateCategory={emitUpdateRoomServiceCategoryMutation} onDeleteCategory={emitDeleteRoomServiceCategoryMutation} refetch={serviceCategoryRefetch} />
      <ServiceList categoryLength={serviceCategoryData?.getRoomServiceCategory.length || 0} data={roomServiceList?.getRoomServices} pagination={pagination} setPagination={setPagination} refetch={roomServiceListRefetch} />
    </div>
  );
};

export default RoomService;
