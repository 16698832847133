import { LayoutFlex, NotoSansTypography } from '@common/components';
import { COLORS } from '@common/styles';
import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui/Button';
import Dropdown from 'components/ui/Dropdown';
import { ReactComponent as Calendar } from 'images/ico_calendar.svg';
import DateRangePickerModal from 'components/ui/DateRangePickerModal';
import { DateRangePickerType, DropdownItem } from 'types';
import { DashboardType, SearchOptions } from 'types/dashboard';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ConciergeOrderStatus } from 'types/order';
import SearchBar from 'components/ui/SearchBar';

type DashboardFilterOptionType<T = string> = {
  id: T
  name: string
  icon?: React.ReactElement
}

interface DashboardFilterOption {
  DATE: DashboardFilterOptionType<'day' | 'week' | 'month' | 'dateRange'>[]
  STATE: DashboardFilterOptionType<'PROCESSING_ALL' | 'FINIH_ALL' | ConciergeOrderStatus.Finish | ConciergeOrderStatus.AdminCancel | ConciergeOrderStatus.UserCancel | ConciergeOrderStatus.Delivery>[]
  TYPE: DashboardFilterOptionType<'TYPE_ALL' | 'ROOM_SERVICE' | 'AMENITY'>[]
}

interface DashboardSelectedFilterOption {
  DATE: DashboardFilterOptionType
  STATE: DashboardFilterOptionType
  TYPE: DashboardFilterOptionType
}

interface ItemProps {
  title: string
  unit?: string
  data: {
    id: string
    name: string
  }[];
  minWidth?: string
  width?: string
  onSelected: (data: { id: string, name: string }) => void
  selectedItem: DashboardFilterOptionType
}

const Wrapper = styled(LayoutFlex)`
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: 98px;
  border-radius: 16px;
  padding: 22px;
  margin-bottom: 24px;
  border: 1px solid ${COLORS.GRAY_02};
`;

const FilterHandlerWrapper = styled.div`
  margin-right: 32px;
  padding-right: 36px;
  border-right: 1px solid ${COLORS.GRAY_02};
`;

const Item: React.FunctionComponent<ItemProps> = ({
  title, unit, data, onSelected, width, selectedItem, minWidth,
}) => (
  <LayoutFlex alignItems="center">
    <div style={{ paddingRight: 16 }}>
      <NotoSansTypography>{title}</NotoSansTypography>
    </div>
    <Dropdown width={width} data={data} onSelected={onSelected} isSelectedItem={selectedItem || undefined} containerStyles={minWidth ? { minWidth } : {}} />
    {
      unit && (
        <div style={{ paddingLeft: 10 }}>
          <NotoSansTypography sTypography fontColor={COLORS.GRAY_01}>{unit}</NotoSansTypography>
        </div>
      )
    }
  </LayoutFlex>
);

interface Props {
  options: SearchOptions
  setOptions: Dispatch<SetStateAction<SearchOptions>>
}

const DashboardFilter: React.FunctionComponent<Props> = ({ options, setOptions }) => {
  const [tabs, setTabs] = React.useState<DashboardFilterOption>({
    DATE: [],
    STATE: [],
    TYPE: [
      {
        id: 'TYPE_ALL',
        name: '전체',
      },
      {
        id: 'ROOM_SERVICE',
        name: '룸서비스',
      },
      {
        id: 'AMENITY',
        name: '어메니티',
      },
    ],
  });

  const [selectedTab, setSelectedTab] = React.useState<DashboardSelectedFilterOption>({
    DATE: tabs.DATE[0],
    STATE: tabs.STATE[0],
    TYPE: tabs.TYPE[0],
  });

  const { search } = useLocation();
  const queries = Object.fromEntries(new URLSearchParams(search));

  const [onShowDateRangePicker, setOnShowDateRangePicker] = React.useState<boolean>(false);

  const initialDropdownOptions = () => {
    const date = moment();
    const dayAgo = moment().subtract(1, 'days');
    const weekAgo = moment().subtract(1, 'weeks');
    const monthAgo = moment().subtract(1, 'months');

    const newDate: DashboardFilterOptionType<'day' | 'week' | 'month' | 'dateRange'>[] = [
      {
        id: 'day',
        name: `하루 전 (${dayAgo.format('YY. MM. DD dd')} ~ ${date.format('YY. MM. DD dd')})`,
      },
      {
        id: 'week',
        name: `일주일 전 (${weekAgo.format('YY. MM. DD dd')} ~ ${date.format('YY. MM. DD dd')})`,
      },
      {
        id: 'month',
        name: `한달 전 (${monthAgo.format('YY. MM. DD dd')} ~ ${date.format('YY. MM. DD dd')})`,
      },
      {
        id: 'dateRange',
        name: '달력 설정',
        icon: <Calendar width={18} height={20} />,
      },
    ];

    let newState: any = [];

    if (queries.type === ConciergeOrderStatus.Processing) {
      newState = [
        {
          id: 'PROCESSING_ALL',
          name: '전체',
        },
        {
          id: ConciergeOrderStatus.Processing,
          name: '처리중',
        },
        {
          id: ConciergeOrderStatus.Delivery,
          name: '배달중',
        },
      ];
    } else if (queries.type === ConciergeOrderStatus.Finish) {
      newState = [
        {
          id: 'FINIH_ALL',
          name: '전체',
        },
        {
          id: ConciergeOrderStatus.Finish,
          name: '배달 완료',
        },
        {
          id: ConciergeOrderStatus.UserCancel,
          name: '고객 취소',
        },
        {
          id: ConciergeOrderStatus.AdminCancel,
          name: '주문 거절',
        },
      ];
    }

    setTabs({
      ...tabs,
      DATE: newDate,
      STATE: newState.length ? newState : tabs.STATE,
    });

    setSelectedTab({
      ...selectedTab,
      ...(!selectedTab.DATE ? { DATE: newDate[0] } : {}),
      STATE: newState.length ? newState[0] : tabs.STATE[0],
    });
  };

  const onSelected = (item: DashboardFilterOptionType) => {
    const copyOptions = { ...options };

    if (item.id === 'day' || item.id === 'week' || item.id === 'month') {
      copyOptions.startDate = moment().subtract(1, `${item.id}s`).format('YYYY-MM-DD');
      copyOptions.endDate = moment().format('YYYY-MM-DD');

      setOptions(copyOptions);

      setSelectedTab({
        ...selectedTab,
        DATE: item,
      });
    } else if (item.id === 'dateRange') {
      setOnShowDateRangePicker(true);
    }

    if (item.id === 'FINIH_ALL' || item.id === 'PROCESSING_ALL' || item.id === ConciergeOrderStatus.Finish || item.id === ConciergeOrderStatus.UserCancel || item.id === ConciergeOrderStatus.AdminCancel || item.id === ConciergeOrderStatus.Delivery || item.id === ConciergeOrderStatus.Processing) {
      if (item.id === 'FINIH_ALL') {
        copyOptions.status = [ConciergeOrderStatus.Finish, ConciergeOrderStatus.UserCancel, ConciergeOrderStatus.AdminCancel];
      } else if (item.id === 'PROCESSING_ALL') {
        copyOptions.status = [ConciergeOrderStatus.Processing, ConciergeOrderStatus.Delivery];
      } else {
        copyOptions.status = [item.id] as [DashboardType];
      }

      setSelectedTab({
        ...selectedTab,
        STATE: item,
      });

      setOptions(copyOptions);
    }
  };

  const onSelectDateRange = React.useCallback((ranges: DateRangePickerType) => {
    const copyOptions = { ...options };

    const startDate = moment(ranges.startDate);
    const endDate = moment(ranges.endDate);

    copyOptions.startDate = startDate.format('YYYY-MM-DD');
    copyOptions.endDate = endDate.format('YYYY-MM-DD');

    const copyDate = [...tabs.DATE];
    copyDate[3].name = `${startDate.format('YY년 MM월 DD일')} - ${endDate.format('YY년 MM월 DD일')}`;

    setTabs({
      ...tabs,
      DATE: copyDate,
    });

    setOptions(copyOptions);
  }, [tabs]);

  const statusMaker = React.useMemo(() => {
    switch (queries.type) {
      case ConciergeOrderStatus.Finish:
        return [ConciergeOrderStatus.Finish, ConciergeOrderStatus.AdminCancel, ConciergeOrderStatus.UserCancel];
      case ConciergeOrderStatus.Processing:
        return [ConciergeOrderStatus.Processing, ConciergeOrderStatus.Delivery];
      case ConciergeOrderStatus.Waiting:
        return [ConciergeOrderStatus.Waiting];
      default:
        return [];
    }
  }, [queries]);

  const resetOptions = React.useCallback(() => {
    setOptions({
      ...options,
      status: statusMaker as [DashboardType],
      startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      phone: null,
    });

    setSelectedTab({
      ...selectedTab,
      DATE: tabs.DATE[0],
      STATE: tabs.STATE[0],
      TYPE: tabs.TYPE[0],
    });
  }, [tabs]);

  const onSearch = (phone: string) => {
    setOptions({
      ...options,
      phone,
    });
  };

  React.useLayoutEffect(() => {
    initialDropdownOptions();
  }, [queries?.type]);

  return (
    <Wrapper>
      <FilterHandlerWrapper>
        <Button text="필터 초기화" width="130px" height={56} backgroundColor={COLORS.WHITE} borderColor={COLORS.BROWN} fontColor={COLORS.BROWN} onClick={resetOptions} />
      </FilterHandlerWrapper>
      <LayoutFlex style={{ width: '100%' }} justifyContent="space-between">
        <div style={{ position: 'relative' }}>
          <Item title="주문날짜" minWidth="300px" width="auto" unit="까지" data={tabs.DATE} selectedItem={selectedTab.DATE} onSelected={onSelected} />
          {
            onShowDateRangePicker && <DateRangePickerModal onSelectDateRange={onSelectDateRange} containerStyles={{ top: 75 }} onClose={() => setOnShowDateRangePicker(false)} />
          }
        </div>
        {
          queries?.type === ConciergeOrderStatus.Waiting || (
            <div style={{ marginLeft: 60, marginRight: 24 }}>
              <Item width="200px" title="상태" data={tabs.STATE} selectedItem={selectedTab.STATE} onSelected={onSelected} />
            </div>
          )
        }
        <LayoutFlex alignItems="center">
          <div style={{ paddingRight: 16 }}>
            <NotoSansTypography>연락처로 검색</NotoSansTypography>
          </div>
          <SearchBar width="280px" height={56} placeholder="연락처 입력" onSearch={onSearch} containerStyles={{ border: '2px solid #31323d' }} inputProps={{ inputMode: 'numeric' }} />
        </LayoutFlex>
      </LayoutFlex>
    </Wrapper>
  );
};

export default DashboardFilter;
