const COLORS = {
  BLACK: "#31323d",
  ACTIVE_BLACK: "#191a24",
  BROWN: "#bea46b",
  BROWN_0DOT01: "rgba(190, 164, 107, 0.1)",
  BROWN_0DOT03: "rgba(190, 164, 107, 0.03)",
  PINK_RED: "#e95354",
  PINK_RED_02: "#fef9f9",
  PINK_RED_0DOT03: "rgba(233, 83, 84, 0.03)",
  ORANGE: "#f85f01",
  ORANGE_0DOT03: "rgba(248, 95, 1, 0.03)",
  BLUE: "#4682dc",
  GREEN: "#3aa968",
  GREEN_0DOT03: "rgba(58,169,104, 0.03)",
  GRAY_01: "#4f4f4f",
  GRAY_02: "#999999",
  GRAY_03: "#D2D2D2",
  GRAY_04: "#EBEBEB",
  GRAY_05: "#F5F5F5",
  GRAY_86: "#868686",
  GRAY_87: "#878787",
  GRAY_B7: "#b7b7b7",
  WHITE: "#ffffff",
};

export default COLORS;
