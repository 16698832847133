import { COLORS } from '@common/styles';
import React from 'react';
import Modal from 'components/ui/Modal';
import styled from 'styled-components';
import { Button } from 'components/ui/Button';
import { ConciergeOrderStatus } from 'types/order';

interface Props {
  isOpened: boolean
  onClose: () => void
  onConfirm: (status: ConciergeOrderStatus) => void
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 174px;
  right: -16px;
  background-color: ${COLORS.WHITE};
  width: 191px;
  height: 228px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;

  > div {
    margin-bottom: 20px;
  }
`;

const BUTTON_STYLES = {
  width: '151px',
  height: 84,
};

const DashboardDeliveryPopup: React.FunctionComponent<Props> = ({ isOpened, onClose, onConfirm }) => (
  <Modal isOpen={isOpened} onClose={onClose} backgroundColor="rgba(0, 0, 0, 0)" minWidth={Boolean(1)}>
    <Wrapper>
      <Button {...BUTTON_STYLES} text="배달중지" backgroundColor={COLORS.WHITE} borderWidth={2} borderColor={COLORS.BLACK} onClick={() => onConfirm(ConciergeOrderStatus.Processing)} />
      <Button {...BUTTON_STYLES} text="배달완료" backgroundColor={COLORS.GREEN} fontColor={COLORS.WHITE} borderWidth={0} onClick={() => onConfirm(ConciergeOrderStatus.Finish)} />
    </Wrapper>
  </Modal>
);

export default DashboardDeliveryPopup;
