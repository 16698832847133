import React from 'react';
import styled from 'styled-components';
import SideBar from 'components/ui/SideBar';
import Header from 'components/ui/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LayoutFlex, NotoSansTypography } from '@common/components';
import { useLazyQuery } from '@apollo/client';
import { getMyAccommodationsQuery } from 'graphql/accommodations';
import { toast } from 'react-hot-toast';
import { FONTS } from '@common/styles';
import { ConciergeOrderStatus } from 'types/order';

interface Props {
}

const Wrapper = styled(LayoutFlex)`
  height: 100%;

  > div:nth-child(2) {
    width: 80%;
    height: 100%;
  }
`;

const Layout: React.FunctionComponent<Props> = () => {
  const [getMyAccommodations] = useLazyQuery(getMyAccommodationsQuery);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queries = Object.fromEntries(new URLSearchParams(search));

  const initialLoad = async () => {
    const getMyAccommodationId = localStorage.getItem('myAccommodations');
    const type = queries?.type || ConciergeOrderStatus.Waiting;

    if (!getMyAccommodationId) {
      const { data } = await getMyAccommodations();

      if (!data) {
        toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{'서버에서 매장정보를 받아올 수 없습니다.\n관리자에게 문의해주세요.'}</NotoSansTypography>);
      }

      if (data?.getMyAccommodations.length) {
        const accommodationId = data.getMyAccommodations[0].id;

        localStorage.setItem('myAccommodations', accommodationId);
        navigate(`dashboard/${accommodationId}?type=${type}`, {
          replace: true,
        });
      }
    } else {
      navigate(`dashboard/${getMyAccommodationId}?type=${type}`, {
        replace: true,
      });
    }
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  return (
    <Wrapper>
      <SideBar />
      <div>
        <Header />
        <Outlet />
      </div>
    </Wrapper>
  );
};

export default Layout;
