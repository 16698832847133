import { gql } from '@apollo/client';

export const GetConciergeServiceNoticeQuery = gql`
  query getConciergeServiceNotice($accommodationId: ID!) {
    getConciergeServiceNotice(accommodationId: $accommodationId) {
      id
      accommodationId
      title
      startDate
      endDate
      type
      priority
      images
    }
  }
`;

export const CreateConciergeServiceNoticeMutation = gql`
  mutation createConciergeServiceNotice($input: [CreateConciergeServiceNotice]!) {
    createConciergeServiceNotice(input: $input) {
      id
      accommodationId
      title
      startDate
      endDate
      type
      priority
      images
    }
  }
`;
