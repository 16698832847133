import { gql } from '@apollo/client';

export const getConciergeOrderStatisticsQuery = gql`
  query getConciergeOrderStatistics($accommodationId: ID!, $options: ConciergeOrderStatisticsOptionInput) {
    getConciergeOrderStatistics(accommodationId: $accommodationId, options: $options) {
      WAITING
      PROCESSING
      DELIVERY
      FINISH
      ADMIN_CANCEL
      USER_CANCEL
      amount
    }
  }
`;
