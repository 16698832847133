import React from 'react';
import Dropdown from 'components/ui/Dropdown';
import { LayoutFlex, NotoSansTypography, SUITTypography } from '@common/components';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import { ReactComponent as ArrowDown } from 'images/ico_fill_arrow.svg';
import { DropdownItem } from 'types';

interface Time {
  id: string
  name: string
}

interface TimeItemProps {
  name: string
  times: Time[]
  onSelect: (item: DropdownItem) => void
  selectedItemIndex?: number
}

const TIMES: { HOUR: Time[], MINUTE: Time[] } = {
  HOUR: new Array(24).fill('').map((_, index) => {
    const hour = (index).toString();
    return {
      id: hour,
      name: hour,
    };
  }),
  MINUTE: new Array(12).fill('').map((_, index) => {
    const minute = (index * 5).toString();
    return {
      id: minute,
      name: minute,
    };
  }),
};

const TimeItemWrapper = styled(LayoutFlex)`
  margin-right: 26px;
  align-items: center;

  > div:nth-child(1) {
    padding-right: 12px;
  }
`;

const TimeItem: React.FunctionComponent<TimeItemProps> = ({ name, times, onSelect, selectedItemIndex }) => (
  <TimeItemWrapper>
    <div>
      <Dropdown
        onSelected={onSelect}
        isSelectedItem={typeof selectedItemIndex === 'number' ? times[selectedItemIndex] : undefined}
        data={times}
        width="245px"
        height={56}
        borderColor={COLORS.GRAY_02}
        borderWidth={1}
        itemContainerStyles={{
          maxHeight: 190,
        }}
        icon={<ArrowDown width={14} height={9.3} />}
      />
    </div>
    <NotoSansTypography fontColor={COLORS.GRAY_02}>{name}</NotoSansTypography>
  </TimeItemWrapper>
);

interface Props {
  onSelect: (type: 'hour' | 'minute', item: DropdownItem) => void
  selectedItemIndex?: number
}

const DeliveryTimePicker: React.FunctionComponent<Props> = ({ onSelect, selectedItemIndex }) => (
  <LayoutFlex alignItems="center">
    <TimeItem times={TIMES.MINUTE} name="분" onSelect={(item) => onSelect('minute', item)} selectedItemIndex={selectedItemIndex} />
    <SUITTypography fontWeight={FONTS.SUIT.SEMI_BOLD} fontColor={COLORS.GRAY_02}>예상 소요</SUITTypography>
  </LayoutFlex>
);

export default DeliveryTimePicker;
