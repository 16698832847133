import { atom } from 'recoil';
import moment from 'moment';

export const dashboardOptionState = atom({
  key: 'dashboardOption',
  default: {
    startDate: moment().subtract(1, 'days').format('YYYYMMDD'),
    endDate: moment().format('YYYYMMDD'),
  },
});
