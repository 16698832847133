import {
  LayoutFlex, LayoutFlexCenterAligned, MontserratTypography, NotoSansTypography,
} from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import { Button } from 'components/ui/Button';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';
import { ConciergeOrder, ConciergeOrderStatus } from 'types/order';
import DashboardDeliveryPopup from './DeliverPopup';
import DashboardMenu from './Menu';
import DashboardOrderRequest from './OrderRequest';

interface Props {
  data: ConciergeOrder
  onReject: (data: ConciergeOrder) => void
  onConfirm: (data: ConciergeOrder, status?: ConciergeOrderStatus) => void
}

interface Popup {
  type: 'delivery' | null,
  isOpened: boolean
}

const Wrapper = styled.div`
  border: 1px solid ${COLORS.GRAY_02};
  border-radius: 16px;
  width: 100%;
  min-height: 212px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 56px;
  padding: 20px 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    height: 100%;
  }

  > div:nth-child(1) {
    width: 15%;
  }

  > div:nth-child(2) {
    width: 44%;
  }

  > div:nth-child(3) {
    width: 40%;
  }
`;

const Grid = styled(LayoutFlexCenterAligned)`
  justify-content: flex-start;
`;

const Line = styled.div`
  ${({ type }: { type: 'horizontal' | 'vertical' }) => css`
    width: ${type === 'horizontal' ? 165 : 1}px;
    height: ${type === 'horizontal' ? 1 : 126}px;
    margin: ${type === 'horizontal' ? '16px 0px' : '0px 24px'};
    background-color: ${COLORS.GRAY_02};
  `};
`;

const LARGE_BUTTON = {
  width: '160px',
  height: 164,
};

const DashboardItem: React.FunctionComponent<Props> = ({ data, onReject, onConfirm }) => {
  const [popup, setPopup] = React.useState<Popup>({
    type: null,
    isOpened: false,
  });

  const onClickedConfirmButton = React.useCallback((clickedData: ConciergeOrder) => {
    if (clickedData.status === ConciergeOrderStatus.Delivery) {
      setPopup((initialPopup: Popup) => ({
        type: 'delivery',
        isOpened: !initialPopup.isOpened,
      }));
    } else if (typeof onConfirm === 'function') {
      onConfirm(clickedData);
    }
  }, [onConfirm]);

  const useButtonConfig = React.useMemo(() => {
    const text = {
      title: '접수하기',
      description: '',
      backgroundColor: COLORS.BROWN,
      descriptionFontSize: 18,
      descriptionOpacity: '100%',
    };

    if (data.status === ConciergeOrderStatus.Processing) {
      text.title = '배달시작';
      text.description = '';
    } else if (data.status === ConciergeOrderStatus.Delivery) {
      text.title = '배달중';
      text.description = `한번 더 클릭하여
배달중지 / 배달완료를
선택하세요.`;
      text.backgroundColor = COLORS.GRAY_01;
      text.descriptionFontSize = 14;
      text.descriptionOpacity = '80%';
    }

    return text;
  }, [data.status]);

  const onCloseModal = React.useCallback(() => {
    setPopup({
      type: null,
      isOpened: false,
    });
  }, []);

  const onConfirmPopupData = React.useCallback((status: ConciergeOrderStatus) => {
    onConfirm(data, status);
    onCloseModal();
  }, []);

  const paymentTypeText = React.useMemo(() => {
    switch (data.paymentType) {
      case 'COAM_PAYMENT':
        return '모바일 컨시어지 선결제 주문';
      case 'DIRECT_CARD':
        return '만나서 카드 결제';
      case 'DIRECT_CASH':
        return '만나서 현금 결제';
      default:
        return '';
    }
  }, []);

  const statusTagConfig = React.useMemo(() => {
    const configs: Record<string, string | undefined> = {
      text: '',
      borderColor: undefined,
      fontColor: COLORS.GRAY_01,
      backgroundColor: undefined,
    };

    if (data.status === ConciergeOrderStatus.Waiting) {
      configs.text = '접수대기';
    } else if (data.status === ConciergeOrderStatus.Processing) {
      configs.text = '처리중';
    } else if (data.status === ConciergeOrderStatus.Delivery) {
      configs.text = '배달중';
    } else if (data.status === ConciergeOrderStatus.Finish) {
      configs.text = '배달완료';
      configs.borderColor = COLORS.GREEN;
      configs.fontColor = COLORS.GREEN;
      configs.backgroundColor = COLORS.GREEN_0DOT03;
    } else if (data.status === ConciergeOrderStatus.AdminCancel || data.status === ConciergeOrderStatus.UserCancel) {
      if (data.status === ConciergeOrderStatus.AdminCancel) {
        configs.text = '주문 거절';
      } else {
        configs.text = '고객 취소';
      }

      configs.borderColor = COLORS.PINK_RED;
      configs.fontColor = COLORS.PINK_RED;
      configs.backgroundColor = COLORS.PINK_RED_0DOT03;
    }

    return configs;
  }, [data.status]);

  return (
    <Wrapper>
      <Container>
        <div>
          <Grid>
            <LayoutFlex alignItems="flex-end" style={{ height: 24, marginRight: 6 }}>
              <MontserratTypography fontSize={16} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} letterSpacing={0.8}>{moment(data.createdAt).format('MM.DD')}</MontserratTypography>
            </LayoutFlex>
            <MontserratTypography fontSize={30} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} lineHeight={30} letterSpacing={1.5}>{moment(data.createdAt).format('HH:mm')}</MontserratTypography>
          </Grid>
          <Line type="horizontal" />
          <div>
            <NotoSansTypography fontColor={COLORS.GRAY_01} fontWeight={FONTS.NOTO_SANS.BOLD}>{data.reservation?.room?.name}</NotoSansTypography>
          </div>
          <div style={{ marginTop: 6 }}>
            <MontserratTypography fontColor={COLORS.GRAY_01}>{data.reservation?.phone}</MontserratTypography>
          </div>
          <LayoutFlex style={{ marginTop: 24 }}>
            <MontserratTypography fontSize={18} lineHeight={18} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} fontColor={COLORS.GRAY_01}>{(data.amount || 0).toLocaleString()}</MontserratTypography>
            <div style={{ paddingTop: 2 }}>
              <NotoSansTypography fontColor={COLORS.GRAY_01}>원</NotoSansTypography>
            </div>
          </LayoutFlex>
          {
            !data.reason || (
              <LayoutFlex style={{ marginTop: 24 }} alignItems="center">
                <div style={{ paddingRight: 4 }}>
                  <NotoSansTypography fontColor={COLORS.GRAY_01}>주문 거뷰 사유: </NotoSansTypography>
                </div>
                <NotoSansTypography fontSize={18} lineHeight={18} fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_01}>{data.reason}</NotoSansTypography>
              </LayoutFlex>
            )
          }
        </div>
        <div>
          <Grid>
            <Button font={FONTS.SUIT.NAME} fontWeight={FONTS.SUIT.BOLD} text={statusTagConfig.text} width="auto" height={32} styleProps={{ marginRight: 16, padding: 16 }} backgroundColor={statusTagConfig.backgroundColor} borderColor={statusTagConfig.borderColor} fontColor={statusTagConfig.fontColor} />
            <Button font={FONTS.SUIT.NAME} fontWeight={FONTS.SUIT.BOLD} text={paymentTypeText} width="auto" height={32} styleProps={{ marginRight: 16, padding: 16 }} fontColor={COLORS.GRAY_01} />
            {/* <Button font={FONTS.SUIT.NAME} fontWeight={FONTS.SUIT.BOLD} text="3개 메뉴 추가 확인 필요" width="174px" height={32} backgroundColor={COLORS.PINK_RED_0DOT03} borderColor={COLORS.PINK_RED} fontColor={COLORS.PINK_RED} /> */}
          </Grid>
          <DashboardMenu roomServices={data.roomServices} amenities={data.amenities} />
        </div>
        <div>
          <Grid style={{ justifyContent: 'flex-end' }}>
            {
              !data?.request || <DashboardOrderRequest text={data?.request || ''} />
            }
            {
              (data.status !== ConciergeOrderStatus.Finish && data.status !== ConciergeOrderStatus.AdminCancel && data.status !== ConciergeOrderStatus.UserCancel) && (
                <>
                  <Button text="주문 거절" {...LARGE_BUTTON} backgroundColor={COLORS.WHITE} styleProps={{ margin: '0px 16px' }} fontSize={20} fontWeight="bold" onClick={() => onReject(data)} />
                  <div style={{ position: 'relative' }}>
                    <Button text={useButtonConfig.title} {...LARGE_BUTTON} backgroundColor={useButtonConfig.backgroundColor} fontColor={COLORS.WHITE} description={useButtonConfig.description} descriptionFontSize={useButtonConfig.descriptionFontSize} descriptionOpacity={useButtonConfig.descriptionOpacity} borderColor="none" fontSize={24} fontWeight="bold" onClick={() => onClickedConfirmButton(data)} />
                    {
                      (data.status === ConciergeOrderStatus.Delivery && (popup.type === 'delivery' && popup.isOpened)) && <DashboardDeliveryPopup isOpened={popup.isOpened} onClose={onCloseModal} onConfirm={onConfirmPopupData} />
                    }
                  </div>
                </>
              )
            }
          </Grid>
        </div>
      </Container>
    </Wrapper>
  );
};

export default DashboardItem;
