import { gql } from '@apollo/client';
import { OffsetPageInfoFragment } from './index';

export const ReservationGroupFragment = gql`
  fragment ReservationGroupFragment on ReservationGroup {
    id
    accommodationId
    groupName
    useStartAt
    useExpireAt
    platform
    status
    reservedBy
    companyAssigneeId
    assignee {
      id
      name
    }
    phone
    email
    memo
  }
`;

export const ReservationFragment = gql`
  fragment ReservationFragment on Reservation {
    id
    type
    intendedType
    isLongTerm
    useStartAt
    useExpireAt
    intendedUseStartAt
    intendedUseExpireAt
    number
    sleeps
    otaNumber
    isPaid
    status
    useVehicle
    vehicleNumber
    platform
    phone
    guestName
    paymentId
    createdAt
    createdBy
    data
    isCancelled
    memo {
      id
      content
    }
    roomId
    room {
      id
      name
    }
    roomType {
      id
      alias
      name
    }
    roomTypeId
    reservationGroup {
      ...ReservationGroupFragment
    }
  }

  ${ReservationGroupFragment}
`;

export const ConciergeOrderFragment = gql`
  fragment ConciergeOrderFragment on ConciergeOrder {
    # roomServices: [RoomServiceOrderDetail]!
    # amenities: [ConciergeAmenityOrderDetail]!
    id
    status
    reason
    request
    amount
    expectedTime
    paymentType
    reservationId
    reservation {
      ...ReservationFragment
    }
    roomServices {
      id
      orderId
      title
      description
      price
      soldOutPeriod
      summary
      leadTime
      thumbnail
      images
      options {
        id
        roomServiceOptionId
        origin {
          id
          name
          price
          status
        }
        price
        status
        quantity
      }
      quantity
    }
    amenities {
      id
      orderId
      name
      price
      deliveryTime
      description
      soldOutPeriod
      quantity
    }
    createdAt
    updatedAt
  }
`;

export const GetOrderRequestTemplatesQuery = gql`
  query getOrderRequestTemplates($accommodationId: ID!) {
    getOrderRequestTemplates(accommodationId: $accommodationId) {
      id
      request
    }
  }
`;

export const getConciergeOrdersQuery = gql`
  query getConciergeOrders(
    $accommodationId: ID!
    $options: ConciergeOrderOptionInput
    $connectionInput: ConnectionOffsetInput!
  ) {
    getConciergeOrders(
      accommodationId: $accommodationId
      options: $options
      connectionInput: $connectionInput
    ) {
      edges {
        cursor
        node {
          ...ConciergeOrderFragment
        }
      }
      pageInfo {
        ...OffsetPageInfoFragment
      }
    }
  }
  ${ConciergeOrderFragment}
  ${OffsetPageInfoFragment}
  ${ReservationFragment}
  ${ReservationGroupFragment}
`;

export const CreateOrderRequestTemplateMutation = gql`
  mutation createOrderRequestTemplate($input: [CreateOrderRequestTemplate]!) {
    createOrderRequestTemplate(input: $input) {
      id
      request
    }
  }
`;

export const UpdateOrderRequestTemplateMutation = gql`
  mutation updateOrderRequestTemplate($input: [UpdateOrderRequestTemplate]!) {
    updateOrderRequestTemplate(input: $input) {
      id
      request
    }
  }
`;

export const DeleteOrderRequestTemplateMutation = gql`
  mutation deleteOrderRequestTemplate($id: [ID]!) {
    deleteOrderRequestTemplate(id: $id)
  }
`;

export const UpdateConciergeOrderMutation = gql`
  mutation updateConciergeOrder(
    $input: UpdateConciergeOrderInput!
    $accommodationId: String
  ) {
    updateConciergeOrder(input: $input, accommodationId: $accommodationId) {
      ...ConciergeOrderFragment
    }
  }
  ${ConciergeOrderFragment}
  ${ReservationFragment}
  ${ReservationGroupFragment}
`;
