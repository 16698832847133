/* eslint-disable react/no-unused-prop-types */
import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import { ConciergeTypography, LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';

interface ButtonWrapperProps {
  width?: string
  height?: number
  backgroundColor?: string | undefined
  deActive?: boolean | undefined
  borderRadius?: number
  borderColor?: string | undefined
  borderWidth?: number | string
}

interface ButtonProps extends ButtonWrapperProps {
  text?: string
  font?: string | undefined
  fontColor?: string | undefined
  fontWeight?: string | number
  fontSize?: number | undefined
  subText?: string | undefined
  subFont?: string | undefined
  subFontColor?: string | undefined
  subFontWeight?: string | number
  styleProps?: Record<string, string | number>
  iconStyleProps?: Record<string, string | number>
  justifyContent?: string | undefined
  icon?: React.ReactElement | undefined
  childButton?: React.ReactElement | undefined
  description?: string | undefined
  descriptionFontColor?: string
  descriptionFontSize?: string | number
  descriptionOpacity?: string
  onClick?: () => void
  onChildButtonClicked?: () => void
}

const ButtonWrapper = styled(LayoutFlexCenterAligned) <ButtonWrapperProps>`
  cursor: pointer;
  width: ${({ width }) => width};
  height: ${({ height }) => height}px;
  background-color: ${({ deActive, backgroundColor }) => (deActive ? COLORS.GRAY_03 : backgroundColor || COLORS.BROWN)};
  border-radius: ${({ borderRadius }) => borderRadius || 6}px;
  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  border-width: ${({ borderWidth }) => borderWidth && `${borderWidth}px`};
`;

const ChildButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const BrownButton: React.FunctionComponent<ButtonProps> = ({
  text, deActive, width, height,
}) => (
  <ButtonWrapper width={width} height={height} deActive={deActive}>
    <p>{text}</p>
  </ButtonWrapper>
);

export const Button: React.FunctionComponent<ButtonProps> = ({
  text, description, width, height, backgroundColor, subText, styleProps, borderWidth, borderColor, fontColor, fontSize, fontWeight, icon, onClick, iconStyleProps, childButton, onChildButtonClicked, borderRadius, font, subFont, subFontColor, subFontWeight, descriptionFontSize, descriptionOpacity, descriptionFontColor,
}) => (
  <ButtonWrapper width={width || '150px'} height={height || 52} backgroundColor={backgroundColor || COLORS.GRAY_05} borderColor={borderColor || COLORS.GRAY_02} style={styleProps} onClick={onClick} borderWidth={borderWidth} borderRadius={borderRadius}>
    {
      icon && <LayoutFlexCenterAligned style={{ marginRight: 8, ...iconStyleProps }}>{icon}</LayoutFlexCenterAligned>
    }
    {
      subText && (
        <>
          <ConciergeTypography color={subFontColor || COLORS.WHITE} textAlign="center" font={subFont} fontSize={fontSize} fontColor={fontColor || COLORS.BLACK} fontWeight={subFontWeight} style={{ opacity: 0.8, margin: '4px 0px' }}>{subText}</ConciergeTypography>
          <div style={{ padding: '0px 8px' }}>{' | '}</div>
        </>
      )
    }
    <LayoutFlexCenterAligned flexDirection="column">
      <ConciergeTypography font={font || FONTS.NOTO_SANS.NAME} fontSize={fontSize} fontColor={fontColor || COLORS.BLACK} fontWeight={fontWeight || FONTS.NOTO_SANS.BOLD}>{text}</ConciergeTypography>
      {
        childButton && (
          <ChildButtonWrapper onClick={onChildButtonClicked}>
            {childButton}
          </ChildButtonWrapper>
        )
      }
      {
        description && <div style={{ marginTop: 12 }}><NotoSansTypography textAlign="center" fontSize={descriptionFontSize || 18} opacity={descriptionOpacity} fontColor={descriptionFontColor || COLORS.WHITE}>{description}</NotoSansTypography></div>
      }
    </LayoutFlexCenterAligned>
  </ButtonWrapper>
);
