import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import XClose from 'components/ui/Close';
import Dropdown from 'components/ui/Dropdown';
import { NotoSansTypography } from '@common/components';
import { useLazyQuery } from '@apollo/client';
import { getConciergeWorkSettingsQuery } from 'graphql/settings';
import moment from 'moment';
import { DropdownItem } from 'types';
import { useGetAccommodationId } from 'helpers/hooks';
import { DEFAULT_WORK_SETTINGS } from 'helpers';

interface Props {
  isOpened: boolean
  onConfirm: (data: { type: 'roomService' | 'amenity', data: DropdownItem }) => void
  onClose: () => void
  containerStyles?: Record<string, number | string>
  title?: string
  description?: string
  data: 'roomService' | 'amenity'
}

const Wrapper = styled.div`
  position: absolute;
  width: 308px;
  height: 580px;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 34px;
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
`;

const ServiceDeactivateModal: React.FunctionComponent<Props> = ({
  isOpened, onConfirm, onClose, containerStyles, title, description, data,
}) => {
  const [dates, setDates] = React.useState<DropdownItem[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<DropdownItem>({ id: '-', name: '-' });
  const { accommodationId } = useGetAccommodationId();
  const [getConciergeWorkSettings] = useLazyQuery(getConciergeWorkSettingsQuery);
  const modalRef = React.useRef<any>();

  const getConciergeWorkSetting = async () => {
    const response = await getConciergeWorkSettings({
      variables: {
        accommodationId,
        weekDay: [],
      },
    });

    const workSettings = response.data?.getConciergeWorkSettings.length ? response.data?.getConciergeWorkSettings : DEFAULT_WORK_SETTINGS;
    const DAY_WEEKS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    const reduceSettings = workSettings.reduce((accumulator: any, currentValue: any) => {
      const { weekDay, workType } = currentValue;

      if (!accumulator[weekDay]) {
        accumulator[weekDay] = undefined;
      }

      if (workType === 'WORK') {
        accumulator[weekDay] = currentValue.value.substring(0, 4);
      }

      return accumulator;
    }, {});

    const dates: DropdownItem[] = new Array(7).fill('').map((_, index) => {
      const date = new Date();
      date.setDate(date.getDate() + (index + 1));

      const getDay = moment(date).day();

      const time = reduceSettings[DAY_WEEKS[getDay]];

      const { start, end } = {
        start: time.substring(0, 2),
        end: time.substring(2, 4),
      };

      const name = `${moment(date).format('YY.MM.DD (ddd)')} 오픈 전까지`;

      return {
        id: `${moment(date).format('YYYY-MM-DD')}T${start}:${end}`,
        name,
      };
    });

    console.log('dates', dates);

    setDates(dates);
  };

  const clickModalOutside = (event: any) => {
    if (isOpened && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  React.useEffect(() => {
    getConciergeWorkSetting();
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', clickModalOutside);

    return () => {
      document.removeEventListener('mousedown', clickModalOutside);
    };
  });

  const onSelectedItem = (item: DropdownItem) => {
    setSelectedDate(item);
    onConfirm({ type: data, data: item });
  };

  return (
    <Wrapper style={containerStyles} ref={modalRef}>
      <Grid style={{ justifyContent: 'space-between' }}>
        <div>
          <NotoSansTypography fontSize={18} lineHeight={18} letterSpacing={-0.9} fontWeight={FONTS.NOTO_SANS.BOLD}>{title || `${data === 'roomService' ? '룸서비스' : '어메니티'} 비활성화`}</NotoSansTypography>
        </div>
        <div>
          <XClose onClick={onClose} width="18" height={18} containerStyles={{ position: 'static' }} />
        </div>
      </Grid>
      <div style={{ marginTop: 12 }}>
        <NotoSansTypography fontColor={COLORS.GRAY_01}>{description || '비활성화 예정 시간 선택'}</NotoSansTypography>
      </div>
      <div style={{ marginTop: 24 }}>
        <Dropdown initialOpened data={[{ id: '2099-01-01T23:59', name: '즉시 비활성화' }].concat(dates)} onClose={onClose} onSelected={onSelectedItem} isSelectedItem={selectedDate} />
      </div>
    </Wrapper>
  );
};

export default ServiceDeactivateModal;
