import { makeVar, useReactiveVar } from '@apollo/client';
import { createApolloFetch } from 'apollo-fetch';
import jwtDecode from 'jwt-decode';
import { API_HOST, STORAGE_KEY } from './constants';
import { AccessTokenPayload } from '../types/user';

const fetch = createApolloFetch({
  uri: `${API_HOST}/graphql`,
});

/**
 * @Process
 * user상태 원시제어 & react 반응 = apollo reactive variable 이용 (Reference: https://www.apollographql.com/docs/react/local-state/reactive-variables/)
 * TokenHandler = Storage Token제어
 * AuthHandler = TokenHandler 상속받으며 reactive variable을 엮음
 */

const AppUserVar = makeVar<AccessTokenPayload | null>(null);

class TokenHandler {
  private storage?: Storage;

  private readonly storageKey: { accessToken: string; refreshToken: string };

  constructor({
    storages = [localStorage, sessionStorage],
    storageKey,
  }: {
    storages?: Storage[];
    storageKey: { accessToken: string; refreshToken: string };
  }) {
    this.storageKey = storageKey;
    storages.forEach((storage) => {
      const savedAccessToken = storage.getItem(storageKey.accessToken);
      if (!savedAccessToken) return;
      this.storage = storage;
    });
  }

  get accessToken() {
    return this.storage?.getItem(this.storageKey.accessToken);
  }

  get refreshToken() {
    return this.storage?.getItem(this.storageKey.refreshToken);
  }

  protected clear() {
    this.storage?.clear();
  }

  protected save({
    storage,
    accessToken,
    refreshToken,
  }: {
    storage?: Storage;
    accessToken: string;
    refreshToken?: string;
  }) {
    if (storage && storage !== this.storage) {
      this.clear();
      this.storage = storage;
    }

    this.storage?.setItem(this.storageKey.accessToken, accessToken);
    if (refreshToken) {
      this.storage?.setItem(this.storageKey.refreshToken, refreshToken);
    }
  }
}

class AuthHandler extends TokenHandler {
  private readonly userVar: typeof AppUserVar;

  constructor(userVar: typeof AppUserVar) {
    super({
      storageKey: {
        accessToken: STORAGE_KEY.ACCESS_TOKEN,
        refreshToken: STORAGE_KEY.REFRESH_TOKEN,
      },
    });
    this.userVar = userVar;

    const { accessToken } = this;
    if (accessToken) this.userVar(jwtDecode(accessToken) as AccessTokenPayload);
  }

  signIn({
    usePermanent,
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken?: string;
    usePermanent: boolean;
  }) {
    this.save({
      storage: (usePermanent && localStorage) || sessionStorage,
      accessToken,
      refreshToken,
    });
    this.userVar(jwtDecode(accessToken) as AccessTokenPayload);
  }

  signOut() {
    this.clear();
    this.userVar(null);
  }

  refreshAccessToken = async () => {
    try {
      const refreshResult = await fetch({
        query: `
          query($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
              accessToken
              refreshToken
            }
          }
        `,
        variables: {
          refreshToken: this.refreshToken,
        },
      });

      const {
        data: {
          refreshToken: { accessToken, refreshToken },
        },
      } = refreshResult;

      this.save({ accessToken, refreshToken });
      this.userVar(jwtDecode(accessToken) as AccessTokenPayload);

      return accessToken;
    } catch (error) {
      console.log(error);

      if (this.accessToken) {
        this.signOut();
      }
    }

    return true;
  };
}

/** @description user가 null이면 로그아웃 상태입니다. */
export const useUser = () => useReactiveVar(AppUserVar);
export const authHandler = new AuthHandler(AppUserVar);
