import { ConciergeAmenity } from './amenity';
import {
  RoomService, RoomServiceOptionDetail, RoomServiceStatus,
} from './roomService';

export enum ConciergeOrderStatus {
  Waiting = 'WAITING',
  Processing = 'PROCESSING',
  Delivery = 'DELIVERY',
  Finish = 'FINISH',
  AdminCancel = 'ADMIN_CANCEL',
  UserCancel = 'USER_CANCEL',
}

export enum ConciergePaymentType {
  CoamPayment = 'COAM_PAYMENT',
  DirectCard = 'DIRECT_CARD',
  DirectCash = 'DIRECT_CASH',
}

export type RoomServiceOrderDetail = {
  id: string;
  origin: RoomService;
  orderId: string;
  title: string;
  description: string;
  price: number;
  status: RoomServiceStatus;
  soldOutPeriod?: string;
  summary?: string;
  leadTime: number;
  thumbnail?: string;
  images?: string;
  options?: RoomServiceOptionDetail;
  quantity: number;
};

export type ConciergeAmenityOrderDetail = {
  id: string;
  conciergeAmenity: ConciergeAmenity;
  orderId: string;
  name: string;
  price: number;
  deliveryTime: string;
  description: string;
  thumbnail: string;
  images?: string[];
  soldOutPeriod?: string;
  quantity: string;
};

export type Reservation = {
  id: string
  type: string
  intendedType: string
  isLongTerm: false
  useStartAt: number
  useExpireAt: number
  intendedUseStartAt: number
  intendedUseExpireAt: number
  number: number
  sleeps: number
  otaNumber: number | null
  isPaid: null,
  status: string
  useVehicle: boolean,
  vehicleNumber: string,
  platform: string,
  phone: string
  guestName: string
  paymentId: string
  createdAt: number
  createdBy: string
  data: string
  isCancelled: false,
  memo: null,
  roomId: string
  room: {
      id: string
      name: string
  },
  roomType: {
      id: string
      alias: string
      name: string
  },
  roomTypeId: string
  reservationGroup: null
}

export interface ConciergeOrder {
  id: string;
  status: ConciergeOrderStatus;
  reason?: string;
  request?: string;
  reservationId: string
  reservation?: Reservation
  expectedTime?: number;
  amount?: number;
  paymentType: ConciergePaymentType;
  roomServices: [RoomServiceOrderDetail];
  amenities: [ConciergeAmenityOrderDetail];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ConciergeOrderEdge {
  cursor: string;
  node: ConciergeOrder;
}
