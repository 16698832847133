import React from 'react';
import { ReactComponent as Close } from 'images/ico_close.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  right: -170px;
  cursor: pointer;
`;

interface Props {
  containerStyles?: Record<string, string | number>
  onClick?: () => void
  width?: string
  height?: number
}

const XClose: React.FunctionComponent<Props> = ({
  containerStyles, onClick, width, height,
}) => (
  <Wrapper style={containerStyles} onClick={onClick}>
    <Close width={width || 23} height={height || 23} />
  </Wrapper>
);

export default XClose;
