import React from 'react';
import { Button } from 'components/ui/Button';
import styled from 'styled-components';
import Modal from 'components/ui/Modal';
import { LayoutFlex, LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';
import { FONTS } from '@common/styles';
import XClose from 'components/ui/Close';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { getConciergeWorkSettingsQuery } from 'graphql/settings';
import { useGetAccommodationId } from 'helpers/hooks';
import { CircularProgress } from '@material-ui/core';
import Loading from 'components/ui/Loading';

type TimeItem = {
  id: string
  text: string
};

const TIMES: TimeItem[] = [
  {
    id: '30_minutes',
    text: '30분 동안',
  },
  {
    id: '1_hours',
    text: '1시간 동안',
  },
  {
    id: '2_hours',
    text: '2시간 동안',
  },
  {
    id: '3_hours',
    text: '3시간 동안',
  },
  {
    id: '4_hours',
    text: '4시간 동안',
  },
  {
    id: '5_hours',
    text: '5시간 동안',
  },
  {
    id: '1_days',
    text: '다음날 오픈 전까지',
  },
];

interface TimeButtonProps {
  data: TimeItem
  onSelect: (data: TimeItem) => void
}

const TimeWrapper = styled.div`
  padding: 8px 12px;
`;

const Wrapper = styled.div`
  width: 720px;
  height: 528px;
  padding: 40px 48px;
`;

const TimeList = styled(LayoutFlex)`
  flex-wrap: wrap;
  justify-content: center;

  > div:last-child {
    > div {
      width: 530px;
    }
  }
`;

const TimeButton: React.FunctionComponent<TimeButtonProps> = ({
  data, onSelect,
}) => (
  <TimeWrapper>
    <Button onClick={() => onSelect(data)} width="160px" height={100} text={data.text} fontWeight="bold" fontSize={20} />
  </TimeWrapper>
);

interface Props {
  isOpened: boolean
  onClose: () => void
  onSelect: (id: string) => void
  isLoading: boolean
}

const ClosedTemporarily: React.FunctionComponent<Props> = ({ isOpened, onClose, onSelect, isLoading }) => {
  const { accommodationId } = useGetAccommodationId();
  const [getConciergeWorkSettings] = useLazyQuery(getConciergeWorkSettingsQuery);

  const onSelectTime = async (time: TimeItem) => {
    const [number, type] = time.id.split('_');

    if (type === 'days') {
      const response = await getConciergeWorkSettings({
        variables: {
          accommodationId,
          weekDay: [],
        },
      });

      if (response.data?.getConciergeWorkSettings.length) {
        const DAY_WEEKS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const day = moment().add(1, 'days').day();

        const reduceSettings = response.data?.getConciergeWorkSettings.reduce((accumulator: any, currentValue: any) => {
          const { weekDay, workType } = currentValue;

          if (!accumulator[weekDay]) {
            accumulator[weekDay] = undefined;
          }

          if (workType === 'WORK') {
            accumulator[weekDay] = currentValue.value.substring(0, 4);
          }

          return accumulator;
        }, {});

        const tomorrowDay = reduceSettings[DAY_WEEKS[day]];

        const { start, end } = {
          start: tomorrowDay.substring(0, 2),
          end: tomorrowDay.substring(2, 4),
        };

        return onSelect(`${moment().add(1, 'day').format('YYYY-MM-DD')}T${start}:${end}`);
      }
    }

    // @ts-ignore
    return onSelect(moment().add(number, type).format('YYYY-MM-DDTHH:mm'));
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <LayoutFlexCenterAligned style={{ marginBottom: 40 }}>
          <NotoSansTypography fontSize={32} fontWeight={FONTS.NOTO_SANS.BOLD} lineHeight={32} letterSpacing={-0.96}>영업임시중지 시간 설정</NotoSansTypography>
          <XClose containerStyles={{ right: -115 }} onClick={onClose} />
        </LayoutFlexCenterAligned>
        {
          !isLoading ? (
            <TimeList>
              {
                TIMES.map((time) => <TimeButton key={time.id} data={time} onSelect={onSelectTime} />)
              }
            </TimeList>
          ) : <Loading containerStyles={{ height: '80%' }} />
        }
      </Wrapper>
    </Modal>
  );
};

export default ClosedTemporarily;
