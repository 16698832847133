import React, { useEffect } from 'react';
import 'moment/locale/ko';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Layout from 'components/ui/Layout';
import ServiceLayout from 'components/ui/ServiceLayout';
import DashboardContainer from 'containers/dashboard/containers';
import AuthContainer from 'containers/auth/containers';
import RoomService from 'containers/service/roomService';
import Amenity from 'containers/service/amenity';
import ServiceNotice from 'containers/service/serviceNotice';
import BusinessHours from 'containers/service/businessHours';
import OrderRequest from 'containers/service/orderRequest';
import { authHandler, useUser } from 'helpers/authHandler';
import { Toaster } from 'react-hot-toast';
import { parse } from 'querystring';

const App: React.FunctionComponent<any> = () => {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queries = parse(location.search.split('?')[1]);
    if (queries.tkn && queries.rkn) {
      localStorage.removeItem('myAccommodations');
      authHandler.signIn({
        accessToken: queries.tkn as string,
        refreshToken: queries.rkn as string,
        usePermanent: true,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!user) {
      const queries = parse(location.search);
      if (queries.tkn && queries.rkn) {
        localStorage.removeItem('myAccommodations');
        authHandler.signIn({
          accessToken: queries.tkn as string,
          refreshToken: queries.rkn as string,
          usePermanent: true,
        });
        navigate('/', { replace: true });
      }
      navigate('/auth', { replace: true });
    }
  }, [user]);

  return (
    <>
      <Routes>
        <Route path="auth/*" element={<AuthContainer />} />
        <Route path="/" element={<Layout />}>
          <Route
            path="dashboard/:accommodationId"
            element={<DashboardContainer />}
          />
        </Route>
        <Route path="/service/:accommodationId" element={<ServiceLayout />}>
          <Route path="roomService/*" element={<RoomService />} />
          <Route path="amenity/*" element={<Amenity />} />
          <Route path="serviceNotice/*" element={<ServiceNotice />} />
          <Route path="businessHours/*" element={<BusinessHours />} />
          <Route path="orderRequest/*" element={<OrderRequest />} />
        </Route>
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          duration: 8000,
          style: {
            padding: '16px',
          },
        }}
      />
    </>
  );
};

export default App;
