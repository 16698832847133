import React from 'react';
import TagDropdown from 'components/ui/TagDropdown';
import { DropdownItem, TabTypes } from 'types';
import { COLORS, FONTS } from '@common/styles';
import { LayoutFlex, NotoSansTypography } from '@common/components';
import { useLazyQuery } from '@apollo/client';
import { getRoomServiceCategoryQuery } from 'graphql/roomService';
import { getConciergeAmenityCategoriesQuery } from 'graphql/amenity';
import { useGetAccommodationId } from 'helpers/hooks';

interface TagDropdownViewProps {
  type: TabTypes
  contentType: 'roomService' | 'amenity'
  selectedItems?: DropdownItem[]
  title: string
  onSelectTag: (type: TabTypes, item: DropdownItem) => void
  onDeleteTag: (type: TabTypes, index: number) => void
}

const TagDropdownView: React.FunctionComponent<TagDropdownViewProps> = ({
  type, title, onSelectTag, onDeleteTag, selectedItems, contentType,
}) => {
  const { accommodationId } = useGetAccommodationId();
  const [getServiceCategory] = useLazyQuery(getRoomServiceCategoryQuery, {
    variables: {
      accommodationId,
    },
  });

  const [getAmenityServiceCategory] = useLazyQuery(getConciergeAmenityCategoriesQuery, {
    variables: {
      accommodationId,
    },
  });

  const [data, setData] = React.useState<DropdownItem[]>([]);

  const initialLoad = async () => {
    if (type === 'category') {
      if (contentType === 'roomService') {
        const { data: getServiceCategoryData } = await getServiceCategory();

        setData(getServiceCategoryData?.getRoomServiceCategory);
      } else if (contentType === 'amenity') {
        const { data: getAmenityServiceCategoryData } = await getAmenityServiceCategory();

        setData(getAmenityServiceCategoryData?.getConciergeAmenityCategories);
      }
    }
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  return (
    <div>
      <LayoutFlex alignItems="center">
        <NotoSansTypography letterSpacing={-0.8} fontColor={COLORS.GRAY_01} fontWeight={FONTS.NOTO_SANS.BOLD}>{title}</NotoSansTypography>
        <div style={{ paddingLeft: 14 }}>
          <NotoSansTypography fontColor={COLORS.PINK_RED} fontSize={14} fontWeight={FONTS.NOTO_SANS.BLACK} lineHeight={20} letterSpacing={-1}>* 카테고리 미지정 시 상품이 노출되지 않습니다.</NotoSansTypography>
        </div>
      </LayoutFlex>
      <div style={{ margin: '10px 0px 16px 0px' }}>
        <TagDropdown selectedItems={selectedItems} width="360px" data={data} onSelected={(item) => onSelectTag(type, item)} onDeleted={(index: number) => onDeleteTag(type, index)} />
      </div>
    </div>
  );
};

export default TagDropdownView;
