import { COLORS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  backgroundColor?: string;
  isOpen?: boolean;
  containerStyles?: Record<string, any>;
  onClose?: () => void;
  maxHeight?: number;
  children: React.ReactNode;
  minWidth?: boolean
}

const Wrapper = styled.div<{ minWidth?: boolean; backgroundColor?: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-width: ${({ minWidth }) => !minWidth && '1920px'};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'rgba(0, 0, 0, 0.5)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

const Container = styled.div`
  background: ${COLORS.WHITE};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 99999;
  margin: 0 auto;
  border-radius: 16px;
  max-height: ${({ maxHeight }: { maxHeight?: number }) =>
    `${maxHeight}px` || 'auto'};
`;

const Modal: React.FunctionComponent<ModalProps> = ({
  backgroundColor,
  isOpen,
  children,
  containerStyles,
  onClose,
  maxHeight,
  minWidth,
}) => {
  React.useEffect(() => {
    // if (isOpen) {
    //   document.getElementById('root')?.addEventListener('click', onCloseModal, false);
    // }
  }, [isOpen]);

  const onCloseModal = React.useCallback(() => {
    document.getElementById('root')?.removeEventListener('click', onCloseModal);
    if (typeof onClose === 'function') {
      onClose();
    }
  }, []);

  return (
    <>
      {isOpen && (
        <Wrapper
          minWidth={minWidth}
          backgroundColor={backgroundColor}
          onClick={() => onClose && onClose()}
          id="modal_wrapper"
        >
          <Container
            style={containerStyles}
            onClick={(event) => {
              event.stopPropagation();
            }}
            maxHeight={maxHeight}
          >
            {children}
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default Modal;
