import { COLORS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Picture } from 'images/ico_picture.svg';
import { NotoSansTypography } from '@common/components';

interface Props {
  type: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  width?: number
  height?: number
  preview?: File | null
  isMultiple?: boolean
}

interface WrapperProps {
  width?: number
  height?: number
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${COLORS.GRAY_05};
  width: ${({ width }) => width || 388}px;
  height: ${({ height }) => height || 180}px;
  border-radius: 6px;
  border: 1px solid ${COLORS.GRAY_02};
  border-style: dashed;

  > label {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Preview = styled.img`
  object-fit: cover;
`;

const InputFile = styled.input`
  display: none;
`;

const ImageUploader: React.FunctionComponent<Props> = ({
  type, width, height, onChange, preview, isMultiple,
}) => (
  <Wrapper width={width} height={height}>
    <label htmlFor={`${type}-upload-file`}>
      {
        preview && <Preview src={typeof preview === 'string' ? preview : URL.createObjectURL(preview)} width={width} height={height} />
      }
      {
        !preview && (
          <>
            <Picture />
            <div style={{ marginTop: 12 }}>
              <NotoSansTypography>클릭하여 이미지 업로드</NotoSansTypography>
            </div>
          </>
        )
      }
    </label>
    <InputFile type="file" accept=".gif,.jpg,.jpeg,.png" id={`${type}-upload-file`} onChange={onChange} multiple={isMultiple} />
  </Wrapper>
);

export default ImageUploader;
