import { useMutation } from '@apollo/client';
import {
  LayoutFlexCenterAligned, MontserratTypography, NotoSansTypography, SUITTypography,
} from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import { Button } from 'components/ui/Button';
import XClose from 'components/ui/Close';
import Modal from 'components/ui/Modal';
import { UpdateConciergeOrderMutation } from 'graphql/order';
import { useGetAccommodationId } from 'helpers/hooks';
import moment from 'moment';
import React from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { ConciergeOrder, ConciergeOrderStatus } from 'types/order';

interface Props {
  isOpened: boolean
  data: ConciergeOrder
  refetch: () => void
  onClose: () => void
  onReject: (data: ConciergeOrder) => void
}

const TIMES = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60];

const Wrapper = styled.div`
  width: 882px;
  height: 647px;
  border-radius: 16px;
  background-color: ${COLORS.WHITE};
  padding: 50px 28px;

  > div {
    display: flex;

    > div:nth-child(1) {
      width: 30%;
      padding-right: 50px;
      border-right: 2px solid ${COLORS.GRAY_02};
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 70%;
    }
  }
`;

const Grid = styled.div`
  display: flex;
`;

const Line = styled.div`
  width: 240px;
  height: 1px;
  margin: 32px 0px;
  background-color: ${COLORS.GRAY_02};
`;

const MenuGrid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 20px;
`;

const TimeWrapper = styled.div`
  margin: 8px 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 32px;
`;

interface TimeButtonProps {
  text: string | number
  index?: number
  onClick: () => void
  isSelected?: boolean
}

const TimeButton: React.FunctionComponent<TimeButtonProps> = ({
  text, index, isSelected, onClick,
}) => (
  <TimeWrapper>
    <Button onClick={onClick} width="140px" height={97} text={`${text}${typeof text === 'number' ? '분' : ''}`} fontWeight="bold" fontSize={20} backgroundColor={(isSelected && COLORS.BROWN) || undefined} fontColor={(isSelected && COLORS.WHITE) || undefined} />
  </TimeWrapper>
);

const Menu = ({ data }: { data: any }) => (
  <MenuGrid>
    <div>
      <NotoSansTypography>{data?.name || data.title}</NotoSansTypography>
    </div>
    <Grid>
      <MontserratTypography fontWeight={FONTS.MONTSERRAT.BOLD} letterSpacing={0.8}>{data.quantity}</MontserratTypography>
      <NotoSansTypography letterSpacing={0.8}>개</NotoSansTypography>
    </Grid>
  </MenuGrid>
);

const DashboardOrderModal: React.FunctionComponent<Props> = ({
  isOpened, data, onClose, onReject, refetch,
}) => {
  const { accommodationId } = useGetAccommodationId();
  const [emitUpdateConciergeOrderMutation] = useMutation(UpdateConciergeOrderMutation);

  const onProcessing = async () => {
    await toast.promise(
      emitUpdateConciergeOrderMutation({
        variables: {
          accommodationId,
          input: {
            id: data.id,
            status: ConciergeOrderStatus.Processing,
            reason: '',
            expectedTime: data.expectedTime,
          },
        },
      }),
      {
        loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
        success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>주문접수가 성공적으로 완료되었습니다.</NotoSansTypography>,
        error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>주문접수 처리 중 문제가 발생했습니다.</NotoSansTypography>,
      },
    );

    refetch();
    onClose();
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <div>
          <div>
            <Button text="모바일 컨시어지 선결제 주문" width="196px" height={32} styleProps={{ marginRight: 16 }} fontColor={COLORS.GRAY_01} />
            <Grid style={{ margin: '16px 0px' }}>
              <MontserratTypography fontSize={32} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} lineHeight={32} letterSpacing={1.6}>{moment(data.createdAt).format('HH:mm')}</MontserratTypography>
            </Grid>
            <Grid>
              <div style={{ marginRight: 12 }}>
                <MontserratTypography fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} fontCOlor={COLORS.BLACK} opacity="80%">{data.reservation?.room?.name}</MontserratTypography>
              </div>
              <div>
                <MontserratTypography fontSize={14} lineHeight={14} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} opacity="80%">{data.reservation?.phone}</MontserratTypography>
              </div>
            </Grid>
            <Line />
            <div style={{ height: 190, maxHeight: 190 }}>
              <Grid style={{ marginBottom: 30 }}>
                <div style={{ marginRight: 12 }}>
                  <SUITTypography fontSize={18} lineHeight={18} fontWeight={FONTS.SUIT.EXTRA_BOLD}>{`[메뉴 ${(data.roomServices.length || 0) + (data.amenities.length || 0)}개]`}</SUITTypography>
                </div>
                <div>
                  <MontserratTypography opacity="80%" lineHeight={18} fontWeight={FONTS.MONTSERRAT.SEMI_BOLD}>{(data.amount || 0).toLocaleString()}</MontserratTypography>
                </div>
                <div>
                  <NotoSansTypography opacity="80%" lineHeight={16}>원</NotoSansTypography>
                </div>
              </Grid>
              {
                data.roomServices.map((menu) => <Menu key={menu.id} data={menu} />)
              }
              {
                data.amenities.map((menu) => <Menu key={menu.id} data={menu} />)
              }
            </div>
            <Line />
            <div>
              <div>
                <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항</NotoSansTypography>
              </div>
              <div style={{ marginTop: 16 }}>
                <NotoSansTypography>{data.request}</NotoSansTypography>
              </div>
            </div>
          </div>
          <div>
            <LayoutFlexCenterAligned flexDirection="column">
              <Grid style={{ alignItems: 'center', marginBottom: 8 }}>
                <div style={{ paddingLeft: 36 }}>
                  <NotoSansTypography fontSize={32} letterSpacing={-0.96} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>접수하기</NotoSansTypography>
                </div>
                <XClose onClick={onClose} />
              </Grid>
              <NotoSansTypography fontSize={18} lineHeight={18} letterSpacing={-0.9} fontColor={COLORS.GRAY_01}>예상 조리 시간 선택</NotoSansTypography>
            </LayoutFlexCenterAligned>
            <ContentWrapper>
              {
                TIMES.map((time, index) => <TimeButton text={time} index={index} key={time} onClick={onProcessing} />)
              }
              <TimeButton text="주문취소" onClick={() => onReject(data)} />
            </ContentWrapper>
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default DashboardOrderModal;
