import { COLORS } from '@common/styles';
import styled from 'styled-components';

interface CircleProps {
  width?: number
  height?: number
  color?: string
  isSelected?: boolean
}

const LayoutCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled(LayoutCenter) <CircleProps>`
  width: ${({ width }) => width || 32}px;
  height: ${({ height }) => height || 32}px;
  border-radius: 50%;
  background-color: ${({ color, isSelected }) => (isSelected ? COLORS.BROWN : color || COLORS.WHITE)};
`;
