import { LayoutFlexCenterAligned, NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';

interface WrapperProps {
  width?: string
  height?: number
}

interface Props extends WrapperProps {
  text: string
}

const Wrapper = styled(LayoutFlexCenterAligned) <WrapperProps>`
  background-color: ${COLORS.GRAY_05};
  width: ${({ width }) => width};
  height: ${({ height }) => height || 300}px;
  border-radius: 10px;
  border: 1px solid ${COLORS.GRAY_02};
  flex-direction: column;
`;

const NotFound: React.FunctionComponent<Props> = ({ text, width, height }) => (
  <Wrapper width={width} height={height}>
    <DeleteButton />
    <div style={{ marginTop: 16 }}>
      <NotoSansTypography fontColor={COLORS.GRAY_02} fontWeight={FONTS.NOTO_SANS.BOLD} fontSize={18}>{text}</NotoSansTypography>
    </div>
  </Wrapper>
);

export default NotFound;
