import React from 'react';
import styled from 'styled-components';
import SideBar from 'components/ui/SideBar';
import Header from 'components/ui/Header';
import ServiceTab from 'containers/service/tab';
import ServiceTitle from 'containers/service/Title';
import { Outlet } from 'react-router-dom';
import { LayoutFlex } from '@common/components';

interface Props {
}

const Wrapper = styled(LayoutFlex)`
  height: 100%;

  > div:nth-child(2) {
    width: 80%;
    height: 100%;
  }
`;

const Grid = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const Content = styled.div`
  padding: 26px 55px;
  height: calc(100% - 80px);
`;

const ServiceLayout: React.FunctionComponent<Props> = () => (
  <Wrapper>
    <SideBar />
    <Grid>
      <Header />
      <Content>
        <ServiceTitle />
        <ServiceTab />
        <Outlet />
      </Content>
    </Grid>
  </Wrapper>
);

export default ServiceLayout;
