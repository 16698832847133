/* eslint-disable no-unused-vars */
import { useQuery } from '@apollo/client';
import { LayoutFlexCenterAligned, MontserratTypography, NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import VenditLogo from 'components/VenditLogo';
import { Button } from 'components/ui/Button';
import { Circle } from 'components/ui/Circle';
import { getConciergeOrderStatisticsQuery } from 'graphql/statistics';
import { useGetAccommodationId } from 'helpers/hooks';
import { ReactComponent as Finish } from 'images/menu_done.svg';
import { ReactComponent as Waiting } from 'images/menu_waiting.svg';
import { ReactComponent as Processing } from 'images/menu_working.svg';
import { ReactComponent as Option } from 'images/option.svg';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { dashboardOptionState } from 'recoil/dashboardOptionState';
import styled from 'styled-components';
import { POLL_INTERVAL } from '../../helpers/constants';

type Types = 'WAITING' | 'PROCESSING' | 'FINISH';

interface SideBarItemProps {
  text: string
  count: number
  type: Types
}

interface ItemProps {
  isSelected: boolean
  text: string
  type: Types
  count: number
  icon: React.ReactElement
  onSelect: (type: Types) => void
}

const Wrapper = styled.div`
  padding: 40px 20px;
  width: 20%;
  height: 100%;
  background-color: ${COLORS.BLACK};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100px;
  background-color: ${({ isSelected }: { isSelected: boolean }) => isSelected && COLORS.ACTIVE_BLACK};
  border-radius: 8px;
  padding: 40px 30px;

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

const Item: React.FunctionComponent<ItemProps> = ({
  text, count, icon, type, isSelected, onSelect,
}) => (
  <ItemWrapper isSelected={isSelected} onClick={() => onSelect(type)}>
    <div>
      <LayoutFlexCenterAligned style={{ justifyContent: 'flex-start' }}>
        {icon}
        <div style={{ marginRight: 14 }} />
        <NotoSansTypography fontSize={22} fontColor={COLORS.WHITE} fontWeight={FONTS.NOTO_SANS.BOLD} letterSpacing={-0.66} lineHeight={22}>{text}</NotoSansTypography>
      </LayoutFlexCenterAligned>
      <Circle isSelected={isSelected}>
        <MontserratTypography fontColor={isSelected ? COLORS.WHITE : COLORS.BLACK} fontSize={20} lineHeight={20}>{count}</MontserratTypography>
      </Circle>
    </div>
  </ItemWrapper>
);

const SideBar: React.FunctionComponent = () => {
  const dashboardOptions = useRecoilValue(dashboardOptionState);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { accommodationId } = useGetAccommodationId();
  const queries = Object.fromEntries(new URLSearchParams(search));
  const { data: conciergeOrderStatisticsData } = useQuery(getConciergeOrderStatisticsQuery, {
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL.DASHBOARD,
    variables: {
      accommodationId,
      options: {
        startDate: dashboardOptions.startDate,
        endDate: dashboardOptions.endDate,
      },
    },
  });

  const [menus, setMenus] = React.useState<SideBarItemProps[]>([
    {
      text: '접수대기',
      count: 0,
      type: 'WAITING',
    },
    {
      text: '처리중',
      count: 0,
      type: 'PROCESSING',
    },
    {
      text: '완료',
      count: 0,
      type: 'FINISH',
    },
  ]);

  React.useEffect(() => {
    if (conciergeOrderStatisticsData?.getConciergeOrderStatistics) {
      const { WAITING, PROCESSING, DELIVERY, FINISH, USER_CANCEL, ADMIN_CANCEL } = conciergeOrderStatisticsData.getConciergeOrderStatistics;

      setMenus([
        {
          text: '접수대기',
          count: WAITING,
          type: 'WAITING',
        },
        {
          text: '처리중',
          count: PROCESSING + DELIVERY,
          type: 'PROCESSING',
        },
        {
          text: '완료',
          count: FINISH + USER_CANCEL + ADMIN_CANCEL,
          type: 'FINISH',
        },
      ]);
    }
  }, [conciergeOrderStatisticsData?.getConciergeOrderStatistics]);

  const [isSelectedMenuMap, setIsSelectedMenuMap] = React.useState<string>(queries.type);

  React.useEffect(() => {
    if (queries.type) {
      setIsSelectedMenuMap(queries.type);
    }
  }, [search]);

  const onSelect = (type: Types) => {
    setIsSelectedMenuMap(type);
    navigate(`/dashboard/${accommodationId}?type=${type}`, { replace: true });
  };

  const iconSelector = (type: Types) => {
    switch (type) {
      case 'WAITING':
        return <Waiting width={19} height={13} />;
      case 'PROCESSING':
        return <Processing width={20} height={17} />;
      case 'FINISH':
        return <Finish width={20} height={15} />;
      default:
        return <></>;
    }
  };

  const isServicePage = React.useMemo(() => pathname.split('/')[1] === 'service', [pathname]);

  return (
    <Wrapper>
      <div>
        <LayoutFlexCenterAligned style={{ marginTop: 8, marginBottom: 58 }}>
          <VenditLogo />
        </LayoutFlexCenterAligned>
        {
          menus.map((menu) => <Item key={menu.text} {...menu} icon={iconSelector(menu.type)} isSelected={isSelectedMenuMap === menu.type} onSelect={onSelect} />)
        }
      </div>
      <div>
        <Button
          text="매장 설정"
          width="100%"
          height={80}
          icon={<Option stroke={isServicePage ? COLORS.WHITE : undefined} />}
          backgroundColor={isServicePage ? COLORS.ACTIVE_BLACK : undefined}
          borderColor={isServicePage ? COLORS.ACTIVE_BLACK : undefined}
          font={FONTS.NOTO_SANS.BOLD}
          fontColor={isServicePage ? COLORS.WHITE : COLORS.BLACK}
          onClick={() => {
            navigate(`/service/${accommodationId}/roomService`);
          }}
        />
      </div>
    </Wrapper>
  );
};

export default SideBar;
