import { NotoSansTypography } from '@common/components';
import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 270px;
  height: 164px;
  background-color: ${COLORS.GRAY_05};
  border-radius: 8px;
  padding: 10px 16px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.GRAY_02};
  margin: 12px 0px;
`;

const DashboardOrderRequest = ({ text }: { text: string }) => (
  <Wrapper>
    <div style={{ paddingTop: 4 }}>
      <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>요청사항</NotoSansTypography>
    </div>
    <Line />
    <div>
      <NotoSansTypography letterSpacing={-0.8}>{text}</NotoSansTypography>
    </div>
  </Wrapper>
);

export default DashboardOrderRequest;
