import { NotoSansTypography } from '@common/components';
import { COLORS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import { DateType, BusinessHourOption } from 'types';
import DateSelector from './DateSelector';

interface Props {
  day: string
  data: BusinessHourOption
  onSelect: (keyName: string, type: DateType, time: number) => void
}

const Grid = styled.div`
  display: flex;
  margin-bottom: 28px;
`;

const Circle = styled.div`
  background-color: ${COLORS.BLACK};
  min-width: 42px;
  max-width: 42px;
  min-height: 42px;
  max-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ServiceBusinessHoursDate: React.FunctionComponent<Props> = ({ data, day, onSelect }) => (
  <Grid>
    <Circle>
      <NotoSansTypography fontSize={18} letterSpacing={-0.9} fontColor={COLORS.WHITE}>{day}</NotoSansTypography>
    </Circle>
    <DateSelector keyName="work" title="영업 시간" onSelectTime={data.work} onSelect={onSelect} />
    <DateSelector keyName="break_1" title="휴게 시간" onSelectTime={data.break[0]} onSelect={onSelect} />
    {
      !(data.break[0].start && data.break[0].end) || <DateSelector keyName="break_2" title="휴게 시간" onSelectTime={data.break[1]} onSelect={onSelect} />
    }
  </Grid>
);

export default ServiceBusinessHoursDate;
