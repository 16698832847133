import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';
import { Button } from 'components/ui/Button';
import { LayoutFlex, MontserratTypography, NotoSansTypography } from '@common/components';
import { RoomService } from 'types/roomService';
import { List } from 'types/api';
import { useMutation } from '@apollo/client';
import { deleteRoomServiceMutation } from 'graphql/roomService';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import DataNotFound from 'components/ui/DataNotFound';
import imageNotFound from 'images/imageNotFound.png';

interface Props {
  data: List<RoomService> | undefined
  onUpdate: (type: 'roomService', data: RoomService) => void
  refetch: () => void
}

const ItemWrapper = styled.div`
  cursor: pointer;
  width: 32%;
  /* height: 130px; */
  border-radius: 10px;
  border: 1px solid ${COLORS.GRAY_02};
  margin-bottom: 30px;
`;

const Grid = styled(LayoutFlex)`
  align-items: center;
`;

interface ItemProps {
  data: RoomService
  onClick: (id: string) => void
  onDelete: (event: React.MouseEvent<HTMLDivElement>, id: string) => void
  isLast: boolean
}

const Thumbnail = styled(Grid)`
  width: 100%;
  height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-start;
  justify-content: flex-end;

  > div {
    cursor: pointer;
    padding-right: 8px;
    padding-top: 8px;
  }
`;

const Item: React.FunctionComponent<ItemProps> = ({ data, onClick, onDelete, isLast }) => (
  <ItemWrapper onClick={() => onClick(data.id)} style={{ marginRight: isLast ? 0 : 16 }}>
    <div>
      <Thumbnail style={{ backgroundImage: `url(${data.thumbnail || imageNotFound})` }}>
        <div>
          <DeleteButton width={24} height={24} onClick={(event: any) => onDelete(event, data.id)} />
        </div>
      </Thumbnail>
      <div style={{ padding: 20 }}>
        <Grid>
          <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>{data.title}</NotoSansTypography>
        </Grid>
        <Grid style={{ margin: '20px 0px 0px 0px' }}>
          <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_01}>{`카테고리: ${data.categories?.length ? data.categories[0].name : '없음'}`}</NotoSansTypography>
        </Grid>
        <Grid style={{ justifyContent: 'space-between', paddingTop: 4, height: 34 }}>
          <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_01}>{`${data.description.slice(0, 30)}${data.description.length > 30 ? '...' : ''}`}</NotoSansTypography>
          {/* <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontColor={COLORS.GRAY_01}>{`${data.description.slice(0, 30)}${data.description.length > 30 ? '...' : ''}/${Number(data.leadTime)}분`}</NotoSansTypography> */}
          <Grid>
            {
              data.price === 0 && <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>무료</NotoSansTypography>
            }
            {
              (data.price > 0) && (
                <>
                  <MontserratTypography fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} fontSize={20} lineHeight={20}>{(data.price || 0).toLocaleString()}</MontserratTypography>
                  <div style={{ padding: '4px 0px 0px 2px' }}>
                    <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>원</NotoSansTypography>
                  </div>
                </>
              )
            }
            {/* {
            (data.price > 0 && data.stock > 0) && (
              <>
                <MontserratTypography fontWeight={FONTS.MONTSERRAT.SEMI_BOLD} fontSize={20} lineHeight={20}>{(data.price || 0).toLocaleString()}</MontserratTypography>
                <div style={{ padding: '4px 0px 0px 2px' }}>
                  <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD}>원</NotoSansTypography>
                </div>
              </>
            )
          } */}
            {/* {
            data.stock === 0 && <Button width="57px" height={34} backgroundColor={COLORS.WHITE} borderColor={COLORS.PINK_RED} fontColor={COLORS.PINK_RED} text="품절" />
          } */}
          </Grid>
        </Grid>
      </div>
    </div>
  </ItemWrapper>
);

const Wrapper = styled(LayoutFlex)`
  width: 100%;
  height: 100%;
  margin-top: 28px;
  flex-wrap: wrap;
`;

const RoomServiceItem: React.FunctionComponent<Props> = ({ data, onUpdate, refetch }) => {
  const [emitDeleteRoomServiceMutation] = useMutation(deleteRoomServiceMutation);

  const onDelete = React.useCallback((event: React.MouseEvent<HTMLDivElement>, id: string) => {
    event.stopPropagation();

    confirmAlert({
      title: '해당 정보를 정말 삭제하시겠습니까?',
      message: '삭제 버튼을 누를 시 해당 아이템이 삭제됩니다.',
      buttons: [
        {
          label: '삭제',
          onClick: async () => {
            await toast.promise(
              emitDeleteRoomServiceMutation({
                variables: {
                  id: [id],
                },
              }),
              {
                loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>삭제중...</NotoSansTypography>,
                success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>룸서비스 삭제가 성공적으로 완료되었습니다.</NotoSansTypography>,
                error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>룸서비스 삭제 중 문제가 발생했습니다.</NotoSansTypography>,
              },
            );

            refetch();
          },
        },
        {
          label: '취소',
          onClick: () => { },
        },
      ],
    });
  }, []);

  return (
    <Wrapper>
      {
        data?.edges.map((roomService, index) => <Item isLast={data?.edges.length === (index + 1)} key={roomService.node.id} data={roomService.node} onClick={(id: string) => onUpdate('roomService', roomService.node)} onDelete={onDelete} />)
      }
      {
        !data?.edges.length && <DataNotFound text="룸서비스 상품이 없습니다." containerStyles={{ border: 'none', height: 250 }} fontSize={16} />
      }
    </Wrapper>
  );
};

export default RoomServiceItem;
