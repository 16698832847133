import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { COLORS, FONTS } from "@common/styles";

export const ConciergeTypography = styled<any>(Typography)`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ fontColor }: any) => fontColor || COLORS.BLACK};
  padding-right: ${({ paddingRight }) => paddingRight || 0}px;
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  font-family: ${({ font }) => font || "NotoSansCJKKR-Regular"}, "sans-serif";
  opacity: ${({ opacity }) => opacity || 1};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "-0.8"}px;
  line-height: ${({ lineHeight }) =>
    `${typeof lineHeight !== "undefined" ? `${lineHeight}px` : "16px"}`};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  word-break: break-all;
  white-space: ${({ whiteSpace }) => whiteSpace || "pre-line"};
  opacity: ${({ opacity }) => opacity};
  text-align: ${({ textAlign }) => textAlign};
  text-shadow: ${({ textShadow }) => textShadow};
`;

export const NotoSansTypography = styled<any>(Typography)`
  font-family: "NotoSansKR";
  font-weight: ${({ fontWeight }) => fontWeight || FONTS.NOTO_SANS.MEDIUM};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || -0.48}px;
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ fontColor }: any) => fontColor || COLORS.BLACK};
  line-height: ${({ lineHeight }) =>
    `${typeof lineHeight !== "undefined" ? `${lineHeight}px` : "16px"}`};
  white-space: ${({ whiteSpace }) => whiteSpace || "pre-line"};
  opacity: ${({ opacity }) => opacity};
`;

export const MontserratTypography = styled<any>(Typography)`
  font-family: "Montserrat";
  font-weight: ${({ fontWeight }) => fontWeight || FONTS.MONTSERRAT.MEDIUM};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 0}px;
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ lineHeight }) =>
    `${typeof lineHeight !== "undefined" ? `${lineHeight}px` : "16px"}`};
  white-space: ${({ whiteSpace }) => whiteSpace || "pre-line"};
  opacity: ${({ opacity }) => opacity};
  color: ${({ fontColor }: any) => fontColor || COLORS.BLACK};
`;

export const SUITTypography = styled<any>(Typography)`
  font-family: "SUIT";
  font-weight: ${({ fontWeight }) => fontWeight || FONTS.MONTSERRAT.MEDIUM};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || -0.48}px;
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ lineHeight }) =>
    `${typeof lineHeight !== "undefined" ? `${lineHeight}px` : "16px"}`};
  white-space: ${({ whiteSpace }) => whiteSpace || "pre-line"};
  opacity: ${({ opacity }) => opacity};

  color: ${({ fontColor }: any) => fontColor || COLORS.BLACK};
`;
