import React from 'react';
import styled from 'styled-components';
import Modal from 'components/ui/Modal';
import { COLORS, FONTS } from '@common/styles';
import { LayoutFlex, NotoSansTypography } from '@common/components';
import { Button } from 'components/ui/Button';
import ImageUploader from 'components/ui/ImageUploader';
import { Circle } from 'components/ui/Circle';
import Input from 'components/ui/Input';
import DeliveryTimePicker from 'components/ui/DeliveryTimePicker';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';
import { DropdownItem } from 'types';
import { useGetAccommodationId, useInput } from 'helpers/hooks';
import { useMutation } from '@apollo/client';
import { createConciergeAmenityMutation, updateConciergeAmenityMutation } from 'graphql/amenity';
import toast from 'react-hot-toast';
import TagDropdownView from 'containers/TagDropdownView';
import SimpleBar from 'simplebar-react';

type ImageTypes = 'thumbnail' | 'images';
type Type = 'create' | 'update';
type TabTypes = 'hashTag' | 'category'

interface Props {
  isOpened: boolean
  onClose: () => void
  type: 'create' | 'update'
  data: any
}

type PhotoViewImage = {
  thumbnail: File | null,
  images: File[]
}

type AmenityTag = {
  category: DropdownItem[]
  hashTag: DropdownItem[]
}

interface AmenityCreateBody {
  tags: AmenityTag
  thumbnail: File | null
  images: File[]
}

interface AmenityInput {
  name: string
  description: string
  price: number | undefined
}

interface AmenityTime {
  hour: number
  minute: number
}

interface PhotoViewProps {
  images: PhotoViewImage
  onUploadPhoto: (data: Record<string, File | File[] | null>) => void
  onDeleteImage: (type: ImageTypes, index: number) => void
}

interface ServiceInputProps {
  keyName: string
  width?: string
  height?: number
  text: string
  unit?: string
  multiline?: boolean
  multilineRows?: number
  unitStyleProps?: Record<string, number | string>
  inputProps?: Record<string, string | number>
  onChange?: (key: string, value: string) => void
  placeholder?: string
}

interface TagDropdownViewType {
  title: string
  data: any
  tag?: string
  containerStyles?: Record<string, string | number>
}
interface CardProps {
  text: string
  children: React.ReactNode
  hideBorder?: boolean
  subTitle?: boolean
  requirement?: boolean
  addButton?: boolean
  onCreate?: () => void
  cardStyleProps?: Record<string, string | number>
  cardLayoutStyleProps?: Record<string, string | number>
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
  width: 871px;
  height: 944px;
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.GRAY_02};
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0px 34px;
`;

const FooterWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.GRAY_02};
  align-items: center;
  padding: 0px 40px;
  justify-content: flex-end;
  height: 94px;
`;

const Grid = styled(SimpleBar) <{ width: number, borderRight?: boolean, padding?: string }>`
  padding: ${({ padding }) => padding || '30px 40px'};
  width: ${({ width }) => width}px;
  border-right: ${({ borderRight }) => borderRight && `1px solid ${COLORS.GRAY_02}`};
  height: 752px;
`;

const CardWrapper = styled.div<{ hideBorder?: boolean }>`
  border-bottom: ${({ hideBorder }) => (hideBorder ? 'none' : `1px solid ${COLORS.GRAY_02}`)};
  padding-bottom: 40px;
  margin-bottom: ${({ hideBorder }) => (!hideBorder ? 30 : 0)}px;

  > div:nth-child(1) {
    padding-bottom: 16px;
  }
`;

const PhotoWrapper = styled(LayoutFlex)`
  flex-wrap: wrap;
  height: 270px;
  width: 100%;

  > div:nth-child(odd) {
    margin-right: 14px;
    margin-bottom: 14px;
  }
`;

const PhotoGrid = styled(LayoutFlex)`
  position: relative;
  z-index: 1;
  width: 178px;
  height: 118px;

  > img {
    max-width: 178px;
    max-height: 118px;
    min-width: 178px;
    min-height: 118px;
    object-fit: cover;
  }
`;

const PhotoDiv = styled.div`
  z-index: 2;
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  top: 4px;
  right: 4px;
`;

const Footer = ({ onClose, onClick, text }: { onClose: () => void, onClick: () => void, text?: string | undefined }) => (
  <FooterWrapper>
    <Button fontWeight="bold" text="취소" backgroundColor={COLORS.WHITE} fontColor={COLORS.GRAY_01} width="158px" height={56} fontSize={20} styleProps={{ marginRight: 12 }} onClick={onClose} />
    <Button fontWeight="bold" borderWidth="0" text={text || '추가'} backgroundColor={COLORS.BROWN} fontColor={COLORS.WHITE} width="220px" height={56} fontSize={20} onClick={onClick} />
  </FooterWrapper>
);

const Header = ({ type }: { type: Type }) => (
  <HeaderWrapper>
    <NotoSansTypography fontSize={24} lineHeight={24} letterSpacing={-1.2} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>{type === 'create' ? '상품 추가' : '상품 변경'}</NotoSansTypography>
  </HeaderWrapper>
);

const Requirement = () => (
  <LayoutFlex alignItems="center">
    <Circle color={COLORS.PINK_RED} width={8} height={8} style={{ marginRight: 8, marginLeft: 24 }} />
    <NotoSansTypography fontSize={14} lineHeight={14} letterSpacing={-0.42}>필수</NotoSansTypography>
  </LayoutFlex>
);

const Title = ({ text }: { text: string }) => <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} fontSize={18} lineHeight={18} letterSpacing={-0.9}>{text}</NotoSansTypography>;
const SubTitle = ({ text }: { text: string }) => <NotoSansTypography letterSpacing={-0.8} fontColor={COLORS.GRAY_01} fontWeight={FONTS.NOTO_SANS.BOLD}>{text}</NotoSansTypography>;

const ServiceInput = ({
  width, height, unit, multiline, multilineRows, unitStyleProps, text, onChange, keyName, inputProps, placeholder,
}: ServiceInputProps) => <Input text={text} placeholder={placeholder} inputProps={inputProps} unitStyleProps={unitStyleProps} multiline={multiline} multilineRows={multilineRows} width={width || '360px'} height={height || 56} backgroundColor={COLORS.WHITE} border={`1px solid ${COLORS.GRAY_02}`} containerStyleProps={{ marginBottom: 0 }} unit={unit} onChange={(value) => onChange && onChange(keyName, value)} />;

const Card: React.FunctionComponent<CardProps> = ({
  text, children, hideBorder, subTitle, requirement, cardStyleProps, cardLayoutStyleProps, addButton, onCreate,
}) => (
  <CardWrapper hideBorder={hideBorder} style={cardStyleProps}>
    <LayoutFlex justifyContent={addButton ? 'space-between' : 'flex-start'} alignItems="center" style={cardLayoutStyleProps}>
      {
        subTitle ? <SubTitle text={text} /> : <Title text={text} />
      }
      {
        requirement && <Requirement />
      }
      {
        addButton && <Button onClick={onCreate} text="추가 +" width="90px" height={40} backgroundColor={COLORS.WHITE} borderColor={COLORS.BROWN} fontColor={COLORS.BROWN} />
      }
    </LayoutFlex>
    {children}
  </CardWrapper>
);

const Photos = ({ images, onDelete }: { images: File[], onDelete: (index: number) => void }) => (
  <>
    {
      images.map((image: File, index: number) => (
        <PhotoGrid key={image.name}>
          <img src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt={`img_${image.name}`} />
          <PhotoDiv onClick={() => onDelete(index)}>
            <DeleteButton width={24} height={24} />
          </PhotoDiv>
        </PhotoGrid>
      ))
    }
  </>
);

const PhotoView: React.FunctionComponent<PhotoViewProps> = ({ images, onUploadPhoto, onDeleteImage }) => {
  const uploadPhoto = (type: ImageTypes, files: any) => {
    if (files?.length) {
      const newImages: PhotoViewImage = {
        ...images,
        [type]: type === 'thumbnail' ? files[0] : images[type].concat(Array.from(files)),
      };

      onUploadPhoto(newImages);
    }
  };

  return (
    <Grid width={452} borderRight>
      <LayoutFlex alignItems="center" style={{ paddingBottom: 22 }}>
        <Title text="사진 추가" />
        {/* <Requirement /> */}
      </LayoutFlex>
      <Card subTitle text="대표이미지" cardStyleProps={{ paddingBottom: 28 }}>
        <ImageUploader type="roomServiceThumbnail" width={372} height={182} onChange={(event: React.ChangeEvent<HTMLInputElement>) => uploadPhoto('thumbnail', event.target.files)} preview={images.thumbnail} />
      </Card>
      <Card subTitle text="추가한 사진" hideBorder>
        <PhotoWrapper>
          <ImageUploader type="roomServiceImages" width={178} height={118} onChange={(event: React.ChangeEvent<HTMLInputElement>) => uploadPhoto('images', event.target.files)} isMultiple />
          <Photos images={images.images} onDelete={(index) => onDeleteImage('images', index)} />
        </PhotoWrapper>
      </Card>
    </Grid>
  );
};

interface DetailProps {
  input: AmenityInput
  times: AmenityTime
  categories: DropdownItem[]
  onChange: (key: string, value: string) => void
  onSelectedItem: (type: 'hour' | 'minute', item: DropdownItem) => void
  onSelectTag: (type: TabTypes, item: DropdownItem) => void
  onDeleteTag: (type: TabTypes, index: number) => void
}

const DetailView: React.FunctionComponent<DetailProps> = ({
  input, times, onChange, onSelectedItem, onSelectTag, onDeleteTag, categories,
}) => (
  <Grid width={419} padding="30px 18px">
    <Card text="어메니티명" hideBorder requirement>
      <ServiceInput keyName="name" placeholder="어메니티명 입력" onChange={onChange} text={input.name} />
    </Card>
    <Card text="설명" hideBorder requirement>
      <ServiceInput
        keyName="description"
        placeholder="설명 입력 (300자 이내)"
        onChange={onChange}
        text={input.description}
        multiline
        height={186}
        unit="300자 이내"
        inputProps={{
          maxLength: 300,
        }}
        multilineRows={8}
        unitStyleProps={{
          position: 'absolute',
          bottom: 10,
          right: -10,
          width: 120,
        }}
      />
    </Card>
    <Card text="예상 소요시간" hideBorder requirement>
      <DeliveryTimePicker onSelect={onSelectedItem} selectedItemIndex={(times.minute / 5)} />
    </Card>
    <Card text="가격" hideBorder requirement>
      <ServiceInput keyName="price" text={typeof input.price !== 'undefined' ? (Number(input.price)).toLocaleString() : ''} unit={typeof input.price !== 'undefined' ? '원' : undefined} placeholder="가격 입력" onChange={onChange} inputProps={{ inputMode: 'numeric' }} />
    </Card>
    <Card text="분류 설정" hideBorder>
      <div style={{ paddingTop: 24, position: 'relative' }}>
        <TagDropdownView type="category" contentType="amenity" title="카테고리" selectedItems={categories} onSelectTag={onSelectTag} onDeleteTag={onDeleteTag} />
        {/* <TagDropdownView title="해시태그" tag="#" data={TAG_DOWN_DATA} /> */}
      </div>
    </Card>
  </Grid>
);

const AmenityCreateUpdate: React.FunctionComponent<Props> = ({ onClose, isOpened, type, data }) => {
  const { accommodationId } = useGetAccommodationId();
  const [input, { onChangeInput, setInput }] = useInput<AmenityInput>({
    name: '',
    description: '',
    price: undefined,
  });

  const [body, setBody] = React.useState<AmenityCreateBody>({
    tags: {
      category: [],
      hashTag: [],
    },
    thumbnail: null,
    images: [],
  });

  const [times, { setInput: setInputTime }] = useInput<AmenityTime>({
    hour: 0,
    minute: 30,
  });

  const [emitCreateConciergeAmenity] = useMutation(createConciergeAmenityMutation);
  const [emitUpdateConciergeAmenity] = useMutation(updateConciergeAmenityMutation);

  React.useEffect(() => {
    if (data) {
      setInput({
        ...input,
        name: data.name,
        description: data.description,
        price: data.price,
      });

      setInputTime((time: AmenityTime) => ({
        ...time,
        minute: data.deliveryTime,
      }));

      setBody({
        ...body,
        tags: {
          ...body.tags,
          category: data.categories,
        },
        images: data.images,
        thumbnail: data.thumbnail,
      });
    }
  }, [data]);

  const onUpdateBody = (data: Record<string, string | File | File[] | null>) => {
    setBody((initialBody) => ({
      ...initialBody,
      ...data,
    }));
  };

  const onDeleteImage = (type: ImageTypes, index: number) => {
    if (type === 'images') {
      const copyBody = { ...body };

      copyBody.images.splice(index, 1);

      setBody({
        ...body,
        images: copyBody.images,
      });
    }
  };

  const onSelectDropdownItem = React.useCallback((type: 'hour' | 'minute', item: DropdownItem) => {
    setInputTime((time: AmenityTime) => ({
      ...time,
      [type]: type === 'hour' ? (60 * Number(item.name)) : Number(item.name),
    }));
  }, []);

  const onSelectTag = (type: TabTypes, item: DropdownItem) => {
    const copyTags: AmenityTag = { ...body.tags };
    const newTag = copyTags[type].concat(item);

    setBody({
      ...body,
      tags: {
        ...body.tags,
        [type]: newTag,
      },
    });
  };

  const onDeleteTag = (type: TabTypes, index: number) => {
    const copyTag = [...body.tags[type]];

    copyTag.splice(index, 1);

    setBody({
      ...body,
      tags: {
        ...body.tags,
        [type]: copyTag,
      },
    });
  };

  const validateChecker = () => {
    let state = {
      isValid: false,
      text: '',
    };

    // if (!body.images) {
    //   state = {
    //     isValid: true,
    //     text: '대표이미지를 선택해주세요.',
    //   };
    // }

    if (typeof input.price === 'undefined') {
      state = {
        isValid: true,
        text: '가격을 입력해주세요.',
      };
    }

    if (!input.description) {
      state = {
        isValid: true,
        text: '설명을 입력해주세요.',
      };
    }

    if (!input.name) {
      state = {
        isValid: true,
        text: '어메니티명을 입력해주세요.',
      };
    }

    // if (!body.tags.category.length) {
    //   state = {
    //     isValid: true,
    //     text: '카테고리를 선택해주세요.',
    //   };
    // }

    return state;
  };

  const onCreate = async () => {
    const validate = validateChecker();

    if (validate.isValid) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{validate.text}</NotoSansTypography>);
    }

    await toast.promise(
      emitCreateConciergeAmenity({
        variables: {
          input: [
            {
              ...input,
              accommodationId,
              price: Number(input.price),
              deliveryTime: (times.hour + times.minute).toString(),
              images: body.images,
              thumbnail: body.thumbnail,
              categories: body.tags.category.map(({ id }) => id),
            },
          ],
        },
      }),
      {
        loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>생성중...</NotoSansTypography>,
        success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>어메니티 생성이 성공적으로 완료되었습니다.</NotoSansTypography>,
        error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>어메니티 생성 중 문제가 발생했습니다.</NotoSansTypography>,
      },
    );

    onClose();
  };

  const onUpdate = async () => {
    const validate = validateChecker();

    if (validate.isValid) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{validate.text}</NotoSansTypography>);
    }

    await toast.promise(
      emitUpdateConciergeAmenity({
        variables: {
          input: {
            id: data.id,
            accommodationId,
            ...input,
            price: Number(input.price),
            deliveryTime: times.hour + times.minute,
            images: body.images,
            thumbnail: body.thumbnail,
            soldOutPeriod: data.soldOutPeriod,
            categories: body.tags.category.map(({ id }) => id),
          },
        },
      }),
      {
        loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
        success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>어메니티 변경이 성공적으로 완료되었습니다.</NotoSansTypography>,
        error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>어메니티 변경 중 문제가 발생했습니다.</NotoSansTypography>,
      },
    );

    onClose();
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <Wrapper>
        <Header type={type} />
        <LayoutFlex>
          <PhotoView images={{ thumbnail: body.thumbnail, images: body.images }} onUploadPhoto={onUpdateBody} onDeleteImage={onDeleteImage} />
          <DetailView input={input} times={times} categories={body.tags.category} onChange={onChangeInput} onSelectedItem={onSelectDropdownItem} onSelectTag={onSelectTag} onDeleteTag={onDeleteTag} />
        </LayoutFlex>
        <Footer onClose={onClose} text={(type === 'update' && '저장') || undefined} onClick={type === 'create' ? onCreate : onUpdate} />
      </Wrapper>
    </Modal>
  );
};

export default AmenityCreateUpdate;
