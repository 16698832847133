import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@common/styles';
import VenditLogo from 'components/VenditLogo';

const Wrapper = styled.div`
  height: 95px;
  border-bottom: 1px solid ${COLORS.GRAY_03};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VenditHeader: React.FunctionComponent = () => (
  <Wrapper>
    <VenditLogo />
  </Wrapper>
);

export default VenditHeader;
