import React from 'react';
import { LayoutFlexJustifyContent } from '@common/components';
// @ts-ignore
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import ko from 'date-fns/locale/ko';
import styled from 'styled-components';
import { COLORS } from '@common/styles';
import { DateRangePickerType } from 'types';
import moment from 'moment';
import { Button } from './Button';

interface Props {
  containerStyles?: Record<string, string | number>
  onSelectDateRange?: (ranges: DateRangePickerType) => void;
  onClose?: () => void
}

const Wrapper = styled.div`
  position: absolute;
  background-color: white;
`;

const DateRangePickerModal: React.FunctionComponent<Props> = ({ containerStyles, onSelectDateRange, onClose }) => {
  const [date, setDate] = React.useState<DateRangePickerType>({
    startDate: moment().toDate(),
    endDate: moment().add(1, 'days').toDate(),
    key: 'selection',
  });

  const onDateSelected = React.useCallback((rageKey: RangeKeyDict) => {
    setDate(rageKey.selection as DateRangePickerType);

    if (typeof onSelectDateRange === 'function') {
      onSelectDateRange(rageKey.selection as DateRangePickerType);
    }
  }, []);

  return (
    <Wrapper style={containerStyles}>
      <DateRangePicker
        rangeColors={[COLORS.BROWN]}
        locale={ko}
        showMonthAndYearPickers={false}
        onChange={onDateSelected}
        ranges={[date]}
        months={2}
        direction="horizontal"
      />
      <LayoutFlexJustifyContent justifyContent="flex-end" style={{ paddingBottom: 16 }}>
        <Button fontWeight="bold" text="확인" backgroundColor={COLORS.BROWN} fontColor={COLORS.WHITE} width="130px" height={38} styleProps={{ marginRight: 12 }} onClick={onClose} />
      </LayoutFlexJustifyContent>
    </Wrapper>
  );
};

export default DateRangePickerModal;
