import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@common/styles';
import Arrow from 'components/ui/Arrow';
import {
  LayoutFlex, LayoutFlexCenterAligned, NotoSansTypography, SUITTypography,
} from '@common/components';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';
import SimpleBar from 'simplebar-react';

interface Item {
  name: string
  id: string
  icon?: React.ReactElement
}

interface Props {
  data: Item[]
  selectedItems?: Item[]
  containerStyles?: Record<string, string | number>
  itemContainerStyles?: Record<string, string | number>
  onSelected?: (item: Item) => void
  onDeleted?: (index: number) => void
  tag?: string
  width?: string
  height?: number
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
}

interface WrapperProps {
  width?: string
  height?: number
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
}

interface ListItemProps {
  data: Item
  isSelected: boolean
  onSelect: (item: Item) => void
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || '240px'};
  min-height: ${({ height }) => height || 56}px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.WHITE};
  border: ${({ borderWidth, borderColor }) => `${borderWidth || 1}px solid ${borderColor || COLORS.GRAY_02}`};
  border-radius: 8px;
`;

const Grid = styled.div<{ height?: number }>`
  display: flex;
  min-height: ${({ height }) => height || 56}px;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
  > div:nth-child(1) {
    flex-wrap: wrap;
  }
`;

const List = styled(SimpleBar)`
  position: absolute;
  margin-top: 16px;
  z-index: 2;
  max-height: 194px;
  border-radius: 8px;
  border: 1px solid ${COLORS.GRAY_02};
  background-color: ${COLORS.WHITE};
`;

const ListGrid = styled.div`
  display: flex;
  align-items: center;  
  height: 48px;
  cursor: pointer;
  padding: 16px;
  :hover {
    background-color: ${COLORS.BROWN_0DOT01};
  }
`;

const ArrowWrapper = styled(LayoutFlex)`
  align-items: center;
`;

const SelectItemWrapper = styled(LayoutFlexCenterAligned)`
  height: 40px;
  border: 1px solid ${COLORS.GRAY_02};
  border-radius: 6px;
  padding: 12px;
`;

const ListItem: React.FunctionComponent<ListItemProps> = ({ data, isSelected, onSelect }) => (
  <ListGrid onClick={() => onSelect(data)}>
    {
      data.icon && (
        <div style={{ marginRight: 10 }}>
          {data.icon}
        </div>
      )
    }
    <SUITTypography fontColor={isSelected && COLORS.GRAY_03}>{data.name}</SUITTypography>
  </ListGrid>
);

interface SelectedItemProps {
  index: number
  tag: string | undefined
  selectedItemData: Item
  onDeleteSelectedItem: (id: string, index: number) => void
}

const SelectedItem: React.FunctionComponent<SelectedItemProps> = ({
  tag, selectedItemData, onDeleteSelectedItem, index,
}) => (
  <SelectItemWrapper>
    <NotoSansTypography>
      {tag}
      {selectedItemData.name}
    </NotoSansTypography>
    <DeleteButton onClick={() => onDeleteSelectedItem(selectedItemData.id, index)} width={24} height={24} style={{ marginLeft: 8, cursor: 'pointer' }} />
  </SelectItemWrapper>
);

const TagDropdown: React.FunctionComponent<Props> = ({
  data, selectedItems, width, height, backgroundColor, containerStyles, itemContainerStyles, borderColor, borderWidth, onSelected, tag, onDeleted,
}) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [isSelectedItems, setIsSelectedItems] = React.useState<Item[]>(selectedItems || []);

  const isSelectedItem = React.useCallback((id: string) => isSelectedItems.findIndex((item) => item.id === id) !== -1, [isSelectedItems]);

  const onSelectItem = (newItem: Item) => {
    const foundItem = isSelectedItem(newItem.id);

    setIsSelectedItems((items: Item[]) => {
      if (foundItem) {
        return items;
      }
      return [...items, newItem];
    });

    setIsOpened(false);
    if (!foundItem && typeof onSelected === 'function') {
      onSelected(newItem);
    }
  };

  const onDeleteSelectedItem = (id: string, index: number) => {
    if (typeof onDeleted === 'function') {
      onDeleted(index);
    }

    setIsSelectedItems((items: Item[]) => {
      if (isSelectedItem(id)) {
        return items.filter((item) => item.id !== id);
      }
      return items;
    });
  };

  React.useEffect(() => {
    if (selectedItems) {
      setIsSelectedItems(selectedItems);
    }
  }, [selectedItems]);

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper width={width} height={height} backgroundColor={backgroundColor} style={containerStyles} borderColor={borderColor} borderWidth={borderWidth}>
        <Grid height={height} onClick={() => setIsOpened(!isOpened)}>
          <LayoutFlex style={{ rowGap: 12, columnGap: 8 }}>
            {
              isSelectedItems.map((item: Item, index: number) => <SelectedItem key={item.id} tag={tag} onDeleteSelectedItem={onDeleteSelectedItem} selectedItemData={item} index={index} />)
            }
          </LayoutFlex>
          <ArrowWrapper style={isSelectedItems.length ? { borderLeft: `1px solid ${COLORS.GRAY_02}`, paddingLeft: 16 } : {}}>
            <Arrow type="bottom" containerStyles={{ position: 'static' }} />
          </ArrowWrapper>
        </Grid>
      </Wrapper>
      <List style={{ display: !isOpened ? 'none' : 'block', ...itemContainerStyles, width }}>
        {
          data?.map((dropdownItem) => <ListItem data={dropdownItem} key={dropdownItem.id} isSelected={isSelectedItem(dropdownItem.id)} onSelect={onSelectItem} />)
        }
      </List>
    </div>
  );
};

export default TagDropdown;
