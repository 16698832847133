import React from 'react';
import PaginationBar from '@material-ui/lab/Pagination';
import { createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { LayoutFlexCenterAligned } from '@common/components';
import { UseInputState, UseInputUpdate } from 'types/hooks';

interface Props {
  paginationInfo?: UseInputState
  setCurrentPage?: (pagination: UseInputUpdate) => void
}

const Wrapper = styled(LayoutFlexCenterAligned)`
  position: relative;
  margin: 30px 0px;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

const Pagination: React.FunctionComponent<Props> = ({ paginationInfo, setCurrentPage }) => {
  const onChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (typeof setCurrentPage === 'function') {
      setCurrentPage({
        page,
      });
    }
  };

  const classes = useStyles();

  return (
    <Wrapper>
      <div className={classes.root}>
        <PaginationBar count={paginationInfo?.totalPages} page={paginationInfo?.page} onChange={onChange} size="large" />
      </div>
    </Wrapper>
  );
};

export default Pagination;
