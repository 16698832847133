import { COLORS, FONTS } from '@common/styles';
import React from 'react';
import styled from 'styled-components';
import AutoMaticallyInput from 'components/ui/AutoMaticallyInput';
import { Button } from 'components/ui/Button';
import { ReactComponent as DeleteButton } from 'images/ico_delete.svg';
import { NotoSansTypography } from '@common/components';
import { confirmAlert } from 'react-confirm-alert';
import { RoomServiceCategory } from 'types/roomService';
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables } from '@apollo/client';
import toast from 'react-hot-toast';
import { useGetAccommodationId } from 'helpers/hooks';
import DataNotFound from 'components/ui/DataNotFound';

type TagPropsItemData = {
  id?: string | null
  priority?: number
  name: string
}

interface TagPropsItem {
  name: string
  description: string
  data: TagPropsItemData[]
}

interface List {
  category: TagPropsItem
  // hashTag: TagPropsItem
}

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  margin-top: 62px;
  border-radius: 8px;
  
  > div {
    margin: 0px 24px;
  }

  > div:nth-last-child(1) {
    border: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
`;

const TagWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.GRAY_02};
  height: 144px;
  padding: 16px 0px;
`;

const ICON_STYLES = {
  width: 24,
  height: 24,
};

type TagTypes = 'category'
// type TagTypes = 'category' | 'hashTag'

interface TagProps {
  data: TagPropsItem
  type: TagTypes
  onCreate: (type: TagTypes) => void
  onUpdate: (type: TagTypes, index: number, text: string) => void
  onDelete: (type: TagTypes, id: string, index: number) => void
  onSave: (type: TagTypes, data: TagPropsItemData) => void
}

const Tag: React.FunctionComponent<TagProps> = ({
  data, type, onCreate, onUpdate, onDelete, onSave,
}) => {
  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.currentTarget.blur();
    }
  };

  return (
    <TagWrapper style={{ height: data?.data.length ? '144px' : 'auto' }}>
      <Grid style={{ justifyContent: 'space-between' }}>
        <Grid>
          <div style={{ marginRight: 24 }}>
            <NotoSansTypography fontSize={24} fontWeight={FONTS.NOTO_SANS.BLACK} lineHeight={24} letterSpacing={-0.72}>{data.name}</NotoSansTypography>
          </div>
          <NotoSansTypography>
            *
            {data.description.slice(0, 30)}
            {data.description.length > 30 && '...'}
          </NotoSansTypography>
        </Grid>
        <div>
          <Button text={`${data.name} 추가 +`} width="173px" height={48} backgroundColor={COLORS.WHITE} borderColor={COLORS.BROWN} fontColor={COLORS.BROWN} fontWeight="bold" onClick={() => onCreate(type)} />
        </div>
      </Grid>
      <Grid style={{ marginTop: 12 }}>
        {
          data?.data.map((item, priority) => <AutoMaticallyInput autoFocus={!item.id} key={item.id} onBlur={() => onSave(type, { name: item.name, priority, ...(!item.id ? {} : { id: item.id }) })} onKeyDown={onKeyDown} placeholder="입력" onChange={(event) => onUpdate(type, priority, event.target.value)} text={item.name} icon={<DeleteButton onClick={() => onDelete(type, item.id || '', priority)} {...ICON_STYLES} />} height={40} iconAlignItem="flex-end" backgroundColor={COLORS.WHITE} border={`1px solid ${COLORS.GRAY_02}`} padding="0px 12px" containerStyleProps={{ marginRight: 24, marginBottom: 0 }} />)
        }
      </Grid>
      {
        !data?.data.length && <DataNotFound
          text={`${data.name}가 없습니다.`}
          containerStyles={{ border: 'none', padding: '0px 0px 32px 0px', height: 68 }}
          fontSize={16}
        />
      }
    </TagWrapper>
  );
};

interface Props {
  category: RoomServiceCategory[]
  refetch: () => void
  onCreateCategory: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>
  onUpdateCategory: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>
  onDeleteCategory: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>
}

const ServiceTag: React.FunctionComponent<Props> = ({ category, onCreateCategory, onUpdateCategory, onDeleteCategory, refetch }) => {
  const { accommodationId } = useGetAccommodationId();
  const [list, setList] = React.useState<List>({
    category: {
      name: '카테고리',
      description: '상품을 분류하는 기능입니다.',
      data: [],
    },
    // hashTag: {
    //   name: '해시태그',
    //   description: '메뉴를 특정 기준으로 분류하는 기능입니다.',
    //   data: [],
    // },
  });

  React.useEffect(() => {
    if (category) {
      setList({
        ...list,
        category: {
          ...list.category,
          data: category,
        },
      });
    }
  }, [category]);

  const onCreate = (type: TagTypes) => {
    const foundLastElement = list[type].data[list[type].data.length - 1];

    if (foundLastElement?.id || list[type].data.length === 0) {
      setList({
        ...list,
        [type]: {
          ...list[type],
          data: list[type].data.concat({
            id: null,
            name: '',
          }),
        },
      });
    }
  };

  const onUpdate = (type: TagTypes, index: number, text: string) => {
    const copyData = [...list[type].data];

    copyData[index] = {
      ...copyData[index],
      name: text,
    };

    setList({
      ...list,
      [type]: {
        ...list[type],
        data: copyData,
      },
    });
  };

  const onSave = (type: TagTypes, data: TagPropsItemData) => {
    if (!data.name) {
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>태그명을 입력해주세요.</NotoSansTypography>);
    }

    confirmAlert({
      title: `해당 태그를 ${data.id ? '변경' : '추가'}하시겠습니까?`,
      buttons: [
        {
          label: data.id ? '변경' : '추가',
          onClick: async () => {
            if (!data.id) {
              await toast.promise(
                onCreateCategory({
                  variables: {
                    input: [
                      {
                        ...data,
                        accommodationId,
                      },
                    ],
                  },
                }),
                {
                  loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{data.id ? '변경중...' : '생성중...'}</NotoSansTypography>,
                  success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`태그가 성공적으로 ${data.id ? '변경' : '추가'}되었습니다.`}</NotoSansTypography>,
                  error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`태그 ${data.id ? '변경' : '추가'} 중 문제가 발생했습니다.`}</NotoSansTypography>,
                },
              );

              refetch();
            } else {
              await toast.promise(
                onUpdateCategory({
                  variables: {
                    input: [data],
                  },
                }),
                {
                  loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{data.id ? '변경중...' : '생성중...'}</NotoSansTypography>,
                  success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`태그가 성공적으로 ${data.id ? '변경' : '추가'}되었습니다.`}</NotoSansTypography>,
                  error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>{`태그 ${data.id ? '변경' : '추가'} 중 문제가 발생했습니다.`}</NotoSansTypography>,
                },
              );
              refetch();
            }
          },
        },
        {
          label: '취소',
          onClick: () => { },
        },
      ],
    });
  };

  const onDelete = (type: TagTypes, id: string, index: number) => {
    if (id) {
      confirmAlert({
        title: '해당 태그를 정말 삭제하시겠습니까?',
        message: '삭제 버튼을 누를 시 해당 태그가 삭제됩니다.',
        buttons: [
          {
            label: '삭제',
            onClick: async () => {
              if (id) {
                await toast.promise(
                  onDeleteCategory({
                    variables: {
                      id: [id],
                    },
                  }),
                  {
                    loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>삭제중...</NotoSansTypography>,
                    success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>카테고리 삭제가 성공적으로 완료되었습니다.</NotoSansTypography>,
                    error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>카테고리 삭제 중 문제가 발생하였습니다.</NotoSansTypography>,
                  },
                );
                refetch();
              } else {
                const copyList = { ...list };
                copyList[type].data.splice(index, 1);

                setList(copyList);
              }
            },
          },
          {
            label: '취소',
            onClick: () => { },
          },
        ],
      });
    } else {
      const copyList = { ...list };
      copyList[type].data.splice(index, 1);

      setList(copyList);
    }
  };

  return (
    <Wrapper>
      <Tag data={list.category} type="category" onCreate={onCreate} onUpdate={onUpdate} onSave={onSave} onDelete={onDelete} />
      {/* <Tag data={list.hashTag} type="hashTag" onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} /> */}
    </Wrapper>
  );
};

export default ServiceTag;
