import React from 'react';
import VenditHeader from 'components/VenditHeader';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { COLORS, FONTS } from '@common/styles';
import { NotoSansTypography } from '@common/components';
import { authHandler, useUser } from 'helpers/authHandler';
import Input from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { useInput } from 'helpers/hooks';
import { ReactComponent as User } from 'images/user.svg';
import { ReactComponent as Password } from 'images/password.svg';
import { Route, useNavigate } from 'react-router-dom';
import { signInMutation } from 'graphql/user';
import { onEnter } from 'helpers';
import { toast } from 'react-hot-toast';

interface Auth {
  identity: string
  password: string
}

const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.WHITE};
  width: 600px;
  height: 500px;
  border-radius: 16px;
`;

const LayoutFlexCenterAligned = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const RememberGrid = styled.div`
  display: flex;
  align-items: center;
`;

const AuthContainer: any = () => {
  const navigate = useNavigate();
  const [emitSignIn] = useMutation(signInMutation);
  const user = useUser();

  const [input, { onChangeInput }] = useInput<Auth>({
    identity: '',
    password: '',
  });

  const [remember] = React.useState<boolean>(false);

  const signIn = async () => {
    try {
      if (!input.identity || !input.password) {
        return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>아이디 혹은 비밀번호가 잘못되었습니다</NotoSansTypography>);
      }

      const signInResult = await emitSignIn({
        variables: {
          input,
        },
      });
      const {
        data: {
          signIn: {
            token: { accessToken, refreshToken },
          },
        },
      } = signInResult;

      authHandler.signIn({ accessToken, refreshToken, usePermanent: true });
      navigate('/', { replace: true });
    } catch (err) {
      console.log(err);
      return toast.error(<NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>아이디 혹은 비밀번호를 확인해 주세요.</NotoSansTypography>);
    }
  };

  if (user) {
    return navigate('/', { replace: true });
  }

  return (
    <div>
      <VenditHeader />
      <LayoutFlexCenterAligned>
        <Grid>
          <div>
            <div style={{ marginBottom: 32 }}>
              <NotoSansTypography fontSize={40} fontWeight={FONTS.NOTO_SANS.BLACK} textAlign="center" letterSpacing={-2} lineHeight={40}>로그인</NotoSansTypography>
            </div>
            <Input text={input.identity} placeholder="아이디 입력" onChange={(value) => onChangeInput('identity', value)} icon={<User />} onKeyDown={onEnter(signIn)} />
            <Input text={input.password} type="password" placeholder="비밀번호 입력" onChange={(value) => onChangeInput('password', value)} icon={<Password />} onKeyDown={onEnter(signIn)} />
            {/* <RememberGrid>
              <Checkbox isVisible={remember} />
              <NotoSansTypography fontWeight={FONTS.NOTO_SANS.BOLD} letterSpacing={-0.8}>기억하기</NotoSansTypography>
            </RememberGrid> */}
            <Button text="로그인" width="460px" height={70} fontColor={COLORS.WHITE} backgroundColor={(input.identity && input.password) ? COLORS.BROWN : COLORS.GRAY_03} borderColor="none" onClick={signIn} styleProps={{ marginTop: 8 }} fontWeight="bold" />
          </div>
        </Grid>
      </LayoutFlexCenterAligned>
    </div>
  );
};

export default AuthContainer;
