import { gql } from '@apollo/client';

export const getMyAccommodationsQuery = gql`
  query getMyAccommodations($useStartAt: Date, $useExpireAt: Date) {
    getMyAccommodations {
      id
      name
      thumbnail
      salesToday
      isActive
      todayReservationCount: todayReservationCountByType(
        useStartAt: $useStartAt
        useExpireAt: $useExpireAt
      ) {
        type
        count
      }
      activeReservationCount
      state
      conciergeServiceSettings {
        enableConcierge
        onSitePaymentByCash
        onSitePaymentByCard
        tempWorkingStop
        roomServiceActivated
        amenityActivated
      }
    }
  }
`;
