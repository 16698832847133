import React from 'react';
import Input from 'components/ui/Input';
import { COLORS } from '@common/styles';
import { ReactComponent as Search } from 'images/search.svg';
import { onEnter } from 'helpers';

interface Props {
  width: string
  height: number
  placeholder?: string
  onSearch: (text: string) => void
  containerStyles?: Record<string, string | number>
  inputProps?: Record<string, string | number>
}

const SearchBar: React.FunctionComponent<Props> = ({ width, height, placeholder, onSearch, containerStyles, inputProps }) => {
  const [text, setText] = React.useState('');

  return (
    <div>
      {/* icon={<Search width={18} height={18} />} */}
      <Input width={width} height={height} placeholder={placeholder} containerStyleProps={{ marginBottom: 0, marginRight: 16, padding: '0px 16px', ...containerStyles }} backgroundColor={COLORS.WHITE} border={`1px solid ${COLORS.GRAY_02}`} text={text} iconAlignItem="flex-end" onChange={(value) => setText(value)} onKeyDown={onEnter(() => onSearch(text))} inputProps={inputProps} />
    </div>
  );
};

export default SearchBar;
