import { gql } from '@apollo/client';

export const signInMutation = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;
