import { gql } from '@apollo/client';
import { OffsetPageInfoFragment } from './index';

export const RoomServiceFragment = gql`
  fragment RoomServiceFragment on RoomService {
    id
    title
    description
    price
    # status
    categories {
      id
      accommodationId
      name
      status
      priority
    }
    # hashtags {
    #   id
    #   accommodationId
    #   name
    #   status
    #   priority
    # }
    soldOutPeriod
    summary
    leadTime
    thumbnail
    images
    options {
      id
      name
      price
      status
    }
    orderRequests {
      id
      request
    }
  }
`;

export const RoomServiceOptionFragment = gql`
  fragment RoomServiceOptionFragment on RoomServiceOption {
    id
    name
    price
    status
  }
`;

export const RoomServiceOptionDetailFragment = gql`
  fragment RoomServiceOptionDetailFragment on RoomServiceOptionDetail {
    id
    origin {
      ...RoomServiceOptionFragment
    }
    roomServiceId
    name
    price
    status
    quantity
  }
`;

export const RoomServiceOrderDetailFragment = gql`
  fragment RoomServiceOrderDetailFragment on RoomServiceOrderDetail {
    id
    origin {
      ...RoomServiceFragment
    }
    orderId
    title
    description
    price
    status
    soldOutPeriod
    summary
    leadTime
    thumbnail
    images
    options {
      ...RoomServiceOptionDetailFragment
    }
    quantity
  }
`;

export const RoomServiceCategoryFragment = gql`
  fragment RoomServiceCategoryFragment on RoomServiceCategory {
    id
    accommodationId
    name
    status
    priority
  }
`;

export const getRoomServiceCategory = gql`
  query getRoomService($roomServiceId: ID) {
    getRoomService(roomServiceId: $roomServiceId) {
      ...RoomServiceFragment
    }
  }
  ${RoomServiceFragment}
`;

export const getRoomServiceCategoryQuery = gql`
  query getRoomServiceCategory($accommodationId: ID) {
    getRoomServiceCategory(accommodationId: $accommodationId) {
      ...RoomServiceCategoryFragment
    }
  }
  ${RoomServiceCategoryFragment}
`;

export const getRoomServicesQuery = gql`
  query getRoomServices(
    $accommodationId: ID!
    $categories: [ID]
    $connectionArgs: ConnectionOffsetInput
    $where: WhereOptionInput
  ) {
    getRoomServices(
      accommodationId: $accommodationId
      categories: $categories
      connectionArgs: $connectionArgs
      where: $where
    ) {
      edges {
        cursor
        node {
          ...RoomServiceFragment
        }
      }
      pageInfo {
        ...OffsetPageInfoFragment
      }
    }
  }
  ${RoomServiceFragment}
  ${OffsetPageInfoFragment}
`;

export const createRoomServiceMutation = gql`
  mutation createRoomService($input: [CreateRoomService]!) {
    createRoomService(input: $input) {
      ...RoomServiceFragment
    }
  }
  ${RoomServiceFragment}
`;

export const updateRoomServiceMutation = gql`
  mutation updateRoomService($input: [UpdateRoomService]!) {
    updateRoomService(input: $input) {
      ...RoomServiceFragment
    }
  }
  ${RoomServiceFragment}
`;

export const deleteRoomServiceMutation = gql`
  mutation deleteRoomService($id: [ID]!) {
    deleteRoomService(id: $id)
  }
`;

export const createRoomServiceCategoryMutation = gql`
  mutation createRoomServiceCategory($input: [CreateRoomServiceCategory]!) {
    createRoomServiceCategory(input: $input) {
      ...RoomServiceCategoryFragment
    }
  }
  ${RoomServiceCategoryFragment}
`;

export const updateRoomServiceCategoryMutation = gql`
  mutation updateRoomServiceCategory($input: [UpdateRoomServiceCategory]!) {
    updateRoomServiceCategory(input: $input) {
      ...RoomServiceCategoryFragment
    }
  }
  ${RoomServiceCategoryFragment}
`;

export const deleteRoomServiceCategoryMutation = gql`
  mutation deleteRoomServiceCategory($id: [ID]!) {
    deleteRoomServiceCategory(id: $id)
  }
`;
