import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '@common/styles';
import Arrow from 'components/ui/Arrow';
import { NotoSansTypography, SUITTypography } from '@common/components';
import { DropdownItem } from 'types';
import SimpleBar from 'simplebar-react';

interface Props {
  data: DropdownItem[]
  isDeActive?: boolean
  id?: string
  isSelectedItem?: DropdownItem | undefined
  icon?: React.ReactElement
  containerStyles?: Record<string, string | number>
  itemContainerStyles?: Record<string, string | number>
  onSelected?: (selectedItem: DropdownItem) => void
  width?: string
  height?: number
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  onClose?: () => void
  initialOpened?: boolean
}

interface WrapperProps {
  text?: string
  width?: string
  height?: number
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
}

interface ListItemProps {
  data: DropdownItem
  isSelected: boolean
  onSelect: (item: DropdownItem) => void
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || '240px'};
  cursor: pointer;
  height: ${({ height }) => height || 56}px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.WHITE};
  border: ${({ borderWidth, borderColor }) => `${borderWidth || 2}px solid ${borderColor || COLORS.BLACK}`};
  border-radius: 8px;
  padding: 0px 18px;
`;

const Grid = styled.div<{ height?: string }>`
  display: flex;
  height: ${({ height }) => height};
  align-items: center;
`;

const List = styled(SimpleBar)`
  position: absolute;
  margin-top: 16px;
  width: 100% !important;
  z-index: 2;
  height: auto;
  border-radius: 8px;
  border: 1px solid ${COLORS.GRAY_02};
  background-color: ${COLORS.WHITE};
`;

const ListGrid = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;  
  height: 48px;
  cursor: pointer;
  padding: 16px;
  ${({ isSelected }) => isSelected && css`
    background-color: ${COLORS.BROWN_0DOT01};
  `}
`;

const ListItem: React.FunctionComponent<ListItemProps> = ({ data, isSelected, onSelect }) => (
  <ListGrid isSelected={isSelected} onClick={() => onSelect(data)}>
    {
      data.icon && (
        <div style={{ marginRight: 10 }}>
          {data.icon}
        </div>
      )
    }
    <SUITTypography>{data.name}</SUITTypography>
  </ListGrid>
);

const Dropdown: React.FunctionComponent<Props> = ({
  id, data, isSelectedItem, width, height, backgroundColor, containerStyles, itemContainerStyles, borderColor, borderWidth, onSelected, icon, onClose, isDeActive, initialOpened,
}) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(initialOpened || false);
  const [isSelected, setIsSelected] = React.useState<DropdownItem | undefined>(isSelectedItem || data.length ? data[0] : undefined);
  const modalRef = React.useRef<any>();

  const onSelectItem = (item: DropdownItem) => {
    setIsSelected(item);
    setIsOpened(false);

    if (typeof onSelected === 'function') {
      onSelected(item);
    }
  };

  const clickModalOutside = (event: any) => {
    if (isOpened && !modalRef.current.contains(event.target)) {
      setIsOpened(false);
    }
  };

  React.useEffect(() => {
    if (isSelectedItem) {
      setIsSelected(isSelectedItem);
    }
  }, [isSelectedItem]);

  React.useEffect(() => {
    document.addEventListener('mousedown', clickModalOutside);

    return () => {
      document.removeEventListener('mousedown', clickModalOutside);
    };
  });

  return (
    <div style={{ position: 'relative' }} ref={modalRef}>
      <Wrapper width={width} height={height} backgroundColor={isDeActive ? COLORS.GRAY_05 : backgroundColor} style={containerStyles} borderColor={borderColor} borderWidth={borderWidth}>
        <Grid style={{ justifyContent: 'space-between' }} height="100%" onClick={() => !isDeActive && setIsOpened(!isOpened)}>
          {
            !isDeActive && (
              <>
                <div>
                  <NotoSansTypography>{isSelected?.name || '-'}</NotoSansTypography>
                </div>
                <div style={{ paddingLeft: 12 }}>
                  {
                    icon || <Arrow type="bottom" containerStyles={{ position: 'static' }} />
                  }
                </div>
              </>
            )
          }
        </Grid>
      </Wrapper>
      <List style={{ display: !isOpened ? 'none' : 'block', width, ...itemContainerStyles }}>
        {
          data?.map((dropdownItem) => <ListItem data={dropdownItem} key={dropdownItem.id} isSelected={dropdownItem.id === isSelected?.id} onSelect={onSelectItem} />)
        }
      </List>
    </div>
  );
};

export default Dropdown;
