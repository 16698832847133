import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS, FONTS } from '@common/styles';
import { NotoSansTypography, SUITTypography } from '@common/components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ServiceTitle = () => {
  const { pathname } = useLocation();

  const useText = React.useMemo(() => {
    const currentService = pathname.split('/').pop();
    const text = {
      title: '룸 서비스',
      description: '음식 주문을 받는 페이지입니다.',
    };

    if (currentService === 'amenity') {
      text.title = '어메니티';
      text.description = '어메니티 주문을 받는 페이지입니다.';
    } else if (currentService === 'serviceNotice') {
      text.title = '메인 배너 설정';
      text.description = 'Home 상단에 배너를 슬라이드 형태로 등록할 수 있는 페이지입니다.';
    } else if (currentService === 'orderRequest') {
      text.title = '요청사항';
      text.description = '요청사항 리스트를 하나의 그룹으로 저장하여 룸서비스와 어메니티에 연결할 수 있습니다.';
    } else if (currentService === 'businessHours') {
      text.title = '매장 운영 시간';
      text.description = '매장 운영 시간을 입력하는 페이지입니다.';
    }

    return text;
  }, [pathname]);

  return (
    <Wrapper>
      <div style={{ marginRight: 12 }}>
        <NotoSansTypography fontSize={28} letterSpacing={-1.4} fontColor={COLORS.ACTIVE_BLACK} fontWeight={FONTS.NOTO_SANS.BOLD}>{useText.title}</NotoSansTypography>
      </div>
      <div style={{ paddingTop: 2 }}>
        <SUITTypography fontColor={COLORS.GRAY_01}>{useText.description}</SUITTypography>
      </div>
    </Wrapper>
  );
};

export default ServiceTitle;
