export const getErrorCode = (errorOrMessage) => {
  const errorMessage =
    typeof errorOrMessage === 'string'
      ? errorOrMessage
      : errorOrMessage.message;
  try {
    const errorCode = errorMessage.split(' ').pop();

    if (/ERR/.test(errorCode)) {
      return errorCode;
    }

    return errorMessage;
  } catch (err) {
    return errorMessage;
  }
};

export const onEnter = (callback) => (event) => {
  if (event.keyCode === 13) {
    callback();
  }
};

export const stringToHHMM = (string, unit) => {
  const myNum = parseInt(string * 60, 10);

  let hours = Math.floor(myNum / 3600);
  let minutes = Math.floor((myNum - hours * 3600) / 60);

  if (hours < 10) {
    hours = `${hours}`;
  }
  if (minutes < 10) {
    minutes = `${minutes}`;
  }

  if (hours === 24) {
    hours = 0;
  }

  if (hours > 24) {
    hours = `${hours - 24}`;
  }

  return {
    time: `${(hours < 10 && unit) || ''}${hours}:${
      (minutes < 10 && unit) || ''
    }${minutes}`,
    string: `${hours > 0 ? `${hours}시${minutes > 0 ? ' ' : ''}` : ''}${
      minutes > 0 ? `${minutes}분` : ''
    }`,
  };
};

export const parseTimeString = (timeString) => {
  const splitted = timeString?.split(':') || [];

  return {
    hour: parseFloat(splitted[0]),
    minute: parseFloat(splitted[1]),
  };
};

export const timeStringToMinute = (timeString) => {
  const { hour, minute } = parseTimeString(timeString);

  return hour * 60 + minute;
};

export const DEFAULT_WORK_SETTINGS = [
  {
    weekDay: 'mon',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'tue',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'wed',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'thu',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'fri',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'sat',
    workType: 'WORK',
    value: '00000000',
  },
  {
    weekDay: 'sun',
    workType: 'WORK',
    value: '00000000',
  },
];
