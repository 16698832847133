import { gql } from '@apollo/client';

export const setConciergeServiceSettingsMutation = gql`
  mutation setConciergeServiceSettings(
    $inputs: [ConciergeServiceSettingInput]!
    $accommodationId: ID!
  ) {
    setConciergeServiceSettings(
      inputs: $inputs
      accommodationId: $accommodationId
    ) {
      id
      key
      value
    }
  }
`;

export const setConciergeWorkSettingMutation = gql`
  mutation setConciergeWorkSetting(
    $inputs: [ConciergeWorkSettingInput]!
    $accommodationId: ID!
  ) {
    setConciergeWorkSetting(
      inputs: $inputs
      accommodationId: $accommodationId
    ) {
      id
      accommodationId
      weekDay
      workType
      value
    }
  }
`;

export const getConciergeWorkSettingsQuery = gql`
  query getConciergeWorkSettings($accommodationId: ID!, $weekDays: [String]) {
    getConciergeWorkSettings(
      accommodationId: $accommodationId
      weekDays: $weekDays
    ) {
      id
      accommodationId
      weekDay
      workType
      value
    }
  }
`;
