import styled, { CSSProperties } from "styled-components";

export const LayoutFlexJustifyContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }: CSSProperties) =>
    justifyContent || "space-between"};
  align-items: center;
  width: ${({ width }: CSSProperties) => width || "100%"};
`;

export const LayoutFlex = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }: CSSProperties) => flexWrap};
  flex-direction: ${({ flexDirection }: CSSProperties) =>
    flexDirection || "row"};
  align-items: ${({ alignItems }: CSSProperties) => alignItems || "flex-start"};
  justify-content: ${({ justifyContent }: CSSProperties) =>
    justifyContent || "flex-start"};
`;

export const LayoutFlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LayoutFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }: CSSProperties) => width};
  justify-content: ${({ justifyContent }: CSSProperties) =>
    justifyContent || "flex-start"};
  align-items: ${({ alignItems }: CSSProperties) => alignItems || "flex-start"};
`;

export const LayoutFlexFullSizeCenterAligned = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const LayoutFlexCenterAligned = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }: CSSProperties) => width};
  flex-direction: ${({ flexDirection }: CSSProperties) => flexDirection};
`;
