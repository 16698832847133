import { gql } from '@apollo/client';
import { OffsetPageInfoFragment } from './index';

export const ConciergeAmenityCategoryFragment = gql`
  fragment ConciergeAmenityCategoryFragment on ConciergeAmenityCategory {
    id
    accommodationId
    name
    status
    priority
  }
`;

export const ConciergeAmenityHashtagFragment = gql`
  fragment ConciergeAmenityHashtagFragment on ConciergeAmenityHashtag {
    id
    accommodationId
    name
    status
    priority
  }
`;

export const ConciergeAmenityFragment = gql`
  fragment ConciergeAmenityFragment on ConciergeAmenity {
    id
    accommodationId
    name
    price
    deliveryTime
    description
    thumbnail
    soldOutPeriod
    status
    categories {
      id
      accommodationId
      name
      status
      priority
    }
    # hashtags {
    #   ...ConciergeAmenityHashtagFragment
    # }
    images
  }
`;

export const ConciergeAmenityOrderDetailFramgnet = gql`
  fragment ConciergeAmenityOrderDetailFramgnet on ConciergeAmenityOrderDetail {
    id
    conciergeAmenity {
      ...ConciergeAmenityFragment
    }
    orderId
    name
    price
    deliveryTime
    description
    thumbnail
    images
    soldOutPeriod
    quantity
  }
`;

export const DeleteConciergeAmenityMutation = gql`
  mutation deleteConciergeAmenity($id: [ID]!) {
    deleteConciergeAmenity(id: $id)
  }
`;

export const getConciergeAmenitiesQuery = gql`
  query getConciergeAmenities(
    $accommodationId: ID!
    $categories: [ID]
    $connectionArgs: ConnectionOffsetInput
    $where: WhereOptionInput
  ) {
    getConciergeAmenities(
      accommodationId: $accommodationId
      categories: $categories
      connectionArgs: $connectionArgs
      where: $where
    ) {
      edges {
        cursor
        node {
          ...ConciergeAmenityFragment
        }
      }
      pageInfo {
        ...OffsetPageInfoFragment
      }
    }
  }
  ${ConciergeAmenityFragment}
  ${OffsetPageInfoFragment}
`;

export const getConciergeAmenityCategoriesQuery = gql`
  query getConciergeAmenityCategories($accommodationId: ID!) {
    getConciergeAmenityCategories(accommodationId: $accommodationId) {
      ...ConciergeAmenityCategoryFragment
    }
  }
  ${ConciergeAmenityCategoryFragment}
`;

export const createConciergeAmenityCategoryMutation = gql`
  mutation createConciergeAmenityCategory(
    $input: [CreateConciergeAmenityCategory]!
  ) {
    createConciergeAmenityCategory(input: $input) {
      ...ConciergeAmenityCategoryFragment
    }
  }
  ${ConciergeAmenityCategoryFragment}
`;

export const updateConciergeAmenityCategoryMutation = gql`
  mutation updateConciergeAmenityCategory(
    $input: [UpdateConciergeAmenityCategory]!
  ) {
    updateConciergeAmenityCategory(input: $input) {
      ...ConciergeAmenityCategoryFragment
    }
  }
  ${ConciergeAmenityCategoryFragment}
`;

export const deleteConciergeAmenityCategoryMutation = gql`
  mutation deleteConciergeAmenityCategory($id: [ID]!) {
    deleteConciergeAmenityCategory(id: $id)
  }
`;

export const createConciergeAmenityMutation = gql`
  mutation createConciergeAmenity($input: [CreateConciergeAmenity]!) {
    createConciergeAmenity(input: $input) {
      ...ConciergeAmenityFragment
    }
  }
  ${ConciergeAmenityFragment}
`;

export const updateConciergeAmenityMutation = gql`
  mutation updateConciergeAmenity($input: [UpdateConciergeAmenity]!) {
    updateConciergeAmenity(input: $input) {
      ...ConciergeAmenityFragment
    }
  }
  ${ConciergeAmenityFragment}
`;

export const deleteConciergeAmenityMutation = gql`
  mutation deleteConciergeAmenity($id: [ID]!) {
    deleteConciergeAmenity(id: $id)
  }
`;
