import React from 'react';
import { useParams } from 'react-router-dom';
import { UseInputState, UseInputUpdate } from '../types/hooks';

export const useInput = <T>(
  initialInput: T,
): [T, { onChangeInput: (key: any, value: any) => void, setInput: any }] => {
  const [input, setInput] = React.useState<T>(initialInput);

  const onChangeInput = React.useCallback(
    (key: string, value: string) => {
      setInput({
        ...input,
        [key]: value,
      });
    },
    [input],
  );

  return [input, { onChangeInput, setInput }];
};

interface UsePaginationProps {
  pagination: UseInputState;
  setPagination: (pagination: UseInputUpdate) => void
}

export const usePaginationInitialConfig = {
  initial: false,
  hasNextPage: false,
  hasPreviousPage: false,
  totalCount: 0,
  totalPages: 0,
  last: false,
  first: false,
  page: 1,
  limit: 6,
};

export const usePagination = (): UsePaginationProps => {
  const [pagination, setPagination] = React.useState<UseInputState>(usePaginationInitialConfig);

  const onUpdate = React.useCallback((pagination: UseInputUpdate) => {
    setPagination((initialPagination: UseInputState) => ({
      ...initialPagination,
      ...pagination,
    }));
  }, []);

  return { pagination, setPagination: onUpdate };
};

export const numberWithCommas = (x: any, postfix: any, { float = false } = {}) => (typeof x === 'number'
  && `${float && x || Math.floor(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${postfix || ''}`)
  || '';

export const parseNumberWithCommas = (amount: number) => parseFloat(amount?.toString?.().replace(/,/g, '')) || 0;

export const restrictInputNumberOnly = ({
  withComma, onChange, maxValue, minValue, numeric, formatOnMinMax = true, allowMinus = false, float = false,
}: any) => (event: any) => {
  if (event.target.value.split('').pop() === '.') return onChange(event.target.value);

  const amountValue = numeric ? parseNumberWithCommas(event.target.value) : event.target.value?.replace?.(/[^0-9]/g, '');

  if (minValue && amountValue < minValue) {
    event.target.onblur = () => onChange(formatValue(minValue)); // eslint-disable-line
  } else {
    event.target.onblur = null; // eslint-disable-line
  }

  if (/-/.test(event.target.value) && /^[0-]{1,2}$/.test(event.target.value) && allowMinus) {
    return onChange('-');
  }

  const formatValue = (targetVal?: any) => (withComma ? numberWithCommas(targetVal || amountValue, null, { float }) : targetVal || amountValue);
  console.log(formatValue());

  if (!minValue || amountValue > minValue) {
    if (maxValue && amountValue < maxValue) {
      return onChange(formatValue());
    } if (maxValue) {
      if (!formatOnMinMax) return;
      return onChange(formatValue(maxValue));
    }
  }

  if (minValue && amountValue > minValue) {
    return onChange(formatValue());
  }

  if (minValue) {
    if (!formatOnMinMax) return;

    return onChange(formatValue());
  }

  return onChange(formatValue());
};

export const useGetAccommodationId = () => {
  const { accommodationId } = useParams<{ accommodationId?: string }>();
  const [myAccommodationId, setMyAccommodationId] = React.useState<string | undefined>(accommodationId);

  React.useEffect(() => {
    if (!accommodationId || accommodationId === 'undefined') {
      const getMyAccommodationId = localStorage.getItem('myAccommodations');

      setMyAccommodationId(getMyAccommodationId || undefined);
    }
  }, [accommodationId]);

  return { accommodationId: myAccommodationId };
};
