import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from '@apollo/client';
import { LayoutFlex, NotoSansTypography } from '@common/components';
import { stringToHHMM, timeStringToMinute } from 'helpers';
import { useGetAccommodationId } from 'helpers/hooks';
import { COLORS, FONTS } from '@common/styles';
import { setConciergeWorkSettingMutation, getConciergeWorkSettingsQuery } from 'graphql/settings';
import styled from 'styled-components';
import { DateType, BusinessHourOption, DateSelectItem } from 'types';
import { ConciergeWorkSetting } from 'types/setting';
import { Button } from 'components/ui/Button';
import ServiceBusinessHoursDate from './components/Date';
import ServiceBusinessHoursDateModal from './components/DateModal';

interface Modal {
  type: 'date' | ''
  isOpened: boolean
}

const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 672px;
  border-radius: 8px;
  background-color: ${COLORS.WHITE};
  padding: 24px 24px 48px 24px; 
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DateWrapper = styled.div`
  margin-top: 24px;
  border: 1px solid ${COLORS.GRAY_02};
  border-radius: 8px;
  height: 558px;

  padding: 28px;


  > div:nth-last-child(1) {
    margin-bottom: 0px;
  }
`;

const DATE = ['월', '화', '수', '목', '금', '토', '일'];

type DayWeeksType = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
type DayWeeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const DAY_WEEKS: DayWeeks = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

interface BusinessHourState {
  mon: BusinessHourOption
  tue: BusinessHourOption
  wed: BusinessHourOption
  thu: BusinessHourOption
  fri: BusinessHourOption
  sat: BusinessHourOption
  sun: BusinessHourOption
}

const BusinessHours: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();
  const { data: getConciergeWorkSettings } = useQuery(getConciergeWorkSettingsQuery, {
    variables: {
      accommodationId,
      weekDays: DAY_WEEKS,
    },
  });
  const [emitSetConciergeWorkSettingMutation] = useMutation(setConciergeWorkSettingMutation);
  const [state, setState] = React.useState<BusinessHourState>({
    mon: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    tue: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    wed: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    thu: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    fri: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    sat: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
    sun: {
      work: {
        start: undefined,
        end: undefined,
      },
      break: [
        {
          start: undefined,
          end: undefined,
        },
        {
          start: undefined,
          end: undefined,
        },
      ],
    },
  });

  const [modal, setModal] = React.useState<Modal>({
    isOpened: false,
    type: '',
  });

  const onShowDateModal = React.useCallback(() => {
    setModal({
      isOpened: true,
      type: 'date',
    });
  }, []);

  const initialWorkSetting = () => {
    const getSettings = getConciergeWorkSettings.getConciergeWorkSettings;

    const tuningWorkSettingDataFormat = (data: ConciergeWorkSetting) => {
      const start = data.value.substring(0, 4);
      const end = data.value.substring(4, 8);

      const dateFormat = (date: string) => {
        const hour = date.substring(0, 2);
        const minute = date.substring(2, 4);

        return timeStringToMinute(`${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`);
      };

      return {
        start: start ? dateFormat(start) : undefined,
        end: end ? dateFormat(end) : undefined,
      };
    };

    const reduceSettings = getSettings.reduce((accumulator: any, currentValue: any) => {
      const { weekDay, workType } = currentValue;

      if (!accumulator[weekDay]) {
        accumulator[weekDay] = {
          work: {
            start: undefined,
            end: undefined,
          },
          break: [],
        };
      }

      if (workType === 'WORK') {
        accumulator[weekDay].work = tuningWorkSettingDataFormat(currentValue);
      } else {
        accumulator[weekDay].break.push(tuningWorkSettingDataFormat(currentValue));
      }

      return accumulator;
    }, {});

    const initialStateDate = (data: BusinessHourOption) => {
      let newBreak = [...data.break];

      if (newBreak.length !== 2) {
        const newBreaks = new Array(2 - newBreak.length).fill('').map((_) => ({
          start: undefined,
          end: undefined,
        }));

        newBreak = newBreak.concat(newBreaks);
      }

      return {
        work: data.work,
        break: newBreak,
      };
    };

    setState({
      ...state,
      mon: initialStateDate(reduceSettings.mon),
      tue: initialStateDate(reduceSettings.tue),
      wed: initialStateDate(reduceSettings.wed),
      thu: initialStateDate(reduceSettings.thu),
      fri: initialStateDate(reduceSettings.fri),
      sat: initialStateDate(reduceSettings.sat),
      sun: initialStateDate(reduceSettings.sun),
    });
  };

  const onCloseModal = React.useCallback(() => {
    setModal({
      isOpened: false,
      type: '',
    });
  }, []);

  const onSelect = (day: DayWeeksType, keyName: string, type: DateType, time: number) => {
    if (keyName === 'work') {
      const LIMIT_HOUR = 1380;
      const LAST_ORDER = 1920;

      setState({
        ...state,
        [day]: {
          ...state[day],
          work: {
            ...state[day].work,
            [type]: time,
            ...(type === 'start' ? { end: time > LIMIT_HOUR ? LAST_ORDER : time + (60 * 9) } : {}),
          },
        },
      });
    } else {
      setState((prev) => {
        const breakTimeIndex = Number(keyName.split('_')[1]) - 1;
        const copyBreakList = [...prev[day].break];

        copyBreakList[breakTimeIndex][type] = time;

        if (type === 'start') {
          copyBreakList[breakTimeIndex].end = time + 60;
        }

        return ({
          ...prev,
          [day]: {
            ...prev[day],
            break: copyBreakList,
          },
        });
      });
    }
  };

  const emitSetConciergeWorkSettingMutationFormatter = (dayWeek: DayWeeksType) => {
    const valueFormatter = (data: DateSelectItem) => `${data.start ? `${String(data.start / 60).padStart(2, '0')}00` : '0000'}${data.end ? `${(data.end / 60)}00` : '0000'}`;
    // const valueFormatter = (data: DateSelectItem) => `${stringToHHMM(data.start || (60 * 24), '0').time.replaceAll(':', '')}${data.end ? `${(data.end / 60)}00` : '0000'}`;

    const data = [
      {
        weekDay: dayWeek,
        workType: 'WORK',
        value: valueFormatter(state[dayWeek].work),
      },
    ];

    if (state[dayWeek].break[0].start) {
      data.push({
        weekDay: dayWeek,
        workType: 'REST1',
        value: valueFormatter(state[dayWeek].break[0]),
      });
    }

    if (state[dayWeek].break[1].start) {
      data.push({
        weekDay: dayWeek,
        workType: 'REST2',
        value: valueFormatter(state[dayWeek].break[1]),
      });
    }

    return data;
  };

  const onSave = async ({ initialSave = false }: { initialSave?: boolean }) => {
    if (!initialSave) {
      await toast.promise(
        emitSetConciergeWorkSettingMutation({
          variables: {
            inputs: DAY_WEEKS.map((dayWeek) => emitSetConciergeWorkSettingMutationFormatter(dayWeek)).flat(2),
            accommodationId,
          },
        }),
        {
          loading: <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>변경중...</NotoSansTypography>,
          success: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>매장 운영 시간이 성공적으로 변경되었습니다.</NotoSansTypography>,
          error: <NotoSansTypography lineHeight={30} fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD}>매장 운영 시간 변경 중 문제가 발생했습니다.</NotoSansTypography>,
        },
      );
    } else {
      await emitSetConciergeWorkSettingMutation({
        variables: {
          inputs: DAY_WEEKS.map((dayWeek) => emitSetConciergeWorkSettingMutationFormatter(dayWeek)).flat(2),
          accommodationId,
        },
      });
    }
  };

  const onUpdateDate = (data: BusinessHourOption) => {
    const immutability = (state: BusinessHourOption) => ({
      ...state,
      break: state.break.map((newBreak) => ({ ...newBreak })),
    });

    setState({
      ...state,
      mon: immutability(data),
      tue: immutability(data),
      wed: immutability(data),
      thu: immutability(data),
      fri: immutability(data),
      sat: immutability(data),
      sun: immutability(data),
    });

    onCloseModal();
  };

  React.useEffect(() => {
    if (getConciergeWorkSettings?.getConciergeWorkSettings.length) {
      initialWorkSetting();
    }

    if (getConciergeWorkSettings?.getConciergeWorkSettings && getConciergeWorkSettings?.getConciergeWorkSettings.length === 0) {
      onSave({ initialSave: true });
    }
  }, [getConciergeWorkSettings]);

  return (
    <>
      <Wrapper>
        <Grid style={{ justifyContent: 'space-between' }}>
          <Grid>
            <NotoSansTypography fontSize={20} fontWeight={FONTS.NOTO_SANS.BOLD} lineHeight={20} letterSpacing={-0.6}>영업시간 설정</NotoSansTypography>
            <div style={{ marginLeft: 24 }}>
              <NotoSansTypography lineHeight={null}>
                *룸서비스와 어메니티를 활성화 할 요일과 시간을 설정하는 기능입니다.
                {'\n'}
                휴게 시간은 요일별 최대 2개의 구간 설정이 가능합니다.
              </NotoSansTypography>
            </div>
          </Grid>
          <LayoutFlex>
            <Button text="일괄 변경" width="94px" height={38} fontColor={COLORS.BROWN} borderColor={COLORS.BROWN} backgroundColor={COLORS.WHITE} fontWeight="bold" onClick={onShowDateModal} styleProps={{ marginRight: 16 }} />
            <Button text="저장하기" width="94px" height={38} backgroundColor={COLORS.WHITE} fontWeight="bold" onClick={() => onSave({ initialSave: false })} />
          </LayoutFlex>
        </Grid>
        <DateWrapper>
          {
            DATE.map((day, index) => <ServiceBusinessHoursDate key={day} data={state[DAY_WEEKS[index]]} onSelect={(keyName: string, type: DateType, time: number) => onSelect(DAY_WEEKS[index], keyName, type, time)} day={day} />)
          }
        </DateWrapper>
      </Wrapper>
      {
        (modal.type === 'date' && modal.isOpened) && <ServiceBusinessHoursDateModal isOpened={modal.isOpened} onClose={onCloseModal} onUpdate={onUpdateDate} />
      }
    </>
  );
};

export default BusinessHours;
