import React from 'react';
import ServiceTag from 'containers/service/Tag';
import ServiceList from 'containers/service/list';
import { useQuery, useMutation } from '@apollo/client';
import { createConciergeAmenityCategoryMutation, deleteConciergeAmenityCategoryMutation, getConciergeAmenitiesQuery, getConciergeAmenityCategoriesQuery, updateConciergeAmenityCategoryMutation } from 'graphql/amenity';
import { useGetAccommodationId, usePagination } from 'helpers/hooks';

const Amenity: React.FunctionComponent = () => {
  const { accommodationId } = useGetAccommodationId();
  const { pagination, setPagination } = usePagination();

  const { data: serviceCategoryData, refetch: serviceCategoryRefetch } = useQuery(getConciergeAmenityCategoriesQuery, {
    variables: {
      accommodationId,
    },
  });
  const {
    data: amenityList, error, refetch: amenityListRefetch,
  } = useQuery(getConciergeAmenitiesQuery, {
    variables: {
      accommodationId,
      connectionArgs: {
        page: pagination.page,
        limit: pagination.limit,
      },
    },
  });
  const [emitCreateAmenityCategoryMutation] = useMutation(createConciergeAmenityCategoryMutation);
  const [emitUpdateAmenityCategoryMutation] = useMutation(updateConciergeAmenityCategoryMutation);
  const [emitDeleteAmenityCategoryMutation] = useMutation(deleteConciergeAmenityCategoryMutation);

  React.useEffect(() => {
    if (amenityList) {
      setPagination({
        ...amenityList?.getConciergeAmenities.pageInfo,
      });
    }
  }, [amenityList]);

  if (error) {
    console.log('error', error);
    // return <>{JSON.stringify(error)}</>;
  }

  return (
    <div>
      <ServiceTag category={serviceCategoryData?.getConciergeAmenityCategories || []} onCreateCategory={emitCreateAmenityCategoryMutation} onUpdateCategory={emitUpdateAmenityCategoryMutation} onDeleteCategory={emitDeleteAmenityCategoryMutation} refetch={serviceCategoryRefetch} />
      <ServiceList categoryLength={serviceCategoryData?.getConciergeAmenityCategories.length || 0} data={amenityList?.getConciergeAmenities} pagination={pagination} setPagination={setPagination} refetch={amenityListRefetch} />
    </div>
  );
};

export default Amenity;
