const FONTS = {
  NOTO_SANS: {
    NAME: "NotoSansKR",
    MEDIUM: 500,
    BOLD: "BOLD",
    BLACK: 900,
  },
  MONTSERRAT: {
    NAME: "Montserrat",
    BOLD: "bold",
    SEMI_BOLD: 600,
    MEDIUM: 500,
  },
  SUIT: {
    NAME: "SUIT",
    BOLD: "BOLD",
    SEMI_BOLD: 600,
    EXTRA_BOLD: 800,
  },
};

export default FONTS;
